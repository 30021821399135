import {
  AnyObject,
  CreditModel,
  LandModel,
  LandSectionModel,
  UserModel,
} from "./types";
import { ServerError } from "./hooks/useServerError";
import { ImageFieldType } from "@catalyst-tech/catalyst-form/dist/types";
import { LngLatBoundsLike } from "mapbox-gl";

export const RECOMMENDED_SELL_PRICE_STANDARD = 10; // CAD
export const RECOMMENDED_SELL_PRICE_PREMIUM = 20; // CAD

export const estimationFetcher = async (endpoint: string, query: AnyObject) => {
  let queryStrings: string[] = [];
  Object.entries(query).map(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((arrayValue) => queryStrings.push(key + "=" + arrayValue));
    } else {
      queryStrings.push(key + "=" + value);
    }
    return "";
  });

  endpoint += "?" + queryStrings.join("&");

  const response = await fetch(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error(response.status.toString());
    throw error;
  }

  return response.json();
};

export const externalFetcher = async (
  endpoint: string,
  query: AnyObject,
  customHeaders: { [key: string]: string }
) => {
  if (query) {
    let queryString = new URLSearchParams(query).toString();
    endpoint += "?" + queryString;
  }

  const response = await fetch(endpoint, {
    headers: {
      "Content-Type": "*/*",
      ...customHeaders,
    },
  });

  if (!response.ok) {
    const error = new Error(response.status.toString());
    throw error;
  }

  return response.json();
};

export const fetcher = async (
  endpoint: string,
  token: string,
  version: string = "v1"
) => {
  endpoint = `${process.env.REACT_APP_MY_TERRA_API}/${version}/${endpoint}`;

  const response = await fetch(endpoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = new Error(response.status.toString());
    throw error;
  }

  return response.json();
};

export const yoAPI = async (
  endpoint: string,
  body: any,
  token: string,
  onSuccess: Function,
  onError: Function,
  method: "POST" | "PUT" | "PATCH" | "DELETE" = "POST",
  type: "json" | "form-data" = "json",
  externalOrLocal: "local" | "external" = "local",
  version: "v1" | "v2" | "v3" = "v1"
) => {
  if (externalOrLocal === "local") {
    endpoint = `${process.env.REACT_APP_MY_TERRA_API}/${version}/${endpoint}`;
  }

  if (token === "") {
    return;
  }

  const requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  if (type === "json")
    requestHeaders.append("Content-Type", "application/json");
  const requestBody = type === "json" ? JSON.stringify(body) : body;

  try {
    const response = await fetch(endpoint, {
      method: method,
      headers: requestHeaders,
      body: requestBody,
    });
    let jsonResponse = await response.json();
    if (!response.ok) {
      let errorMessage;

      if (typeof jsonResponse === "object" && "detail" in jsonResponse) {
        Array.isArray(jsonResponse.detail)
          ? (errorMessage = jsonResponse.detail[0].msg)
          : (errorMessage = jsonResponse.detail);
      } else if (typeof jsonResponse === "string") {
        errorMessage = jsonResponse;
      } else {
        errorMessage = "sorry, something wrong happened.";
      }

      throw new Error(errorMessage);
    }
    console.log(jsonResponse);
    onSuccess(jsonResponse);
  } catch (error) {
    onError(error);
  }
};

export const easyAPI = (
  endpoint: string,
  body: AnyObject,
  token: string,
  resetForm: () => void,
  serverError: ServerError,
  onSuccessCallback?: (created_resource: any) => void,
  method: "POST" | "PUT" | "PATCH" = "POST",
  type: "json" | "form-data" = "json",
  version: "v1" | "v2" | "v3" = "v1"
) => {
  const onSuccess = (createdResource: AnyObject) => {
    if (typeof onSuccessCallback === "function")
      onSuccessCallback(createdResource);
    resetForm();
    serverError.clear();
  };
  const onError = (error: Error) => {
    serverError.set(error);
  };
  yoAPI(
    endpoint,
    body,
    token,
    onSuccess,
    onError,
    method,
    type,
    "local",
    version
  );
};

export const getDate = (timestamp: number) => {
  let date = new Date(timestamp * 1000);
  return date.toLocaleDateString("en-CA");
};

export const getTime = (timestamp: number) => {
  let time = new Date(timestamp * 1000);
  return time.toLocaleTimeString("en-CA");
};

export const compareTimeStamp = (
  a: AnyObject,
  b: AnyObject,
  timeSampKey: string,
  order: "new first" | "old first"
) => {
  let value =
    order === "new first"
      ? b[timeSampKey] - a[timeSampKey]
      : a[timeSampKey] - b[timeSampKey];
  return value;
};

export const sortByTimeStamp = (
  array: AnyObject[],
  timeSamp: string,
  order: "new first" | "old first"
) => {
  return array.sort((a: AnyObject, b: AnyObject) =>
    compareTimeStamp(a, b, timeSamp, order)
  );
};

export const currentTimestamp = () => Math.floor(Date.now() / 1000);

export const conditionalClassNames = (
  classNamesObject: {
    [key: string]: boolean;
  },
  additionalClassNames?: string
) => {
  let classes: string[] = [];
  Object.entries(classNamesObject).map(
    ([key, value]: [string, boolean]) => value && classes.push(key)
  );
  additionalClassNames && classes.push(...additionalClassNames.split(" "));
  return classes.join(" ");
};

export const arrayToCommaSeparatedText = (array: (string | number)[]) => {
  if (Array.isArray(array)) {
    let text = "";
    array.sort().forEach((item) => {
      text += item + ", ";
    });
    text = text.slice(0, -2);
    return text;
  }
};

export const debounce = (callback: Function, delay: number = 1000) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const getSectionDescription = (section: LandSectionModel) =>
  `${section.section}-${section.township}-${section.range}-${section.meridian}`;

export const getLandSectionDescription = (land: LandModel) =>
  `${land.section}-${land.township}-${land.range}-${land.meridian}`;

export const getLandDescription = (land: LandModel) =>
  `${land.quarter}-${land.section}-${land.township}-${land.range}-${land.meridian}`;

export const landHasPractice = (
  land: LandModel,
  practice: "zero_till" | "shelterbelt" | "grassland"
) => {
  if (practice in land.practices) return true;
  return false;
};

export const isBeingTestedByCypress = () => {
  return "Cypress" in window;
};

export const formImageToBe = (
  action: "uploaded" | "removed",
  image: ImageFieldType
) => {
  if (action === "uploaded" && image.file instanceof File && image.url !== "") {
    return true;
  }
  if (action === "removed" && image.file === "" && image.url === "") {
    return true;
  }
  return false;
};

export const doneOnboarding = (user: UserModel) => {
  return user.onboard_step === -1;
};

export const formatMoney = (amount: number) => {
  let formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });
  return formatter.format(amount);
};

export const totalCreditsOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  let results = credits.filter((credit) => credit.year === getCurrentYear());
  let currentCredits = 0;
  if (results.length > 0) {
    if (type === "standard" && results[0].avoidance !== null) {
      let credits = results[0].avoidance.credits_total;
      currentCredits = parseFloat(credits.toFixed(1));
    }
    if (type === "premium" && results[0].removal !== null) {
      let credits = results[0].removal.credits_total;
      currentCredits = parseFloat(credits.toFixed(1));
    }
  }
  return currentCredits;
};

export const listedCreditsOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  let results = credits.filter((credit) => credit.year === getCurrentYear());
  let currentCredits = 0;
  if (results.length > 0) {
    if (type === "standard" && results[0].avoidance !== null) {
      let credits = results[0].avoidance.credits_committed;
      currentCredits = parseFloat(credits.toFixed(1));
    }
    if (type === "premium" && results[0].removal !== null) {
      let credits = results[0].removal.credits_committed;
      currentCredits = parseFloat(credits.toFixed(1));
    }
  }
  return currentCredits;
};

export const soldCreditsOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  let results = credits.filter((credit) => credit.year === getCurrentYear());
  let currentCredits = 0;
  if (results.length > 0) {
    if (type === "standard" && results[0].avoidance !== null) {
      let credits = results[0].avoidance.credits_sold;
      currentCredits = parseFloat(credits.toFixed(1));
    }
    if (type === "premium" && results[0].removal !== null) {
      let credits = results[0].removal.credits_sold;
      currentCredits = parseFloat(credits.toFixed(1));
    }
  }
  return currentCredits;
};

export const creditsOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  let results = credits.filter((credit) => credit.year === getCurrentYear());
  let currentCredits = 0;
  if (results.length > 0) {
    if (type === "standard" && results[0].avoidance !== null) {
      let credits = results[0].avoidance.available_credits;
      currentCredits = parseFloat(credits.toFixed(1));
    }
    if (type === "premium" && results[0].removal !== null) {
      let credits = results[0].removal.available_credits;
      currentCredits = parseFloat(credits.toFixed(1));
    }
  }
  return currentCredits;
};

export const pendingCreditsOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  let results = credits.filter((credit) => credit.year === getCurrentYear());
  let currentCredits = 0;
  if (results.length > 0) {
    if (type === "standard" && results[0].avoidance !== null) {
      let credits = results[0].avoidance.credits_pending;
      currentCredits = parseFloat(credits.toFixed(1));
    }
    if (type === "premium" && results[0].removal !== null) {
      let credits = results[0].removal.credits_pending;
      currentCredits = parseFloat(credits.toFixed(1));
    }
  }
  return currentCredits;
};

export const balanceOf = (
  type: "standard" | "premium",
  credits: CreditModel[]
) => {
  if (type === "standard")
    return formatMoney(
      creditsOf(type, credits) * RECOMMENDED_SELL_PRICE_STANDARD
    );
  if (type === "premium")
    return formatMoney(
      creditsOf(type, credits) * RECOMMENDED_SELL_PRICE_PREMIUM
    );
  return "";
};

export const recommendedSellPriceFor = (type: "standard" | "premium") => {
  if (type === "standard") return formatMoney(RECOMMENDED_SELL_PRICE_STANDARD);
  if (type === "premium") return formatMoney(RECOMMENDED_SELL_PRICE_PREMIUM);
  return "";
};

export const getPercentage = (value: number, total: number) => {
  if (total === 0) return 0;
  return parseInt(((value / total) * 100).toFixed());
};

export const getLandBounds = (land: LandModel) => {
  let southWest = [land.corners[0][1], land.corners[0][0]];
  let northEast = [land.corners[2][1], land.corners[2][0]];
  return [...southWest, ...northEast];
};

export const getLandsBounds = (lands: LandModel[]) =>
  lands.reduce(
    (previous: any[][], current) => {
      let lngValues = current.corners.map((coords: any) => coords[1]);
      let latValues = current.corners.map((coords: any) => coords[0]);

      // get most west longitude value
      let mostWestLngValue = Math.min(...lngValues);
      if (previous[0][0] !== null)
        mostWestLngValue = Math.min(previous[0][0], mostWestLngValue);

      // get most east longitude value
      let mostEastLngValue = Math.max(...lngValues);
      if (previous[1][0] !== null)
        mostEastLngValue = Math.max(previous[1][0], mostEastLngValue);

      // get most north latitude value
      let mostNorthLatValue = Math.max(...latValues);
      if (previous[1][1] !== null)
        mostNorthLatValue = Math.max(previous[1][1], mostNorthLatValue);

      // get most south latitude value
      let mostSouthLatValue = Math.min(...latValues);
      if (previous[0][1] !== null)
        mostSouthLatValue = Math.min(previous[0][1], mostSouthLatValue);

      return [
        [mostWestLngValue, mostSouthLatValue],
        [mostEastLngValue, mostNorthLatValue],
      ];
    },
    [
      [null, null],
      [null, null],
    ]
  ) as LngLatBoundsLike;

export const getCurrentYear = () => new Date().getFullYear();
