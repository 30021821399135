import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { AnyObject } from "../../../../types";

export const useEnrollments = (token: string) => {
  const { data, error } = useSWR(
    token ? [`customer/me/dossier`, token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    enrollments: data as AnyObject,
    isLoading: !error && !data,
    isError: error,
  };
};
