import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode } from "react";
import {
  FormChangeHandler,
  FormSubmitHandler,
  ProfileModel,
} from "../../../types";
import { validate } from "../../../validators/validate";

type ProfileFormProps = {
  profile?: Partial<ProfileModel>;
  onChange?: FormChangeHandler;
  children: (submit: FormSubmitType) => ReactNode;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const ProfileForm = ({
  profile,
  onChange,
  children,
  onSubmit,
  serverErrorMessage,
}: ProfileFormProps) => {
  return (
    <QuickForm
      fields={[
        {
          label: "account name",
          id: "farm_name",
          type: "text",
          initial: profile?.farm_name || "",
          validators: [validate.required],
          instructions: (
            <p className="font-size-14 color-neutral-600">
              Account name is just a nickname for your account, and it can be
              whatever you call your farm.
            </p>
          ),
        },
        {
          label: "province",
          id: "province",
          type: "text",
          initial: profile?.province || "",
          validators: [validate.required],
        },
        {
          label: "city/town",
          id: "city",
          type: "text",
          initial: profile?.city || "",
          validators: [validate.required],
        },
      ]}
      onChange={onChange}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
    >
      {(submit) => children(submit)}
    </QuickForm>
  );
};

export default ProfileForm;
