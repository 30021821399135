import EnrollmentProgramCard from "../features/enrollment/EnrollmentProgramCard";
import Widget from "../layout/Widget";

const CarbonCreditProgramsPage = () => {
  return (
    <Widget title="select a carbon credit program">
      <div className="ram">
        <EnrollmentProgramCard type="no-till" />
        <EnrollmentProgramCard type="shelterbelt" />
        <EnrollmentProgramCard type="grasslands" />
        <EnrollmentProgramCard type="rice" />
        <EnrollmentProgramCard type="tree planting" />
      </div>
    </Widget>
  );
};

export default CarbonCreditProgramsPage;
