import { SelectField } from "@catalyst-tech/catalyst-form";

type CreditTypeSelectProps = {
  label: string;
  id: string;
  currentCreditType: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  errors: { [key: string]: string[] };
};

const CreditTypeSelect: React.FC<CreditTypeSelectProps> = ({
  label,
  id,
  currentCreditType,
  handleChange,
  errors,
}) => {
  return (
    <div className="section">
      <SelectField
        options={[{ id: "removal" }, { id: "avoidance" }]}
        keyAsLabel="id"
        label={label}
        id={id}
        value={currentCreditType}
        handleChange={handleChange}
        errors={errors}
      />
    </div>
  );
};

export default CreditTypeSelect;
