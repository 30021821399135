import { ReactNode } from "react";
import { getCurrentYear } from "../../helpers";
import { AnyObject } from "../../types";
import MultiSelectField from "./MultiSelectField";

type ChemFallowYearsFieldProps = {
  label: string;
  id: string;
  value: any;
  handlers: any;
  errors: AnyObject;
  landDescription: string;
  formData?: AnyObject;
  resetField?: (input: string) => void;
  instructions?: ReactNode;
};

const ChemFallowYearsField = ({
  label,
  id,
  value,
  handlers,
  errors,
  landDescription,
  formData,
  resetField,
  instructions,
}: ChemFallowYearsFieldProps) => {
  const getValidYears = () => {
    const noTillStartYear = formData
      ? formData[`no_till_start_year_${landDescription}`]
      : undefined;
    let years = [];
    if (noTillStartYear >= 1900 && noTillStartYear <= getCurrentYear()) {
      for (let i = parseInt(noTillStartYear); i <= getCurrentYear(); i++) {
        years.push(i.toString());
      }
    }
    return years;
  };

  return (
    <div className="form__group">
      <p className="form__label">{label}</p>
      <MultiSelectField
        options={getValidYears()}
        id={id}
        value={value as string[]}
        handleChange={handlers.handleDirectChange}
        emptyMessage="Type a valid No-Till start year to see the chem fallow years options."
      />
    </div>
  );
};

export default ChemFallowYearsField;
