import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { isBeingTestedByCypress } from "../helpers";

export const useAccessToken = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    if (isBeingTestedByCypress()) {
      const auth0 = JSON.parse(localStorage.getItem("auth0Cypress")!);
      setToken(auth0.body.access_token);
    } else {
      const getAccessToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
        } catch (error) {
          console.log(error);
        }
      };
      getAccessToken();
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  return {
    token: token,
  };
};
