import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useRef, useState } from "react";
import { FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { UserModel } from "../../types";
import { UserContext } from "../features/user/providers/UserProvider";

type AvatarProps = {
  noProfileLink?: boolean;
};

const Avatar = ({ noProfileLink = false }: AvatarProps) => {
  const { logout } = useAuth0();
  const user = useContext(UserContext) as UserModel;
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const toggleAvatarMenu = () => setOpen((current) => !current);

  const avatarElement = useRef<HTMLDivElement | null>(null);

  // close on click outside
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        avatarElement.current &&
        !avatarElement.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open]);

  // close on location change
  useEffect(() => setOpen(false), [location]);

  return (
    <div className="avatar" ref={avatarElement}>
      <img
        src={(user.pic as string) || "/images/no-avatar-tiny.png"}
        alt=""
        className="avatar__image"
        onClick={toggleAvatarMenu}
      />
      {open && (
        <nav className="avatar__menu">
          <div className="avatar__group">
            <p className="avatar__name">
              {user.party.given_name} {user.party.family_name}
            </p>
          </div>
          <div className="avatar__group">
            {!noProfileLink && (
              <Link to="/profile/edit" className="avatar__link">
                <FaUserEdit /> edit profile
              </Link>
            )}
            <Link to="/support" className="avatar__link">
              <MdContactSupport /> help
            </Link>
          </div>
          <p
            className="avatar__link"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <FaSignOutAlt />
            sign out
          </p>
        </nav>
      )}
    </div>
  );
};

export default Avatar;
