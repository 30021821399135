import { QuickForm, SelectField } from "@catalyst-tech/catalyst-form";
import { FormSubmitHandler, LandModel } from "../../../types";
import { validate } from "../../../validators/validate";
import Button from "../../ui/Button";
import Loading from "../../ui/Loading";

type LandFormProps = {
  land?: Partial<LandModel>;
  isLoading?: boolean;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const LandForm = ({
  land,
  isLoading = false,
  onSubmit,
  serverErrorMessage,
}: LandFormProps) => {
  const getSectionFieldOptions = () => {
    let options = [];
    let max = 36;
    let withA = ["6", "7", "18", "19", "30", "31", "36"];
    for (let i = 0; i < max; i++) {
      let current = (i + 1).toString();
      options.push({ id: current });
      withA.includes(current) && options.push({ id: current + "A" });
    }
    return options;
  };

  const getTownshipFieldOptions = () => {
    let options = [];
    let max = 127;
    let withA = ["19", "27", "42", "43", "45", "46", "47", "48"];
    for (let i = 0; i < max; i++) {
      let current = (i + 1).toString();
      options.push({ id: current });
      withA.includes(current) && options.push({ id: current + "A" });
    }
    return options;
  };

  const getRangeFieldOptions = () => {
    let options = [];
    let max = 34;
    let withA = ["13", "21", "24", "26", "29"];
    for (let i = 0; i < max; i++) {
      let current = (i + 1).toString();
      options.push({ id: current });
      withA.includes(current) && options.push({ id: current + "A" });
    }
    return options;
  };

  return (
    <QuickForm
      fields={[
        {
          label: "quarter",
          id: "quarter",
          type: "QuarterField",
          initial: land?.quarter || "",
          validators: [validate.required],
        },
        {
          label: "section",
          id: "section",
          type: "SectionField",
          initial: land?.section || "",
          validators: [validate.required],
        },
        {
          label: "township",
          id: "township",
          type: "TownshipField",
          initial: land?.township || "",
          validators: [validate.required],
        },
        {
          label: "range",
          id: "range",
          type: "RangeField",
          initial: land?.range || "",
          validators: [validate.required],
        },
        {
          label: "meridian",
          id: "meridian",
          type: "MeridianField",
          initial: land?.meridian || "",
          validators: [validate.required],
        },
      ]}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
      customFields={{
        QuarterField: (field, form) => (
          <SelectField
            label={field.label}
            id={field.id}
            options={[
              { id: "NW", label: "NW 1/4" },
              { id: "NE", label: "NE 1/4" },
              { id: "SW", label: "SW 1/4" },
              { id: "SE", label: "SE 1/4" },
              { id: "N", label: "N 1/2" },
              { id: "S", label: "S 1/2" },
              { id: "E", label: "E 1/2" },
              { id: "W", label: "W 1/2" },
              { id: "ALL", label: "All Quarters" },
            ]}
            keyAsLabel={"label"}
            value={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
        SectionField: (field, form) => (
          <SelectField
            label={field.label}
            id={field.id}
            options={getSectionFieldOptions()}
            keyAsLabel={"id"}
            value={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
        TownshipField: (field, form) => (
          <SelectField
            label={field.label}
            id={field.id}
            options={getTownshipFieldOptions()}
            keyAsLabel={"id"}
            value={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
        RangeField: (field, form) => (
          <SelectField
            label={field.label}
            id={field.id}
            options={getRangeFieldOptions()}
            keyAsLabel={"id"}
            value={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
        MeridianField: (field, form) => (
          <SelectField
            label={field.label}
            id={field.id}
            options={[{ id: "W2" }, { id: "W3" }]}
            keyAsLabel={"id"}
            value={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
      }}
    >
      {(submit) => (
        <div className="flex">
          <Button onClick={submit}>add land</Button>
          {isLoading && <Loading />}
        </div>
      )}
    </QuickForm>
  );
};

export default LandForm;
