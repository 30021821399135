import { getDate, getTime } from "../../../helpers";
import { OrderModel } from "../../../types";

type ListingItemProps = {
  listing: OrderModel;
};

const ListingItem = ({ listing }: ListingItemProps) => {
  var formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });
  return (
    <div className="listing-item">
      <p className="listing-item__cell--full">
        <b> Listing ID: </b>
        {listing.order_id}
      </p>
      <div className="listing-item__row">
        {/* <p className="listing-item__cell">
          <span className="listing-item__title">year</span>
          {order.vintage}
        </p> */}
        <p className="listing-item__cell">
          <span className="listing-item__title">credit type</span>
          {listing.credit_type === "avoidance" ? "standard" : "premium"}
        </p>
        <p className="listing-item__cell">
          <span className="listing-item__title">credit amount</span>
          {listing.amount}
        </p>
        <p className="listing-item__cell">
          <span className="listing-item__title">min sell price</span>
          {formatter.format(parseFloat(listing.minimum_sell_price))}
        </p>
        <p className="listing-item__cell">
          <span className="listing-item__title">value</span>
          {formatter.format(
            parseFloat(listing.amount) * parseFloat(listing.minimum_sell_price)
          )}
        </p>
        <p className="listing-item__cell">
          <span className="listing-item__title">listing date & time</span>
          <span className="font-size-12">
            {getDate(listing.created_ts)} {getTime(listing.created_ts)}
          </span>
        </p>
        <p className="listing-item__cell">
          <span className="listing-item__title">sale status</span>
          {listing.status === "open" ? "pending.." : "filled"}
        </p>
      </div>
    </div>
  );
};

export default ListingItem;
