import IconLoadingBig from "./icons/IconLoadingBig";

type LoadingProps = {
  fullscreen?: boolean;
};

const Loading = ({ fullscreen }: LoadingProps) => {
  if (fullscreen) {
    return (
      <div className="full-screen-loading">
        <IconLoadingBig />
      </div>
    );
  }

  return (
    <div className="loading">
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
