import { ReactNode } from "react";
import { IoClose } from "react-icons/io5";
import Button from "./Button";

type ModalProps = {
  visibility: boolean;
  handleClose: VoidFunction;
  title: string;
  children: ReactNode;
  id: string;
};

const Modal = ({
  visibility,
  handleClose,
  title,
  children,
  id,
}: ModalProps) => {
  if (visibility === false) return null;
  return (
    <div className="modal" id={id}>
      <div className="modal__wrapper">
        <header className="modal__header">
          <h1>{title}</h1>
          <Button variant="second" onClick={handleClose}>
            <IoClose />
          </Button>
        </header>
        <div className="modal__body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
