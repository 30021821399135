import { nanoid } from "nanoid";
import { memo } from "react";
import { LandSectionModel } from "../../../../../types";
import LandSectionDisplay from "../../../land-section/LandSectionDisplay";
import LandPractice from "../../../land/LandPractice";

type PracticesBulkEditProps = {
  sections: LandSectionModel[];
  onPracticeUpdate: (landId: string) => void;
};

const PracticesBulkEdit = ({
  sections,
  onPracticeUpdate,
}: PracticesBulkEditProps) => {
  return (
    <>
      {sections.map((section) => (
        <div className="land-select__section" key={nanoid()}>
          <LandSectionDisplay section={section} />
          <div className="land-select__section-lands">
            {section.lands.map((land) => (
              <LandPractice
                key={nanoid()}
                landId={land.land_id}
                onUpdate={onPracticeUpdate}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default memo(PracticesBulkEdit);
