import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode, useContext } from "react";
import { KeyedMutator } from "swr";
import { easyAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  FormSuccessHandler,
  QuickFormObject,
  UserContactModel,
  UserModel,
} from "../../../types";
import { UserContext } from "../user/providers/UserProvider";
import UserContactForm from "./UserContactForm";

type UserContactEditProps = {
  contact: UserContactModel;
  mutateContacts: KeyedMutator<any>;
  children: (submit: FormSubmitType) => ReactNode;
  onSuccess?: FormSuccessHandler;
};

const UserContactEdit = ({
  contact,
  mutateContacts,
  children,
  onSuccess,
}: UserContactEditProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();

  const updateContactInformation = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<any>;
    }
  ) => {
    let newContact = {
      is_primary: true,
      party_id: user.party.party_id,
      contact_id: contact.contact_id,
      phone_number: form.data.phone_number,
      phone_type: "phone",
    };

    const onAPISuccess = (response: any) => {
      mutateContacts();
      typeof onSuccess === "function" && onSuccess(response);
    };

    easyAPI(
      `party/${user.party.party_id}/contact/${contact.contact_id}`,
      newContact,
      token,
      () => {},
      serverError,
      onAPISuccess,
      "PATCH"
    );
  };

  return (
    <>
      <UserContactForm
        contact={contact}
        onSubmit={updateContactInformation}
        serverErrorMessage={serverError.message}
      >
        {(submit) => children(submit)}
      </UserContactForm>
    </>
  );
};

export default UserContactEdit;
