import { Link } from "react-router-dom";
import { getCurrentYear } from "../../helpers";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="flex gap-8 wrap">
        <Link to="#link">Legal</Link>
        <Link to="#link">Privacy</Link>
        <Link to="#link">Terms of Service</Link>
      </div>
      <div className="footer__right">
        <p>Copyright © {getCurrentYear()} MyTerra. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
