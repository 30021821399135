import { useContext } from "react";
import { mutate } from "swr";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useModal } from "../../../hooks/useModal";
import { UserModel } from "../../../types";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import { UserContext } from "../user/providers/UserProvider";
import BusinessList from "./BusinessList";
import BusinessNew from "./BusinessNew";

const BusinessEdit = () => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { modal } = useModal();

  const handleBusinessCreated = () => {
    mutate([`customer/${user.customer_id}/business`, token, "v1"]);
    modal.close();
  };

  return (
    <div>
      <Button onClick={() => modal.open("new-business")}>add business</Button>
      <Modal
        visibility={modal.visibility("new-business")}
        handleClose={modal.close}
        title="new business"
        id="new-business"
      >
        <BusinessNew onSuccess={handleBusinessCreated} />
      </Modal>
      <br />
      <BusinessList />
    </div>
  );
};

export default BusinessEdit;
