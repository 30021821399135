import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      labels: {
        color: "hsl(240, 6%, 08%)",
      },
    },
    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    yAxis: {
      grid: {
        borderColor: "hsl(210, 6%, 18%)",
      },
      ticks: {
        color: "hsl(210, 6%, 18%)",
      },
    },
    xAxis: {
      grid: {
        borderColor: "hsl(210, 6%, 18%)",
      },
      ticks: {
        color: "hsl(210, 6%, 18%)",
      },
    },
  },
};

const labels = ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const data = {
  labels,
  datasets: [
    {
      label: "Standard Credit",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: "hsl(210, 6%, 08%)",
      backgroundColor: "hsl(150, 24%, 92%)",
    },
    {
      label: "Premium Credit",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: "hsl(210, 6%, 08%)",
      backgroundColor: "hsl(150, 64%, 32%)",
    },
  ],
};

const ProfitsChart = () => {
  return (
    <div className="chart">
      <Bar options={options} data={data} />
    </div>
  );
};

export default ProfitsChart;
