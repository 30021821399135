import { EnrollmentProgramType } from "../../../types";
import Card from "../../ui/Card";
import EnrollmentButton from "./EnrollmentButton";

const programDescriptions = {
  "no-till":
    "You can get credits for land that are already currently being no-tilled.",
  shelterbelt:
    "Get carbon credits for leaving shelter belts and home belts where they are.",
  grasslands: "Preserve your grasslands or pasture to get carbon credits.",
  rice: "Get carbon credits for optimizing the growth of the biggest food staple crop in the world.",
  "tree planting":
    "Turn marginal land into a massive source of carbon credits.",
};

const availableEnrollmentTypes = ["no-till"];

type EnrollmentProgramCardType = {
  type: EnrollmentProgramType;
  comingSoon?: boolean;
};

const EnrollmentProgramCard = ({
  type,
  comingSoon = false,
}: EnrollmentProgramCardType) => {
  return (
    <div>
      <Card imageUrl={`/images/programs/${type}.png`}>
        <div className="grid gap-4">
          <p className="subtitle font-size-14">carbon Credit Program</p>
          <h2>{type}</h2>
        </div>
        <p className="enrollment-program__body">{programDescriptions[type]}</p>
        {availableEnrollmentTypes.includes(type) ? (
          <EnrollmentButton type={type} />
        ) : (
          <p className="coming-soon">coming soon</p>
        )}
      </Card>
    </div>
  );
};

export default EnrollmentProgramCard;
