import { useContext, useRef } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { mutate } from "swr";
import { yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import { BusinessModel, UserModel } from "../../../types";
import Button from "../../ui/Button";
import LabelValuePair from "../../ui/LabelValuePair";
import { UserContext } from "../user/providers/UserProvider";

type BusinessItemProps = {
  business: BusinessModel;
};

const BusinessItem = ({ business }: BusinessItemProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const businessItem = useRef<HTMLDivElement | null>(null);
  const { serverError } = useServerError();

  const handleDeleteBusiness = async () => {
    businessItem.current?.classList.add("remove");
    const onSuccess = () => {
      serverError.clear();
      mutate([`customer/${user.customer_id}/business`, token]);
    };
    const onFailure = (error: Error) => {
      serverError.set(error);
      businessItem.current?.classList.remove("remove");
    };
    yoAPI(
      `customer/${user.customer_id}/business/${business.party.party_id}`,
      {},
      token,
      onSuccess,
      onFailure,
      "DELETE"
    );
  };

  return (
    <div className="panel" ref={businessItem}>
      <div className="flex-row">
        <Button onClick={handleDeleteBusiness} variant="second">
          <FaRegTrashAlt />
        </Button>
        <LabelValuePair label="business name" value={business.party.name} />
        <LabelValuePair
          label="business number"
          value={business.party.business_number}
        />
      </div>
    </div>
  );
};

export default BusinessItem;
