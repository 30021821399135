import { ReactNode } from "react";

type CardProps = {
  imageUrl: string;
  children: ReactNode;
};

const Card = ({ imageUrl, children }: CardProps) => {
  return (
    <div className="card">
      <img src={imageUrl} alt="" className="card__image" />
      <div className="card__body">{children}</div>
    </div>
  );
};

export default Card;
