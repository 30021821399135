import useSWR from "swr";
import { fetcher } from "../helpers";
import { LandModel } from "../types";

export const useLand = (token: string, landId: string) => {
  const { data, error } = useSWR(
    token ? [`land/${landId}`, token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    land: data as LandModel,
    isLoading: !error && !data,
    isError: error,
  };
};
