import { useState } from "react";

export const useWizard = (steps: string[], initialStep: number = 1) => {
  const getInitialStep = () => {
    if (initialStep > steps.length) {
      return steps.length;
    }
    return initialStep;
  };
  const [currentStep, setCurrentStep] = useState(getInitialStep());
  const [loading, setLoading] = useState(false);

  return {
    wizard: {
      steps: steps,
      currentStep: currentStep,
      stepCount: steps.length,
      isLoading: loading,
      setLoading: (loading: boolean) => {
        console.log("accessed set loading in wizard", loading);
        setLoading(loading);
      },
      isCurrentStep: (index: number) => index === currentStep,
      isDoneStep: (index: number) => index < currentStep,
      goToNextStep: () => {
        if (currentStep < steps.length) {
          setLoading(false);
          setCurrentStep((current) => current + 1);
        }
      },
      goToPreviousStep: () => {
        if (currentStep > 1) {
          setCurrentStep((current) => current - 1);
        }
      },
    },
  };
};
