import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { CreditModel, UserModel } from "../../../../types";

export const useUserCredits = (user: UserModel, token: string) => {
  const { data, error } = useSWR(
    token ? [`party/${user.party.party_id}/credit`, token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    credits: data as {
      items: CreditModel[];
    },
    isLoading: !error && !data,
    isError: error,
  };
};
