import Credits from "./Credits";

const CreditHoldings = () => {
  return (
    <div className="list list--gap-12">
      <div className="ram">
        <Credits type="standard" />
        <Credits type="premium" />
      </div>
    </div>
  );
};

export default CreditHoldings;
