import { useContext } from "react";
import { IoMdMenu } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Avatar from "../ui/Avatar";
import Button from "../ui/Button";
import Logo from "../ui/Logo";
import { MenuContext } from "../ui/providers/MenuProvider";

const Header = () => {
  const menuContext = useContext(MenuContext);
  let location = useLocation();
  let pageName = location.pathname.split("/")[1];

  return (
    <header className="header">
      <div className="menu-toggle">
        <Button
          variant="second"
          onClick={menuContext?.toggleMenu}
          customClasses={"menu-toggle-button"}
        >
          <IoMdMenu />
        </Button>
      </div>
      <div className="header__logo">
        <Logo />
      </div>
      <div className="header__title">
        <p className="bold font-size-25">{pageName.replaceAll("-", " ")}</p>
      </div>
      <div className="header__controls">
        <Avatar />
      </div>
    </header>
  );
};

export default Header;
