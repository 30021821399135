import { nanoid } from "nanoid";
import { ReactNode } from "react";

type OrderedListType = {
  items: ReactNode[];
};

const OrderedList = ({ items }: OrderedListType) => {
  return (
    <ol className="ordered-list">
      {items.map((item, index) => (
        <li className="ordered-list__item" key={nanoid()}>
          <div className="ordered-list__number">{index + 1}</div>
          <p>{item}</p>
        </li>
      ))}
    </ol>
  );
};

export default OrderedList;
