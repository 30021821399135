import { useNavigate } from "react-router-dom";
import { EnrollmentModel } from "../../../types";
import Button from "../../ui/Button";
import LabelValuePair from "../../ui/LabelValuePair";

type EnrollmentItemProps = {
  enrollment: EnrollmentModel;
};

const EnrollmentItem = ({ enrollment }: EnrollmentItemProps) => {
  const navigate = useNavigate();

  return (
    <div className="panel">
      <div className="flex-row">
        <LabelValuePair label="enrollment type" value="No-Till" />
        <LabelValuePair label="enrollment ID" value={enrollment.dossier_id} />
        <Button
          onClick={() =>
            navigate(
              `/carbon-credit-programs/${"no-till"}/${enrollment.dossier_id}`
            )
          }
          disabled={enrollment.status !== "incomplete"}
        >
          {enrollment.status === "incomplete" ? "continue" : "open"}
        </Button>
      </div>
    </div>
  );
};

export default EnrollmentItem;
