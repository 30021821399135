import { useAuth0 } from "@auth0/auth0-react";
import { FaCommentDots, FaSignOutAlt } from "react-icons/fa";
import Button from "./Button";

const FailedGettingUser = () => {
  const { user, logout } = useAuth0();

  return (
    <div className="failed-getting-user">
      <div className="grid gap-12 padding-24">
        <h1>Sorry, We had an issue retrieving this account.</h1>
        <div>
          <p>
            Account Email: <b>{user?.email}</b>
          </p>
          <p>Please try again later.</p>
          <p>If this issue persists, don't hesitate to contact us.</p>
        </div>
        <div className="flex">
          <Button onClick={() => logout()} variant="second" stretch>
            <FaSignOutAlt />
            sign out
          </Button>
          <Button
            onClick={() =>
              (window.location.href = "https://carbonterra.com/#footer")
            }
            stretch
          >
            <FaCommentDots />
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FailedGettingUser;
