import { useAuth0 } from "@auth0/auth0-react";
import { conditionalClassNames } from "../../helpers";
import Widget from "../layout/Widget";

const PrivacyPolicyPage = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div
      className={conditionalClassNames({
        "margin-24": !isAuthenticated,
      })}
    >
      <Widget title="Privacy Policy">
        <p>
          Last Updated: September <strong>22</strong>, 2022
        </p>
        <p>
          CarbonTerra Limited and its subsidiaries (“<strong>we</strong>,” “
          <strong>our</strong>” or “<strong>us</strong>”) recognize that your
          privacy is very important, and we are committed to protecting your (“
          <strong>your</strong>,” or “<strong>you</strong>”) personal
          information by complying with applicable existing privacy legislation.
        </p>
        <p>
          This privacy policy (“<strong>Privacy Policy</strong>”) describes the
          personal information that CarbonTerra or its service providers
          collect, how it is processed as well as the purposes for such
          processing.
        </p>
        <p>
          We may make certain content, products and other offerings, such as
          climate smart product offerings, carbon product service offerings,
          letters of intent, binding contract offers and acceptances, market
          data, price information, connectivity, contract confirmation, and
          messaging services, to enable the exchange of carbon offset credits
          (collectively, “<strong>Services</strong>”) between and other users,
          including through the Sites, from time to time.
        </p>
        <p>
          In this Privacy Policy, “<strong>Sites</strong>” means any: (i)
          CarbonTerra website, including carbonterra.com; (ii) applications made
          available by CarbonTerra from time to time via a CarbonTerra website
          or in any app store for use on mobile devices, tablets, personal
          computers or other devices; (iii) content offered on or through any of
          the foregoing, including the MyTerra platform; and (iv) modifications
          to any of the foregoing.
        </p>
        <p>
          This Privacy Policy applies to personal information we receive and
          handle in connection with:
        </p>
        <ul>
          <li>access to or use of the Sites;</li>
          <li>access to or use of the Services;</li>
          <li>marketing messages and other communications;</li>
          <li>recruitment processes; and</li>
          <li>
            the ability to connect users with our representatives or advisors.
          </li>
        </ul>
        <p>
          <strong>
            <u>How we collect and use personal information </u>
          </strong>
        </p>
        <p>
          In the course of using the Services, we collect and use information
          for the purposes identified below:
        </p>
        <p>The kinds of personal information we collect and process include:</p>
        <p>
          <strong>Account Creation</strong>: When you create an account with us,
          we may collect information including full name, email, social media
          account, phone number and password that you select to create an
          account with us. We may also collect information about your business,
          commercial operation or your employer. We use this information to
          administer your account and to verify your identity.
        </p>
        <p>
          <strong>Profile Creation</strong>: To create your profile, you provide
          us with personal information including a profile image, linked social
          media accounts, geographic location, employer and job title, and
          business or commercial operation related information. We use this
          information <strong>for</strong> the purpose of publicly marketing
          carbon offsets credits.
        </p>
        <p>
          <strong>Carbon Offset Marketing</strong>: To facilitate the exchange
          of carbon offsets, we may collect personal information about you
          depending on whether you are selling or buying carbon offsets.
        </p>
        <ul>
          <li>
            If you are marketing carbon offsets for sale, we may collect related
            data including relevant location data, satellite imagery data,
            photographs, agronomic data, map data, recording, and information
            about how the carbon offsets were generated, and information
            available from public sources including land registries, records
            databases and government agencies. We use this information for the
            purpose of facilitating the exchange of carbon offset credits, to
            confirm your relationship with the business or commercial enterprise
            associated with the carbon offset credits marketed, and to ensure
            the legitimacy of carbon offset credits marketed through the
            Services
            <strong>. </strong>
          </li>
        </ul>
        <ul>
          <li>
            If you are purchasing carbon offset credits, we may collect personal
            information about you including your name, job title, and employer.
            We collect information about your sales offers and sales history,
            including the details of any offered or completed carbon offset
            transaction] [for the purpose of facilitating the exchange of carbon
            offset credits.
          </li>
        </ul>
        <p>
          <strong>Processing Payments:</strong> We use a third-party payment
          processor to process payments. Your payment card data or account
          information is provided to our payment processor. If you pay using a
          debit or credit card, we only store the last four digits of your
          credit card number, the card type, the expiration number, your postal
          or zip code and the country.
        </p>
        <p>
          <strong>Subscribe to our Mailing List or Newsletter: </strong>You may
          be provided with the option to sign-up to receive our email mailing
          list or newsletter (including offers and promotions). If you sign-up,
          we collect your name, email address and related contact information.
          If you no longer wish to receive these communications, you can
          unsubscribe at any time by clicking the “unsubscribe” link included at
          the bottom of the newsletter. Alternatively, you can opt-out of
          receiving our newsletter by contacting us at the contact information
          under “<strong>Contact Us</strong>” below. Please note that you may
          continue to receive certain transactional or account-related
          communications from us.
        </p>
        <p>
          <strong>Usage Data</strong>: We use certain information to help us
          understand the activity on platform, to monitor and improve the
          platform, and to tailor your platform experience. In addition, we may
          use third party service providers to collect analytical information
          about your use of the platform to help us tailor your platform
          experience, improve our products and the quality of the platform, and
          to manage and analyze data in order to better understand our users.
          This information may include technical data collected about your
          interaction with the Sites and email communications. This data may
          include: IP address; log files; unique device identifiers; pages
          viewed; date and time of access; content of request; username;
          password; usage data; device type; browser type and settings; language
          settings; aggregate statistical information; transferred volume of
          data; and website requesting access.
        </p>
        <p>
          <strong>Social Media: </strong>We may offer you the opportunity to
          engage with our content on or through third-party social networking
          websites, plug-ins and applications. When you engage with our content
          on or through third-party social networking websites, plug-ins and
          applications, you may allow us to have access to certain information
          associated with your social media account (e.g., name, username, email
          address, profile picture, gender) to deliver the content or as part of
          the operation of the website, plug-in or application. When you provide
          information from your social media account, we may use this
          information to personalize your experience on the Website and on the
          third-party social networking websites, plug-ins and applications, and
          to provide you with other products or services you may request.
        </p>
        <p>
          <strong>Contact</strong>: &nbsp;When you contact us with a comment or
          question, or while using our chat function, we collect the information
          you provide such as your username email address and/or social media
          account, &nbsp;along with additional information that we may need to
          help us promptly respond to your inquiry. We retain this information
          to assist you in the future and to improve our customer service and
          service offerings. We also use personal information to establish and
          manage our relationship with you and provide quality service. We may
          call you to confirm your affiliation with a business or commercial
          operation offering carbon offset credits for sale.
        </p>
        <p>
          Some of the above may not constitute personal information under
          applicable privacy laws.
        </p>
        <p>
          <strong>Marketing Communications:</strong> In order to send you
          communications and contact you about our goods and Services, programs,
          events, campaigns, functions or news updates that may be relevant or
          of interest to you, we may collect email address as well as other
          information on an optional basis.
        </p>
        <p>
          <strong>
            <u>Cookies</u>
          </strong>
        </p>
        <p>
          The Sites may use “cookies” (these are files that are implanted in
          your hard drive by some websites to store information about your web
          use patterns) to enhance or personalise your use of the Sites. Cookies
          allow for the collection of information such as:
        </p>
        <ul>
          <li>your browser type;</li>
          <li>time spent on the Sites;</li>
          <li>pages visited; and</li>
          <li>your language preferences.</li>
        </ul>
        <p>
          These cookies may also be used to collect and store information about
          your usage of certain components of the Sites. We or our service
          providers may in any case collect anonymous information that allows us
          to review information about the date, time, and duration of visits to
          the Sites without identifying you.
        </p>
        <p>
          Pixel tags, web beacons, clear GIFs, or other similar technologies may
          be used in connection with the Sites to track the way you use the
          Sites and to compile statistics about Site usage and response rates.
          This information is generally automatically provided to us by your web
          browser.
        </p>
        <p>
          You may choose to refuse cookies by turning them off in the browser;
          however, if you do not accept them, you may experience some difficulty
          in your use of certain components of the Sites.
        </p>
        <p>We may use cookies to:</p>
        <ul>
          <li>improve the functionality of the Sites;</li>
          <li>process your requests and transactions;</li>
          <li>display information more effectively;</li>
          <li>personalize your experience while using the Sites;</li>
          <li>gather information regarding how the Sites are used; and</li>
          <li>compile anonymous and aggregate statistics.</li>
        </ul>
        <p>
          <strong>
            <u>The Circumstances in Which We May Share Personal Information</u>
          </strong>
        </p>
        <p>
          We may transfer Your personal information to our affiliates and other
          third-party service providers (such as entities providing verification
          services, crop analysis, providers of security, marketing, and IT
          services) in connection with the purposes identified above. If you
          make a payment via the Sites or directly to us or one of our dealers,
          your personal information may need to be provided to third parties for
          the purpose of processing such payments. Our service providers are
          only provided with the information they need to perform their
          designated functions and are not authorized to use or disclose
          personal information for their own marketing or other purposes. Our
          service providers may be located in the U.S., Canada, Barbados or
          other foreign jurisdictions. For more information about transfers to
          foreign affiliates or third-party services providers, including
          information about where our service providers process personal
          information and the purposes for such processing, please contact our
          Privacy Officer listed under the “Contact Us” section of this Privacy
          Policy below.
        </p>
        <p>
          We and our Canadian, U.S., Barbados or other foreign service providers
          may provide your personal information in response to a search warrant
          to other legally valid inquiry or order, or to another organization
          for the purposes of investigating a breach of an agreement or
          contravention of law or detecting, suppressing or preventing fraud, or
          as otherwise may be required or permitted by applicable Canadian,
          U.S., Barbadian or other law or legal process, which may include
          lawful access by US or foreign courts, law enforcement or other
          government authorities. Your personal information may also be
          disclosed where necessary for the establishment, exercise or defence
          of legal claims and to investigate or prevent actual or suspected loss
          or harm to persons or property.
        </p>
        <p>
          We may sell, transfer, or otherwise disclose our database of personal
          information to an actual or potential successor entity, purchaser, or
          investor in connection with a corporate merger, consolidation, sale of
          our assets or a substantial part of our assets, share sale, investment
          transaction or other corporate rearrangement.
        </p>
        <p>
          Subject to applicable law, you may withdraw your consent for us to use
          or disclose your personal information at any time. To withdraw this
          consent please contact us using the details below. Please note that
          withdrawing your consent may mean that we are unable to provide you
          with certain Services.
        </p>
        <p>
          <strong>
            <u>Security and Retention of Your Personal Information</u>
          </strong>
        </p>
        <p>
          We process personal information in a manner that ensures appropriate
          security of the personal information, including protection against
          unauthorized or unlawful processing and against accidental loss,
          destruction, or damage, using appropriate physical, technical or
          organizational measures.
        </p>
        <p>
          We have record retention processes designed to retain personal
          information for no longer than necessary for the purposes set out
          herein or as otherwise required to meet legal or business
          requirements.
        </p>
        <p>
          <strong>
            <u>Access to and Correction of Your Personal Information</u>
          </strong>
        </p>
        <p>
          We aim to ensure that your personal information is accurate, complete,
          and up to date. Please contact us via the details below if any of your
          details provided have changed or if you believe that the information
          we hold is inaccurate.
        </p>
        <p>
          You may request us to provide you with access to the personal
          information we hold about you at any time. We will respond to your
          access request as soon as possible, however, prior to disclosing any
          such personal information it may be necessary for you to
          satisfactorily verify your identity.
        </p>
        <p>
          There are exceptional circumstances where access to or correction of
          your personal information may be refused by us, such as where access
          would be unlawful. We will advise you of such circumstances if they
          arise.
        </p>
        <p>
          <strong>
            <u>Marketing</u>
          </strong>
        </p>
        <p>
          We may contact you by phone, email, text or via other electronic
          communication to provide you with marketing information and materials
          about our Services that may be of interest to you. If you do not wish
          to receive such communications, you may contact us or, as applicable,
          follow the unsubscribe options provided in the electronic marketing
          communications. Please note that you may continue to receive
          transactional or account-related communications from us.
        </p>
        <p>
          <strong>
            <u>How You Can Manage Your Privacy</u>
          </strong>
        </p>
        <p>
          As indicated above, if you receive email communications from us, you
          can unsubscribe any time by clicking the “unsubscribe” link included
          at the bottom of the email. Alternatively, you can opt out of
          receiving our marketing communications by contacting us at the contact
          information under “Contact Us” below. You may continue to receive
          transactional or account-related communications from us.
        </p>
        <p>
          You may limit the collection of certain website information by
          deleting or disabling cookies. Most computers’ internet browsers
          enable you to erase stored cookies, block all cookies, or receive a
          warning before a cookie is stored. Disabling cookies may prevent you
          from using specific features on the Sites.
        </p>
        <p>
          Please note that many opt-outs use browser cookies or device controls
          and are specific to the device, or browser, you are using. If you buy
          a new computer, change web browsers or devices, or delete the cookies
          on your computer, you may need to opt out again.
        </p>
        <p>
          <strong>
            <u>Privacy Policy Updates</u>
          </strong>
        </p>
        <p>
          This Privacy Policy will be reviewed from time to time to take account
          of new laws and/or changes to our operations. We recommend that you
          periodically review this policy for any changes.
        </p>
        <p>
          <strong>
            <u>Contact Us</u>
          </strong>
        </p>
        <p>
          We welcome your questions, comments or complaints about this Privacy
          Policy or our privacy practices. You can contact us at:
        </p>
        <p>
          By
          mail:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          Privacy Officer
          <br />
          CarbonTerra Limited
          <br />
          211 - 310 Wall Street
          <br />
          Saskatoon, S7K 1N7, SK, Canada
        </p>
        <p>
          By email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <a href="mailto:privacyofficer@carbonterra.earth">
            privacyofficer@carbonterra.earth
          </a>
        </p>
        <p>
          We have procedures in place to receive and respond to complaints or
          inquiries about our handling of personal information, our compliance
          with this Privacy Policy, and with applicable privacy laws. To discuss
          our compliance with this Privacy Policy or to request access or
          amendments to your personal information, please contact our Privacy
          Officer using the contact information listed above.
        </p>
      </Widget>
    </div>
  );
};

export default PrivacyPolicyPage;
