import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode } from "react";
import {
  FormChangeHandler,
  FormSubmitHandler,
  ProfileModel,
} from "../../../types";
import { validate } from "../../../validators/validate";

type SocialProfileFormProps = {
  profile?: Partial<ProfileModel>;
  onChange?: FormChangeHandler;
  children: (submit: FormSubmitType) => ReactNode;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const SocialProfileForm = ({
  profile,
  onChange,
  children,
  onSubmit,
  serverErrorMessage,
}: SocialProfileFormProps) => {
  return (
    <>
      <QuickForm
        fields={[
          {
            label: "upload profile picture",
            id: "pic",
            type: "image",
            initial: { url: profile?.pic || "", file: "" },
            validators: [],
          },
          {
            label: "bio",
            id: "bio",
            type: "text",
            initial: profile?.bio || "",
            validators: [],
            instructions: (
              <p className="subtitle">tell us about your farm and your experience as a farmer:</p>
            ),
          },
          {
            label: "website",
            id: "website",
            type: "url",
            initial: profile?.website || "",
            validators: [validate.url],
          },
        ]}
        onChange={onChange}
        onSubmit={onSubmit}
        serverError={serverErrorMessage}
      >
        {(submit) => children(submit)}
      </QuickForm>
    </>
  );
};

export default SocialProfileForm;
