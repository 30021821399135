import { ReactNode, useEffect, useRef, useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

type PopupTipType = {
  content: ReactNode;
};

const PopupTip = ({ content }: PopupTipType) => {
  const [contentIsVisible, setContentIsVisible] = useState(false);
  const toggleContent = () => setContentIsVisible((current) => !current);
  let popupTipElement = useRef<HTMLDivElement | null>(null);

  // close on click outside
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        popupTipElement.current &&
        !popupTipElement.current.contains(event.target)
      ) {
        setContentIsVisible(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <span className="popup-tip" ref={popupTipElement}>
      <button className="popup-tip__button" onClick={toggleContent}>
        <FaRegQuestionCircle />
      </button>
      {contentIsVisible && (
        <span className="popup-tip__content">{content}</span>
      )}
    </span>
  );
};

export default PopupTip;
