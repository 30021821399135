import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import OrderedList from "../../ui/OrderedList";

const EnrollmentIntro = () => {
  const navigate = useNavigate();

  return (
    <div className="enrollment-intro">
      <img
        src="/images/couple-in-field.png"
        alt=""
        className="enrollment-intro__image"
      />

      <div className="enrollment-intro__body">
        <div className="enrollment-intro__content">
          <h3>
            Generate carbon credits by enrolling your lands in our programs
          </h3>
          <p>
            We provide multiple carbon credit programs for your farm. This means
            you can enroll each land in your farm into multiple programs to gain
            more credits.
          </p>
        </div>
        <OrderedList
          items={[
            "Select a carbon credit program",
            "Select the land quarters you want to enroll",
            "Complete any program specific steps",
            "Review & submit your enrolled lands",
          ]}
        />
        <Button
          onClick={() => navigate("/carbon-credit-programs")}
          customClasses="enrollment-button"
          stretch
          dataTest={"enrollment-button"}
        >
          Enroll in a Carbon Credit Program
        </Button>
      </div>
    </div>
  );
};

export default EnrollmentIntro;
