import { getSectionDescription } from "../../../helpers";
import { LandSectionModel } from "../../../types";
import LandItem from "../land/LandItem";
import LandItemExcluded from "../land/LandItemExcluded";
import LandSectionDisplay from "./LandSectionDisplay";

type LandSectionItemProps = {
  section: LandSectionModel;
};

const LandSectionItem = ({ section }: LandSectionItemProps) => {
  const getQuarter = (quarter: "NW" | "NE" | "SW" | "SE") => {
    const results = section.lands.filter((land) => land.quarter === quarter);
    if (results.length) {
      return <LandItem land={results[0]} />;
    }
    return (
      <LandItemExcluded lld={quarter + "-" + getSectionDescription(section)} />
    );
  };

  return (
    <div className="land-section-item">
      <LandSectionDisplay section={section} />
      <div className="land-section-item__lands">
        <div className="land-section-item__land">{getQuarter("NW")}</div>
        <div className="land-section-item__land">{getQuarter("NE")}</div>
        <div className="land-section-item__land">{getQuarter("SW")}</div>
        <div className="land-section-item__land">{getQuarter("SE")}</div>
      </div>
    </div>
  );
};

export default LandSectionItem;
