import { nanoid } from "nanoid";
import { useEffect } from "react";
import { conditionalClassNames } from "../helpers";
import { useAccessToken } from "../hooks/useAccessToken";
import { useIndex } from "../hooks/useIndex";
import { AnyObject } from "../types";
import Loading from "./ui/Loading";
import ResourceItem from "./ResourceItem";

type ResourceListProps = {
  resourceName: string;
  adjective?: string;
  endpoint: string;
  parentId?: string;
  version?: string;
  sortKey?: string;
  query?: AnyObject;
  dataKey?: string;
  className?: string;
  grid?: boolean;
  onSuccess?: (items: AnyObject[]) => void;
  // itemsPerPage: number;
};

const ResourceList = ({
  resourceName,
  adjective,
  endpoint,
  parentId,
  version = "v1",
  sortKey,
  query,
  dataKey,
  className,
  grid,
  onSuccess,
}: // itemsPerPage,
ResourceListProps) => {
  const { token } = useAccessToken();
  const pluralize = require("pluralize");

  // const [currentPage, setCurrentPage] = useState(1);
  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage);
  // };
  // useEffect(() => setCurrentPage(1), [endpoint]);

  // const { items, pageCount, isLoading, isError } = useIndex(
  //   instance,
  //   endpoint,
  //   currentPage,
  //   itemsPerPage
  // );

  const { items, isLoading, isError } = useIndex(
    token,
    endpoint,
    query,
    dataKey,
    version
  );

  useEffect(() => {
    typeof onSuccess === "function" && onSuccess(items);
  }, [items, onSuccess]);

  if (isLoading) return <Loading />;
  // if (isError && isError.message === 'page not found') handlePageChange(1);
  if (isError) return <p>Error: {isError.message}</p>;
  return (
    // <Pagination
    //   currentPage={currentPage}
    //   itemsPerPage={itemsPerPage}
    //   pageCount={pageCount}
    //   onPageChange={handlePageChange}
    // >
    <ul
      className={conditionalClassNames(
        {
          "resource-list": true,
          "resource-list--grid": grid === true,
        },
        `${resourceName}-list`
      )}
    >
      {items.length ? (
        items
          .sort((a, b) =>
            sortKey ? parseInt(b[sortKey]) - parseInt(a[sortKey]) : 0
          )
          .map((item) => (
            <ResourceItem
              key={nanoid()}
              resourceName={resourceName}
              parentId={parentId}
              item={item}
            />
          ))
      ) : (
        <div className="panel">
          <p className="empty">
            no {adjective?.replaceAll("_", " ")} {pluralize(resourceName)}.
          </p>
        </div>
      )}
    </ul>
    // </Pagination>
  );
};

export default ResourceList;
