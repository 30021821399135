import { ReactNode } from "react";
import { WizardObject } from "../../../types";

type WizardStepProps = {
  of: WizardObject;
  step: number;
  children: ReactNode;
};

const WizardStep = ({ of: wizard, step, children }: WizardStepProps) => {
  if (wizard.isCurrentStep(step)) {
    return <>{children}</>;
  }
  return null;
};

export default WizardStep;
