import { FaCheckCircle, FaExclamationTriangle, FaHourglassHalf } from "react-icons/fa";
import { MdIncompleteCircle } from "react-icons/md";
import { conditionalClassNames } from "../../helpers";

type StatusType = {
  type: "incomplete" | "pending" | "action required" | "complete";
  message?: string;
};

const Status = ({ type, message }: StatusType) => {
  const getIcon = () => {
    if (type === "incomplete") return <MdIncompleteCircle />
    if (type === "pending") return <FaHourglassHalf />
    if (type === "action required") return <FaExclamationTriangle />
    if (type === "complete") return <FaCheckCircle />;
  };

  return (
    <div
      className={conditionalClassNames({
        status: true,
        "status--incomplete": type === "incomplete",
        "status--pending": type === "pending",
        "status--action-required": type === "action required",
        "status--complete": type === "complete",
      })}
    >
      {message ? message : type} {getIcon()}
    </div>
  );
};

export default Status;
