import { useContext } from "react";
import { UserModel } from "../../../types";
import ResourceList from "../../ResourceList";
import { UserContext } from "../user/providers/UserProvider";

const ListingList = () => {
  const user = useContext(UserContext) as UserModel;
  return (
    <ResourceList
      resourceName="listing"
      endpoint={`party/${user.party.party_id}/order`}
      sortKey="created_ts"
    />
  );
};

export default ListingList;
