import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import { EnrollmentProgramType } from "../../../types";
import Button from "../../ui/Button";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import { useIncompleteEnrollments } from "./hooks/useIncompleteEnrollments";

type EnrollmentNewProps = {
  type: EnrollmentProgramType;
  disabled?: boolean;
};

const EnrollmentButton = ({ type, disabled = false }: EnrollmentNewProps) => {
  const { token } = useAccessToken();
  const navigate = useNavigate();
  const { serverError } = useServerError();
  const [newClaimLoading, setNewClaimLoading] = useState(false);
  const { enrollments, isLoading, isError } = useIncompleteEnrollments(token);

  const getPracticeType = () => {
    if (type === "no-till") return "zero_till";
    return "";
  };

  const handleCreateEnrollment = () => {
    setNewClaimLoading(true);
    const onSuccess = (createdClaim: any) => {
      serverError.clear();
      setNewClaimLoading(false);
      navigate(`/carbon-credit-programs/${type}/${createdClaim.dossier_id}`);
    };
    const onFailure = (error: Error) => {
      serverError.set(error);
      setNewClaimLoading(false);
    };
    yoAPI(
      `dossier?practice_type=${getPracticeType()}`,
      {},
      token,
      onSuccess,
      onFailure
    );
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting data" />;

  return (
    <div className="enrollment-button">
      {enrollments.items.length > 0 ? (
        <Button
          onClick={() =>
            navigate(
              `/carbon-credit-programs/${type.replaceAll(" ", "-")}/${
                enrollments.items[0].dossier_id
              }`
            )
          }
          disabled={disabled}
          stretch
        >
          continue this enrollment
        </Button>
      ) : (
        <div className="flex">
          <Button onClick={handleCreateEnrollment} disabled={disabled} stretch>
            enroll
          </Button>
          {newClaimLoading && <p>loading...</p>}
          {serverError.message && <p>Error: {serverError.message}</p>}
        </div>
      )}
    </div>
  );
};

export default EnrollmentButton;
