import { useState } from 'react';

export type ServerError = {
  message: string;
  set: React.Dispatch<React.SetStateAction<Error | null>>;
  clear: () => void;
};

export const useServerError = () => {
  const [serverError, setServerError] = useState<Error | null>(null);

  const clearServerError = () => setServerError(null);

  return {
    serverError: {
      message: serverError?.message ? serverError.message : '',
      set: setServerError,
      clear: clearServerError,
    },
  };
};
