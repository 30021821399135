import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { getLandDescription, yoAPI } from "../../../../../helpers";
import { useAccessToken } from "../../../../../hooks/useAccessToken";
import { useIndex } from "../../../../../hooks/useIndex";
import {
  AnyObject,
  LandModel,
  LandSectionModel,
  WizardObject,
} from "../../../../../types";
import SelectedItems from "../../../../other/SelectedItems";
import Loading from "../../../../ui/Loading";
import WizardControls from "../../../../ui/wizard/WizardControls";
import LandNew from "../../../land/LandNew";

import LandSelect from "../../../land/LandSelect";

type SelectedLandToEnrollProps = {
  landsToEnroll: LandModel[];
  onChange: (land: LandModel, action: "add" | "remove") => void;
  wizard: WizardObject;
};

const SelectLandsToEnroll = ({
  onChange,
  wizard,
  landsToEnroll,
}: SelectedLandToEnrollProps) => {
  const { enrollmentId } = useParams();
  const { token } = useAccessToken();
  const {
    items: sections,
    isLoading,
    isError,
  } = useIndex(
    token,
    "customer/me/land-section",
    {
      eligible_for: enrollmentId,
      page_size: 1000,
    },
    undefined,
    "v2"
  );

  const handleLandCreated = () => {
    mutate([
      `customer/me/land-section?eligible_for=${enrollmentId}`,
      token,
      "v2",
    ]);
  };

  // links selected lands to current enrollment
  const handleLinkingLandsToEnrollment = () => {
    wizard.setLoading(true);
    const onSuccess = (data: AnyObject) => {
      wizard.setLoading(false);
      wizard.goToNextStep();
    };
    const onFailure = (error: Error) => {
      wizard.setLoading(false);
      console.log(error);
    };
    yoAPI(
      `dossier/${enrollmentId}/land`,
      {
        descriptions: landsToEnroll.map((land) => getLandDescription(land)),
      },
      token,
      onSuccess,
      onFailure,
      "PUT"
    );
  };

  if (isLoading) return <Loading />;
  if (isError) return <p>Error: {isError.message}</p>;
  return (
    <>
      <div>
        <h3>Select the lands you want to enroll</h3>
        <p className="subtitle">
          Please select lands that you would like to enroll into the no-till
          protocol. <br /> These lands should be presently no-till or you must
          be committed to converting them to no-till. <br />
          Any lands that you do not enroll now can be enrolled later.
        </p>
      </div>

      <div className="space-between">
        <h4>your lands (by section):</h4>
        <LandNew asModal onSuccess={handleLandCreated} />
      </div>
      <LandSelect
        selectedLands={landsToEnroll}
        sections={sections as LandSectionModel[]}
        onChange={onChange}
      />
      <WizardControls
        wizard={wizard}
        onNext={handleLinkingLandsToEnrollment}
        nextDisabled={landsToEnroll.length === 0}
      >
        <SelectedItems
          items={landsToEnroll.map((land) => getLandDescription(land))}
          label="lands to enroll"
          noItemsNote="no lands selected."
        />
      </WizardControls>
    </>
  );
};

export default SelectLandsToEnroll;
