import { getLandDescription } from "../../../helpers";
import { LandModel } from "../../../types";
import LandCheckbox from "./LandCheckbox";

type LandBoxProps = {
  land: LandModel;
  selectedLands: LandModel[];
  onChange: (land: LandModel, action: "add" | "remove") => void;
  showCalculatedCredits?: boolean;
};

const LandBox = ({
  land,
  selectedLands,
  onChange,
  showCalculatedCredits = false,
}: LandBoxProps) => {
  let checked =
    selectedLands.filter((currentLand) => currentLand.land_id === land.land_id)
      .length > 0;

  return (
    <div className="land-box">
      <LandCheckbox land={land} checked={checked} onChange={onChange}>
        <p className="bold font-size-18">{getLandDescription(land)}</p>
        {showCalculatedCredits ? (
          <>
            <p className="font-size-14">calculated credits</p>
            <p>$9,414.29</p>
          </>
        ) : (
          <>
            <p className="font-size-14">estimated credits</p>
            <p>$3,922 - $11,767</p>
          </>
        )}
      </LandCheckbox>
    </div>
  );
};

export default LandBox;
