import useSWR from "swr";
import { fetcher } from "../helpers";
import { UserModel } from "../types";

export const useUser = (token: string) => {
  const { data, error } = useSWR(
    token ? ["customer/me", token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    user: data as UserModel,
    isLoading: !error && !data,
    isError: error,
  };
};
