import { useState } from "react";
import { useParams } from "react-router-dom";
import { yoAPI } from "../../../../helpers";
import { useAccessToken } from "../../../../hooks/useAccessToken";
import { useWizard } from "../../../../hooks/useWizard";
import { AnyObject, LandModel } from "../../../../types";
import Widget from "../../../layout/Widget";
import WizardProgress from "../../../ui/wizard/WizardProgress";
import SelectLandsToEnroll from "./steps/SelectLandsToEnroll";
import DeclareFarmingPractices from "./steps/DeclareFarmingPractices";
import UploadSeededAcreageReports from "./steps/UploadSeededAcreageReports";
import UploadSoilSampleReports from "./steps/UploadSoilSampleReports";
import PostEnrollmentDetails from "./steps/PostEnrollmentDetails";
import WizardStep from "../../../ui/wizard/WizardStep";
import EnrollmentReview from "./steps/EnrollmentReview";
import WizardControls from "../../../ui/wizard/WizardControls";

const NoTillProgram = () => {
  const { token } = useAccessToken();
  const { enrollmentId } = useParams();

  // setup the wizard state
  const { wizard } = useWizard([
    "land enrollment",
    "farming practices",
    "seeded acreage reports",
    "soil sample reports",
    "review",
    "congrats",
  ]);

  // keep track of lands selected to be enrolled
  const [landsToEnroll, setLandsToEnroll] = useState<LandModel[]>([]);

  // adds/removes lands to/from "landsToEnroll" state
  const handleLandsToEnrollChange = (
    land: LandModel,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      setLandsToEnroll((current) => [...current, land]);
    }
    if (action === "remove") {
      setLandsToEnroll((current) =>
        current.filter((currentLand) => currentLand.land_id !== land.land_id)
      );
    }
  };

  // submit enrollment
  const handleEnrollmentSubmission = () => {
    wizard.setLoading(true);
    const onSuccess = (data: AnyObject) => {
      wizard.goToNextStep();
    };
    const onFailure = (error: Error) => {
      wizard.setLoading(false);
      console.log(error);
    };
    yoAPI(
      `dossier/${enrollmentId}`,
      {
        status: "pending",
      },
      token,
      onSuccess,
      onFailure,
      "PATCH"
    );
  };

  return (
    <Widget title="no-till">
      <WizardProgress wizard={wizard} />
      <WizardStep of={wizard} step={1}>
        <SelectLandsToEnroll
          landsToEnroll={landsToEnroll}
          onChange={handleLandsToEnrollChange}
          wizard={wizard}
        />
      </WizardStep>
      <WizardStep of={wizard} step={2}>
        <DeclareFarmingPractices lands={landsToEnroll} wizard={wizard} />
      </WizardStep>
      <WizardStep of={wizard} step={3}>
        <UploadSeededAcreageReports
          wizard={wizard}
          onSuccess={wizard.goToNextStep}
        />
      </WizardStep>
      <WizardStep of={wizard} step={4}>
        <UploadSoilSampleReports
          wizard={wizard}
          onSuccess={wizard.goToNextStep}
        />
      </WizardStep>
      <WizardStep of={wizard} step={5}>
        <EnrollmentReview />
        <WizardControls
          wizard={wizard}
          onNext={handleEnrollmentSubmission}
          customNextLabel="submit enrollment"
        />
      </WizardStep>
      <WizardStep of={wizard} step={6}>
        <PostEnrollmentDetails />
      </WizardStep>
    </Widget>
  );
};

export default NoTillProgram;
