import { ReactNode } from "react";

type ModalControlsProps = {
  children: ReactNode;
};

const ModalControls = ({ children }: ModalControlsProps) => {
  return <div className="modal-controls">{children}</div>;
};

export default ModalControls;
