import BusinessItem from "./features/business/BusinessItem";
import DocumentItem from "./features/document/DocumentItem";
import EnrollmentItem from "./features/enrollment/EnrollmentItem";
import LandSectionItem from "./features/land-section/LandSectionItem";
import LandItem from "./features/land/LandItem";
import ListingItem from "./features/listing/ListingItem";

type ResourceItemProps = {
  resourceName: string;
  parentId?: string;
  item: any;
};

const ResourceItem = ({ resourceName, parentId, item }: ResourceItemProps) => {
  const getItemComponent = (resourceName: string, item: any) => {
    switch (resourceName) {
      case "land":
        return <LandItem land={item} />;
      case "land-section":
        return <LandSectionItem section={item} />;
      case "enrollment":
        return <EnrollmentItem enrollment={item} />;
      case "business":
        return <BusinessItem business={item} />;
      case "listing":
        return <ListingItem listing={item} />;
      case "document":
        return <DocumentItem document={item} parentId={parentId || ""} />;
      default:
        return <p>ERROR: Unknown resource item.</p>;
    }
  };
  return getItemComponent(resourceName, item);
};

export default ResourceItem;
