import { nanoid } from "nanoid";
import { FaCheckCircle } from "react-icons/fa";
import { conditionalClassNames } from "../../../helpers";

type WizardProgressProps = {
  wizard: {
    steps: string[];
    stepCount: number;
    isCurrentStep: (index: number) => boolean;
    isDoneStep: (index: number) => boolean;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
  };
};

const WizardProgress = ({ wizard }: WizardProgressProps) => {
  return (
    <div className="wizard-progress">
      {wizard.steps.map((stepName, index) => (
        <div
          className={conditionalClassNames({
            "wizard-progress__step": true,
            "wizard-progress__step--current": wizard.isCurrentStep(index + 1),
            "wizard-progress__step--done": wizard.isDoneStep(index + 1),
          })}
          key={nanoid()}
        >
          <span className="wizard-progress__step-number">{index + 1}</span>
          <div className="wizard-progress__step-name">
            {stepName} {wizard.isDoneStep(index + 1) && <FaCheckCircle />}
          </div>
          <div className="wizard-progress__step-underline"></div>
        </div>
      ))}
    </div>
  );
};

export default WizardProgress;
