const IconLand = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 8H2.75L2 11h5.75V9.75L6 8zM13.25 8H10L8.25 9.75V11H14l-.75-3zM1.875 11.5L1 15h6.75v-3.5H1.875zM15 15l-.875-3.5H8.25V15H15z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.121 1.879A3 3 0 105.88 6.12L8 8.243l2.121-2.122a3 3 0 000-4.242zM8 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      />
    </svg>
  );
};

export default IconLand;
