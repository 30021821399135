import { nanoid } from "nanoid";
import { getLandDescription, landHasPractice } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import LandCreditValue from "../land/LandCreditValue";
import { useEnrollmentLands } from "./hooks/useEnrollmentLands";

type EnrollmentLandsType = {
  enrollmentId?: string;
};

const EnrollmentLands = ({ enrollmentId }: EnrollmentLandsType) => {
  const { token } = useAccessToken();
  const { lands, isLoading, isError } = useEnrollmentLands(token, enrollmentId);

  if (isLoading) return <Loading />;
  if (isError)
    return <ErrorMessage message="failed getting enrollment lands" />;
  return (
    <div>
      <table className="table">
        <thead className="table__head">
          <tr className="table__row">
            <th className="table__cell">Land Description</th>
            <th className="table__cell">Cultivated Acres</th>
            <th className="table__cell">No-Till Start Year</th>
            <th className="table__cell">Chem Fallow Years</th>
            <th className="table__cell">Carbon Credit Value</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {lands.map((land) => (
            <tr className="table__row" key={nanoid()}>
              <td className="table__cell">{getLandDescription(land)}</td>
              <td className="table__cell">
                {land.practices.zero_till?.cultivated_acres}
              </td>
              <td className="table__cell">
                {land.practices.zero_till?.no_till_start_year}
              </td>
              <td className="table__cell">
                {land.practices.zero_till?.chem_fallow_years &&
                land.practices.zero_till?.chem_fallow_years.length > 0 ? (
                  <p className="flex">
                    {land.practices.zero_till?.chem_fallow_years.map((year) => (
                      <span>
                        <p>{year}</p>
                      </span>
                    ))}
                  </p>
                ) : (
                  <p>None</p>
                )}
              </td>
              <td className="table__cell">
                <LandCreditValue
                  landDescription={getLandDescription(land)}
                  practices={land.practices.zero_till}
                  hasPractices={landHasPractice(land, "zero_till")}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EnrollmentLands;
