import { ReactNode } from "react";
import { conditionalClassNames } from "../../helpers";
import PopupTip from "../ui/PopupTip";
// import Tooltip from "../Tooltip";

type WidgetType = {
  title?: string;
  customHeader?: ReactNode;
  icon?: ReactNode;
  tooltip?: ReactNode;
  controls?: ReactNode;
  required?: boolean;
  children: ReactNode;
};

const Widget = ({
  title,
  customHeader,
  icon,
  tooltip,
  controls,
  required,
  children,
}: WidgetType) => {
  return (
    <article className="widget">
      <header
        className={conditionalClassNames({
          widget__header: true,
          "widget__header--with-controls": Boolean(controls),
        })}
      >
        {customHeader ? (
          customHeader
        ) : (
          <div className="widget__title">
            <div className="widget__icon">{icon}</div>
            <h1 className="widget__heading">{title}</h1>
            {required && <span className="widget__required">required</span>}
          </div>
        )}
        {tooltip && <PopupTip content={tooltip} />}
        {controls}
      </header>
      <main className="widget__main">{children}</main>
    </article>
  );
};

export default Widget;
