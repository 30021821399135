import { useContext, useEffect, useState } from "react";
import {
  balanceOf,
  creditsOf,
  listedCreditsOf,
  pendingCreditsOf,
  recommendedSellPriceFor,
  soldCreditsOf,
  totalCreditsOf,
} from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { UserModel } from "../../../types";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import PopupTip from "../../ui/PopupTip";
import Status from "../../ui/Status";
import ListingNew from "../listing/ListingNew";
import { UserContext } from "../user/providers/UserProvider";
import { useUserCredits } from "./hooks/useUserCredits";

const content = {
  standard: {
    name: "standard",
    popupTip: (
      <>
        <p>
          <span className="highlight">standard credits</span>, also known as{" "}
          <b>"avoidance credits"</b> are an older version of removal credits.
        </p>
        <p>
          <b>
            any carbon storage that occurred before 2016 is classified as
            avoidance.
          </b>
        </p>
      </>
    ),
  },
  premium: {
    name: "premium",
    popupTip: (
      <>
        <p>
          <span className="highlight">premium credits</span>, also known as{" "}
          <b>"removal credits "</b> are the most valuable tier of carbon credits
          that's currently available in the industry.
        </p>
        <p>
          <b>
            These are credits that are created by storing carbon permanently any
            time 2016 and onwards.
          </b>
        </p>
      </>
    ),
  },
};

type CreditsType = {
  type: "standard" | "premium";
};

const Credits = ({ type }: CreditsType) => {
  const user = useContext(UserContext) as UserModel;
  const { token } = useAccessToken();
  const { credits, isLoading, isError } = useUserCredits(user, token);
  const [pendingCredits, setPendingCredits] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [listedCredits, setListedCredits] = useState(0);
  const [soldCredits, setSoldCredits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [recommendedSellPrice, setRecommendedSellPrice] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    if (credits && "items" in credits && credits.items.length > 0) {
      setPendingCredits(pendingCreditsOf(type, credits.items));
      setAvailableCredits(creditsOf(type, credits.items));
      setListedCredits(listedCreditsOf(type, credits.items));
      setSoldCredits(soldCreditsOf(type, credits.items));
      setTotalCredits(totalCreditsOf(type, credits.items));
      setRecommendedSellPrice(recommendedSellPriceFor(type));
      setBalance(balanceOf(type, credits.items));
    }
  }, [credits, type]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting user credits" />;
  return (
    <div className="credits">
      <div className="credits__header">
        <div className="credits__title">
          <p>{content[type].name}</p>
          <PopupTip content={content[type].popupTip} />
        </div>
        <Status
          type={pendingCredits ? "pending" : "complete"}
          message={
            pendingCredits
              ? `${pendingCredits} credits pending`
              : "no pending credits"
          }
        />
      </div>
      <div className="credits__summery">
        <div>
          <p className="font-size-14 bold">available</p>
          <p className="font-size-36 bold">{availableCredits}</p>
        </div>
        <div>
          <p className="font-size-12 bold">listed</p>
          <p className="font-size-20 bold">{listedCredits}</p>
        </div>
        <div>
          <p className="font-size-12 bold">total</p>
          <p className="font-size-20 bold">{totalCredits}</p>
        </div>
        <div>
          <p className="font-size-12 bold">sold</p>
          <p className="font-size-20 bold">{soldCredits}</p>
        </div>
      </div>
      <div className="flex gap-4">
        <p className="font-size-14 ">
          Estimated value of available credits: <b>{balance}</b>
        </p>
        <PopupTip
          content={`At our recommended sell price: ${recommendedSellPrice} (${type})`}
        />
      </div>
      <ListingNew type={type} availableCredit={availableCredits} asModal />
    </div>
  );
};

export default Credits;
