import { useContext } from "react";
import { easyAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  BusinessModel,
  FormSuccessHandler,
  QuickFormObject,
  UserModel,
} from "../../../types";
import { UserContext } from "../user/providers/UserProvider";
import BusinessForm from "./BusinessForm";

type BusinessNewProps = {
  onSuccess?: FormSuccessHandler;
};

const BusinessNew = ({ onSuccess }: BusinessNewProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();

  const createBusiness = async (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<BusinessModel>;
    }
  ) => {
    let newBusiness = new FormData();
    if (form.data.business_document instanceof Blob)
      newBusiness.append(
        "ownership_upload",
        form.data.business_document,
        form.data.business_document.name
      );
    newBusiness.append("name", form.data.business_name || "");
    newBusiness.append("business_number", form.data.business_number || "");
    newBusiness.append("gst_number", form.data.business_number || "");
    newBusiness.append("tax_payers_id", form.data.business_number || "");
    newBusiness.append("website", "https://www.google.com/");
    easyAPI(
      `customer/${user.customer_id}/business`,
      newBusiness,
      token,
      form.reset,
      serverError,
      onSuccess,
      "POST",
      "form-data"
    );
  };

  return (
    <BusinessForm
      onSubmit={createBusiness}
      serverErrorMessage={serverError.message}
    />
  );
};

export default BusinessNew;
