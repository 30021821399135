import { useParams } from "react-router-dom";
import { WizardObject } from "../../../../../types";
import WizardControls from "../../../../ui/wizard/WizardControls";
import DocumentList from "../../../document/DocumentList";
import DocumentUpload from "../../../document/DocumentUpload";

type UploadSeededAcreageReportsProps = {
  wizard: WizardObject;
  onSuccess: VoidFunction;
};

const UploadSeededAcreageReports = ({
  wizard,
  onSuccess,
}: UploadSeededAcreageReportsProps) => {
  const { enrollmentId } = useParams();
  return (
    <>
      <div className="list list--small-gap">
        <h3>upload your seeded acreage reports </h3>
        <p className="subtitle">
          Your previous 3 years of SCIC seeded acreage reports are used to
          validate your cultivated acres, the land that you're currently
          farming, and your crop rotation. <br /> If you do not have SCIC seeded
          acreage reports please contact our team.
        </p>
      </div>
      <h4>Uploaded reports</h4>
      <DocumentList
        enrollmentId={enrollmentId}
        category={"seeded acreage report"}
        type={"seeded acreage report"}
      />
      <DocumentUpload
        category={"seeded acreage report"}
        label={"upload new reports"}
        onSubmissionStart={() => wizard.setLoading(true)}
        onSuccess={onSuccess}
      >
        {(submit, uploadInfo) => (
          <WizardControls
            wizard={wizard}
            onNext={submit}
            uploadInfo={uploadInfo}
          />
        )}
      </DocumentUpload>
    </>
  );
};

export default UploadSeededAcreageReports;
