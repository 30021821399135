import { memo } from "react";

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

type LandImageProps = {
  landBounds: number[];
  imageSize: number;
};

const LandImage = memo(({ landBounds, imageSize }: LandImageProps) => {
  return (
    <img
      className="land-item__image"
      src={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/[${landBounds}]/${imageSize}x${imageSize}?access_token=${mapboxAccessToken}&attribution=false&logo=false`}
      alt="land quarter"
    />
  );
});

export default LandImage;
