import { debounce, easyAPI, getLandDescription } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import { FormSuccessHandler, LandModel, QuickFormObject } from "../../../types";
import NoTillForm from "./NoTillForm";

type NoTillEditProps = {
  land: LandModel;
  onSuccess: FormSuccessHandler;
};

const NoTillEdit = ({ land, onSuccess }: NoTillEditProps) => {
  const { token } = useAccessToken();
  const { serverError } = useServerError();

  const updateLandInfo = (form: QuickFormObject) => {
    const newLandPractices = {
      cultivated_acres:
        form.data[`cultivated_acres_${getLandDescription(land)}`],
      no_till_start_year:
        form.data[`no_till_start_year_${getLandDescription(land)}`],
      chem_fallow_years:
        form.data[`chem_fallow_years_${getLandDescription(land)}`],
    };
    easyAPI(
      `land/${land.land_id}/zero-till`,
      newLandPractices,
      token,
      () => {},
      serverError,
      onSuccess,
      "PUT",
      "json",
      "v2"
    );
  };

  const updateOnChange = debounce(updateLandInfo);

  return (
    <NoTillForm
      landDescription={getLandDescription(land)}
      practices={land.practices?.zero_till}
      onChange={updateOnChange}
      onSubmit={updateLandInfo}
      serverErrorMessage={serverError.message}
    />
  );
};

export default NoTillEdit;
