import { QuickForm } from "@catalyst-tech/catalyst-form";
import {
  FormChangeHandler,
  FormSubmitHandler,
  ZeroTillPracticesModel,
} from "../../../types";
import { validate } from "../../../validators/validate";
import ChemFallowYearsField from "../../form/ChemFallowYearsField";

type NoTillFormProps = {
  landDescription: string;
  practices?: ZeroTillPracticesModel;
  onChange?: FormChangeHandler;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const NoTillForm = ({
  landDescription,
  practices,
  onChange,
  onSubmit,
  serverErrorMessage,
}: NoTillFormProps) => {
  return (
    <QuickForm
      fields={[
        {
          label: "cultivated acres",
          id: `cultivated_acres_${landDescription}`,
          type: "text",
          initial: practices?.cultivated_acres?.toString() || "",
          validators: [validate.required, validate.numberAboveZero],
        },
        {
          label: "no-till start year",
          id: `no_till_start_year_${landDescription}`,
          type: "text",
          initial: practices?.no_till_start_year?.toString() || "",
          validators: [validate.required, validate.noTillStartYear],
        },
        {
          label: "chem fallow years",
          id: `chem_fallow_years_${landDescription}`,
          type: "chemFallowYears",
          initial:
            practices?.chem_fallow_years.map((year) => year.toString()) || [],
          validators: [],
        },
      ]}
      customFields={{
        chemFallowYears: (field, form) => {
          return (
            <ChemFallowYearsField
              label={field.label}
              id={field.id}
              value={form.data[field.id]}
              handlers={form.handlers}
              errors={form.errors}
              formData={form.data}
              landDescription={landDescription}
            />
          );
        },
      }}
      onValidChange={onChange}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
    >
      {() => <></>}
    </QuickForm>
  );
};

export default NoTillForm;
