import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormChangeHandler } from "@catalyst-tech/catalyst-form/dist/types";
import {
  RECOMMENDED_SELL_PRICE_PREMIUM,
  RECOMMENDED_SELL_PRICE_STANDARD,
} from "../../../helpers";
import { FormSubmitHandler, OrderModel } from "../../../types";
import { validate } from "../../../validators/validate";
import Button from "../../ui/Button";
import CreditTypeSelect from "../credit-type/CreditTypeSelect";
import { MoneySliderField } from "./MoneySliderField";

type ListingFormType = {
  availableCredit: number;
  type: "standard" | "premium";
  listing?: Partial<OrderModel>;
  onChange?: FormChangeHandler;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const ListingForm = ({
  availableCredit,
  type,
  listing,
  onChange,
  onSubmit,
  serverErrorMessage,
}: ListingFormType) => {
  return (
    <QuickForm
      fields={[
        {
          label: "how many credits to list",
          id: "amount",
          type: "creditListing",
          initial: listing?.amount || "",
          validators: [validate.required, validate.numberAboveZero],
        },
        {
          label: "minimum sell price (per credit)",
          id: "minimum_sell_price",
          type: "minimumSellPrice",
          initial:
            listing?.minimum_sell_price ||
            (type === "standard"
              ? RECOMMENDED_SELL_PRICE_STANDARD.toString()
              : RECOMMENDED_SELL_PRICE_PREMIUM.toString()),
          validators: [validate.required, validate.numberAboveZero],
        },
      ]}
      customFields={{
        creditTypeSelect: (field, form) => (
          <CreditTypeSelect
            label={field.label}
            id={field.id}
            currentCreditType={form.data[field.id] as string}
            handleChange={form.handlers.handleSelectChange}
            errors={form.errors}
          />
        ),
        creditListing: (field, form) => (
          <MoneySliderField
            label={field.label}
            id={field.id}
            instructions={field.instructions}
            value={form.data[field.id] as string}
            handleDirectChange={form.handlers.handleDirectChange}
            handleChange={form.handlers.handleTextChange}
            handleBlur={form.handlers.handleInputBlur}
            errors={form.errors}
            maxValue={availableCredit}
            tweakControls
          />
        ),
        minimumSellPrice: (field, form) => (
          <MoneySliderField
            label={field.label}
            id={field.id}
            instructions={field.instructions}
            value={form.data[field.id] as string}
            handleDirectChange={form.handlers.handleDirectChange}
            handleChange={form.handlers.handleTextChange}
            handleBlur={form.handlers.handleInputBlur}
            errors={form.errors}
            maxValue={
              type === "standard"
                ? RECOMMENDED_SELL_PRICE_STANDARD * 2
                : RECOMMENDED_SELL_PRICE_PREMIUM * 2
            }
          />
        ),
      }}
      onChange={onChange}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
    >
      {(submit) => (
        <Button onClick={submit} stretch>
          list credits for sale
        </Button>
      )}
    </QuickForm>
  );
};

export default ListingForm;
