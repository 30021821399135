import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { conditionalClassNames } from "../../helpers";
import Button from "../ui/Button";

type MultiSelectProps = {
  options: string[];
  id: string;
  value: string[];
  handleChange: (targetInput: string, newValue: string[]) => void;
  emptyMessage: string;
};

const MultiSelectField = ({
  options,
  id,
  value,
  handleChange,
  emptyMessage,
}: MultiSelectProps) => {
  const [showOptionsWindow, setShowOptionsWindow] = useState(false);
  const toggleOptionsWindow = () => setShowOptionsWindow((current) => !current);
  let MultiSelectElement = useRef<HTMLDivElement | null>(null);

  // close on click outside
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        MultiSelectElement.current &&
        !MultiSelectElement.current.contains(event.target)
      ) {
        setShowOptionsWindow(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const [selectedValues, setSelectedValues] = useState<string[]>(
    value.map((value) => value.toString())
  );

  const handleSelectedValuesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.dataset["value"];
    const toAdd = event.target.checked;
    const toRemove = !toAdd;

    if (value) {
      if (toAdd) setSelectedValues((current) => [...current, value]);
      if (toRemove)
        setSelectedValues((current) => current.filter((v) => v !== value));
    }
  };

  const handleChangeRef = useRef(handleChange);
  useLayoutEffect(() => {
    handleChangeRef.current = handleChange;
  }, [handleChange]);

  useEffect(() => {
    handleChangeRef.current(id, selectedValues);
  }, [id, selectedValues]);

  return (
    <div className="multiselect" ref={MultiSelectElement}>
      <div className="flex">
        <Button
          onClick={toggleOptionsWindow}
          variant="second"
          customClasses={conditionalClassNames({
            multiselect__button: true,
            "multiselect__button--open": showOptionsWindow,
          })}
        >
          {selectedValues.length > 0
            ? selectedValues.map((value) => (
                <span className="multiselect__selected-value" key={value}>
                  {value}
                </span>
              ))
            : "click to select"}
            <span className="multiselect__button-icon"><FaCaretDown /></span>
        </Button>
      </div>
      {showOptionsWindow && (
        <div className="multiselect__window">
          {options.length ? (
            options.map((option) => (
              <div
                className={conditionalClassNames({
                  multiselect__option: true,
                  "  multiselect__option--selected":
                    selectedValues.includes(option),
                })}
                key={option}
              >
                <input
                  className="form__checkbox"
                  type="checkbox"
                  name={id + "_checkbox_" + option}
                  id={id + "_checkbox_" + option}
                  checked={selectedValues.includes(option)}
                  data-value={option}
                  onChange={handleSelectedValuesChange}
                  // hidden
                />
                <label
                  className={conditionalClassNames({
                    form__label: true,
                    "form__label--no-tag": true,
                    "multiselect__option-label": true,
                    "multiselect__option-label--selected":
                      selectedValues.includes(option),
                  })}
                  htmlFor={id + "_checkbox_" + option}
                >
                  {option}
                </label>
              </div>
            ))
          ) : (
            <p className="grid-full-span">{emptyMessage}</p>
          )}
          {/* <FieldErrors list={errors[id]} /> */}
        </div>
      )}
    </div>
  );
};

export default MultiSelectField;
