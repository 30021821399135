import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode, useContext } from "react";
import { mutate } from "swr";
import { easyAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  FormSuccessHandler,
  ProfileModel,
  QuickFormObject,
  UserModel,
} from "../../../types";
import { UserContext } from "../user/providers/UserProvider";
import UserIdentityForm from "./UserIdentityForm";

type UserIdentityEditProps = {
  children: (submit: FormSubmitType) => ReactNode;
  onSuccess?: FormSuccessHandler;
};

const UserIdentityEdit = ({ children, onSuccess }: UserIdentityEditProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();

  const updateProfile = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<ProfileModel>;
    }
  ) => {
    const newProfile = new FormData();
    newProfile.append("given_name", form.data.given_name || "");
    newProfile.append("family_name", form.data.family_name || "");

    const onAPISuccess = (new_profile: ProfileModel) => {
      mutate(["customer/me", token]);
      typeof onSuccess === "function" && onSuccess(new_profile);
    };

    easyAPI(
      "customer/me/profile",
      newProfile,
      token,
      () => {},
      serverError,
      onAPISuccess,
      "POST",
      "form-data"
    );
  };

  return (
    <UserIdentityForm
      profile={user}
      onSubmit={updateProfile}
      serverErrorMessage={serverError.message}
    >
      {(submit) => children(submit)}
    </UserIdentityForm>
  );
};

export default UserIdentityEdit;
