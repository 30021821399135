import { useContext, useState } from "react";
import { easyAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useModal } from "../../../hooks/useModal";
import { useServerError } from "../../../hooks/useServerError";
import {
  FormSuccessHandler,
  LandModel,
  QuickFormObject,
  UserModel,
} from "../../../types";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import LandForm from "./LandForm";
import { FaPlus } from "react-icons/fa";
import { UserContext } from "../user/providers/UserProvider";

type LandNewProps = {
  asModal?: boolean;
  buttonVariant?: "primary" | "second";
  onSuccess?: FormSuccessHandler;
};

const LandNew = ({
  asModal = false,
  buttonVariant = "primary",
  onSuccess,
}: LandNewProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();
  const { modal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const createLand = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<LandModel>;
    }
  ) => {
    setIsLoading(true);
    let llds = [];

    // TODO: clean this up
    if (typeof form.data.quarter === "string") {
      if (["NE", "NW", "SE", "SW"].includes(form.data.quarter)) {
        llds.push(
          `${form.data.quarter}-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
        );
      } else {
        if (form.data.quarter === "ALL") {
          llds.push(
            `NE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `NW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `SE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `SW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
        }
        if (form.data.quarter === "N") {
          llds.push(
            `NE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `NW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
        }
        if (form.data.quarter === "S") {
          llds.push(
            `SE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `SW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
        }
        if (form.data.quarter === "E") {
          llds.push(
            `NE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `SE-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
        }
        if (form.data.quarter === "W") {
          llds.push(
            `NW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
          llds.push(
            `SW-${form.data.section}-${form.data.township}-${form.data.range}-${form.data.meridian}`
          );
        }
      }
    }

    const newLand = {
      llds: llds,
    };

    const onAPISuccess = (createdLand: LandModel) => {
      setIsLoading(false);
      modal.close();
      typeof onSuccess === "function" && onSuccess(createdLand);
    };

    easyAPI(
      `customer/${user.customer_id}/land`,
      newLand,
      token,
      form.reset,
      serverError,
      onAPISuccess
    );
  };

  if (asModal)
    return (
      <>
        <Button onClick={() => modal.open("new-land")} variant={buttonVariant}>
          <FaPlus /> add new land
        </Button>
        <Modal
          visibility={modal.visibility("new-land")}
          handleClose={modal.close}
          title="new land"
          id="new-land"
        >
          <LandForm
            onSubmit={createLand}
            isLoading={isLoading}
            serverErrorMessage={serverError.message}
          />
        </Modal>
      </>
    );

  return (
    <LandForm onSubmit={createLand} serverErrorMessage={serverError.message} />
  );
};

export default LandNew;
