import { ReactElement, useContext, useEffect, useRef } from "react";
import { FaCoins, FaCommentDots, FaFileInvoice, FaMap } from "react-icons/fa";
import {
  MdBusinessCenter,
  MdDashboard,
  MdOutlineSupport,
  MdRequestQuote,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { conditionalClassNames } from "../helpers";
import { useModal } from "../hooks/useModal";
import Button from "./ui/Button";
import IconLand from "./ui/icons/IconLand";
import Modal from "./ui/Modal";
import ModalControls from "./ui/ModalControls";
import { MenuContext } from "./ui/providers/MenuProvider";

type MenuItemType = {
  path: string;
  text: string;
  icon: ReactElement;
  comingSoon?: boolean;
};

const MenuItems: MenuItemType[] = [
  {
    path: "/dashboard",
    text: "dashboard",
    icon: <MdDashboard />,
  },
  {
    path: "/my-farm",
    text: "my farm",
    icon: <IconLand />,
  },
  {
    path: "/carbon-credits",
    text: "My Carbon Credits",
    icon: <FaCoins />,
  },
  {
    path: "/my-enrolled-lands",
    text: "My Enrolled Lands",
    icon: <FaFileInvoice />,
  },
  {
    path: "/listings",
    text: "Credits Listed for Sale",
    icon: <MdRequestQuote />,
  },
  {
    path: "/businesses",
    text: "My Businesses",
    icon: <MdBusinessCenter />,
  },
  {
    path: "/variable-rate-maps",
    text: "Variable Rate Maps",
    icon: <FaMap />,
    comingSoon: true,
  },
  {
    path: "/support",
    text: "support",
    icon: <MdOutlineSupport />,
  },
];

const Menu = () => {
  const menuContext = useContext(MenuContext);
  const { modal } = useModal();

  // close on click outside
  let MenuElement = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        MenuElement.current &&
        !MenuElement.current.contains(event.target) &&
        !event.target.classList.contains("menu-toggle-button")
      ) {
        menuContext?.closeMenu();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [menuContext]);

  const handleClickLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: MenuItemType
  ) => {
    if (item.comingSoon === true) {
      event.preventDefault();
      modal.open(item.path.replace("/", ""));
      return;
    }
    menuContext?.closeMenu();
  };

  return (
    <>
      <nav
        ref={MenuElement}
        className={conditionalClassNames({
          menu: true,
          "menu--closed": !menuContext?.isOpen,
        })}
      >
        {MenuItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              conditionalClassNames({
                menu__link: true,
                "menu__link--active": isActive,
                "menu__link--coming-soon": item.comingSoon ?? false,
              })
            }
            onClick={(e) => handleClickLink(e, item)}
          >
            {item.icon} {item.text}
          </NavLink>
        ))}
      </nav>
      <Modal
        visibility={modal.visibility("variable-rate-maps")}
        handleClose={modal.close}
        title={"variable rate maps"}
        id={"variable-rate-maps"}
      >
        <div>
          <p>
            Variable rate desiccation and fungicide maps are coming soon to our
            platform.
          </p>
          <p>
            If you're interested in ordering early at a discounted price, please
            contact us.
          </p>
        </div>
        <ModalControls>
          <Button
            onClick={() =>
              (window.location.href = "https://carbonterra.com/#footer")
            }
          >
            <FaCommentDots />
            Contact Us
          </Button>
        </ModalControls>
      </Modal>
    </>
  );
};

export default Menu;
