import { FaCommentDots } from "react-icons/fa";
import Widget from "../layout/Widget";
import Button from "../ui/Button";

const SupportPage = () => {
  return (
    <Widget title="Contact Us">
      <Button
        onClick={() =>
          (window.location.href = "https://carbonterra.com/#footer")
        }
      >
        <FaCommentDots />
        Contact Us
      </Button>
    </Widget>
  );
};

export default SupportPage;
