import {
  FormSubmitType,
  ImageFieldType,
} from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode, useContext } from "react";
import { mutate } from "swr";
import { easyAPI, formImageToBe } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  FormSuccessHandler,
  ProfileModel,
  QuickFormObject,
  UserModel,
} from "../../../types";
import { UserContext } from "../user/providers/UserProvider";
import SocialProfileForm from "./SocialProfileForm";

type SocialProfileEditProps = {
  children: (submit: FormSubmitType) => ReactNode;
  onSuccess?: FormSuccessHandler;
};

const SocialProfileEdit = ({ children, onSuccess }: SocialProfileEditProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();

  const updateProfile = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<Omit<ProfileModel, "pic">> & { pic: ImageFieldType };
    }
  ) => {
    const newProfile = new FormData();
    if (formImageToBe("uploaded", form.data.pic)) {
      let file = form.data.pic.file as File;
      newProfile.append("pic", file, file.name);
    }
    if (formImageToBe("removed", form.data.pic))
      newProfile.append("remove_pic", "true");
    form.data.bio
      ? newProfile.append("bio", form.data.bio)
      : newProfile.append("remove_bio", "true");
    form.data.website
      ? newProfile.append("website", form.data.website)
      : newProfile.append("remove_website", "true");

    const onAPISuccess = (new_profile: ProfileModel) => {
      mutate(["customer/me", token]);
      typeof onSuccess === "function" && onSuccess(new_profile);
    };

    easyAPI(
      "customer/me/profile",
      newProfile,
      token,
      () => {},
      serverError,
      onAPISuccess,
      "POST",
      "form-data"
    );
  };

  return (
    <SocialProfileForm
      profile={user}
      onSubmit={updateProfile}
      serverErrorMessage={serverError.message}
    >
      {(submit) => children(submit)}
    </SocialProfileForm>
  );
};

export default SocialProfileEdit;
