import { FaCheckCircle } from "react-icons/fa";
import { mutate } from "swr";
import {
  conditionalClassNames,
  getLandDescription,
  landHasPractice,
} from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useLand } from "../../../hooks/useLand";
import { ZeroTillPracticesModel } from "../../../types";
import Loading from "../../ui/Loading";
import NoTillEdit from "../land-info/NoTillEdit";
import LandCreditValue from "./LandCreditValue";

type LandPracticeType = {
  landId: string;
  onUpdate: (landId: string) => void;
};

const LandPractice = ({ landId, onUpdate }: LandPracticeType) => {
  const { token } = useAccessToken();
  const { land, isLoading, isError } = useLand(token, landId);

  const handleLandUpdated = (newPractices: ZeroTillPracticesModel) => {
    mutate([`land/${landId}`, token]);
    onUpdate(newPractices.land_id);
  };

  if (isLoading) return <Loading />;
  if (isError) return <p>Error: {isError.message}</p>;
  return (
    <div
      className={conditionalClassNames({
        "land-practice": true,
        "land-practice--declared": landHasPractice(land, "zero_till"),
      })}
    >
      <div className="land-practice__header">
        <p>{getLandDescription(land)}</p>
        {landHasPractice(land, "zero_till") && <FaCheckCircle />}
      </div>
      <div className="land-practice__body">
        <NoTillEdit land={land} onSuccess={handleLandUpdated} />
      </div>
      <div className="land-practice__footer">
        <LandCreditValue
          landDescription={getLandDescription(land)}
          practices={land.practices.zero_till}
          hasPractices={landHasPractice(land, "zero_till")}
        />
      </div>
    </div>
  );
};

export default LandPractice;
