import { formatMoney } from "../../../helpers";
import { ZeroTillPracticesModel } from "../../../types";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import PopupTip from "../../ui/PopupTip";
import { useCarbonTerraEstimations } from "./hooks/useCarbonTerraEstimations";

const defaultBestCase = {
  best_cultivated_acres: 160,
  best_zero_till_range: [2007, 2022],
  best_chem_fallow_years: [],
};

const defaultWorstCase = {
  worst_cultivated_acres: 160,
  worst_zero_till_range: [2017, 2022],
  worst_chem_fallow_years: [],
};

type LandCreditValueProps = {
  landDescription: string;
  practices?: ZeroTillPracticesModel;
  hasPractices: boolean;
};

const LandCreditValue = ({
  landDescription,
  practices,
  hasPractices,
}: LandCreditValueProps) => {
  console.log(practices);

  const getBestCase = () => {
    if (hasPractices) {
      return {
        best_cultivated_acres: practices?.cultivated_acres,
        best_zero_till_range: [practices?.no_till_start_year, 2022],
        best_chem_fallow_years: practices?.chem_fallow_years.map((year) =>
          parseInt(year.toString())
        ),
      };
    }
    return defaultBestCase;
  };

  const { estimations, isLoading, isError } = useCarbonTerraEstimations({
    lld: landDescription,
    ...getBestCase(),
    ...defaultWorstCase,
  });

  const getWorstTotal = () => {
    let standard = estimations.worst_case.upfront.avoidance * 10;
    let premium = estimations.worst_case.upfront.removal * 20;
    return formatMoney(standard + premium);
  };

  const getBestTotal = () => {
    let standard = estimations.best_case.upfront.avoidance;
    let premium = estimations.best_case.upfront.removal;
    let total = standard + premium;
    return total.toFixed(1);
  };

  const getBestTotalValue = () => {
    let standard = estimations.best_case.upfront.avoidance * 10;
    let premium = estimations.best_case.upfront.removal * 20;
    let total = standard + premium;
    return formatMoney(total);
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting data" />;

  if (hasPractices) {
    return (
      <p className="font-size-14">
        this land generates{" "}
        <span className="bold color-brand-500">{getBestTotal()}</span> total
        upfront credits at an estimated total value of{" "}
        <span className="bold color-brand-500 font-size-18">
          {getBestTotalValue()}
        </span>
        <PopupTip content={"based on our recommended sell prices."} />
      </p>
    );
  }

  return (
    <p className="font-size-14">
      this land could generate a total upfront value between{" "}
      <span className="bold color-brand-500">{getWorstTotal()}</span> and{" "}
      <span className="bold color-brand-500">{getBestTotalValue()}</span>
      <PopupTip content={"based on our recommended sell prices."} />
    </p>
  );
};

export default LandCreditValue;
