import { ReactNode } from "react";
import { UploadInfo, WizardObject } from "../../../types";
import Button from "../Button";
import Loading from "../Loading";

type WizardControlsProps = {
  wizard: WizardObject;
  onNext: () => any;
  onPrevious?: () => any;
  customNextLabel?: string;
  nextDisabled?: boolean;
  uploadInfo?: UploadInfo;
  children?: ReactNode;
};

const WizardControls = ({
  wizard,
  onNext,
  onPrevious,
  customNextLabel,
  nextDisabled,
  uploadInfo,
  children,
}: WizardControlsProps) => {
  const handlePreviousClick = () => {
    if (typeof onPrevious === "function") {
      let formIsSubmitted = onPrevious();
      if (formIsSubmitted === true) wizard.setLoading(true);
    } else {
      wizard.goToPreviousStep();
    }
  };

  const handleNextClick = () => {
    onNext();
  };

  const getNextLabel = () => {
    if (typeof customNextLabel === "string") {
      return customNextLabel;
    }
    return "next";
  };

  return (
    <div className="wizard-controls">
      <div>{children}</div>
      <div className="wizard-controls__controls">
        {wizard.isLoading && <Loading />}
        {!wizard.isCurrentStep(1) && ( // hide previous button if it's the first wizard step
          <Button
            onClick={handlePreviousClick}
            variant="second"
            disabled={wizard.isLoading}
          >
            previous
          </Button>
        )}
        <Button
          onClick={handleNextClick}
          disabled={nextDisabled || wizard.isLoading}
        >
          {getNextLabel()}
          {/* {uploadInfo?.progress} */}
        </Button>
      </div>
    </div>
  );
};

export default WizardControls;
