import { Outlet } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Onboarding from "./components/pages/Onboarding";
import { doneOnboarding } from "./helpers";
import MenuProvider from "./components/ui/providers/MenuProvider";
import { UserContext } from "./components/features/user/providers/UserProvider";
import { useContext } from "react";

const App = () => {
  const user = useContext(UserContext);

  if (!user) return <p>hiu</p>;

  if (!doneOnboarding(user))
    return (
      <MenuProvider>
        <Layout type="isolated">
          <Onboarding user={user} />
        </Layout>
      </MenuProvider>
    );

  return (
    <MenuProvider>
      <Layout>
        <Outlet />
      </Layout>
    </MenuProvider>
  );
};

export default App;
