import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { EnrollmentModel } from "../../../../types";

export const useEnrollment = (token: string, enrollmentId: string = "") => {
  const { data, error } = useSWR(
    token ? [`dossier/${enrollmentId}`, token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    enrollment: data as EnrollmentModel,
    isLoading: !error && !data,
    isError: error,
  };
};
