import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode } from "react";
import { FormChangeHandler, FormSubmitHandler, ProfileModel } from "../../../types";
import { validate } from "../../../validators/validate";


type UserIdentityFormProps = {
  profile?: Partial<ProfileModel>;
  onChange?: FormChangeHandler;
  children: (submit: FormSubmitType) => ReactNode;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const UserIdentityForm = ({
  profile,
  onChange,
  children,
  onSubmit,
  serverErrorMessage,
}: UserIdentityFormProps) => {
  return (
    <>
      <QuickForm
        fields={[
          {
            label: "legal first name",
            id: "given_name",
            type: "text",
            initial: profile?.party?.given_name || "",
            validators: [validate.required],
          },
          {
            label: "legal family name",
            id: "family_name",
            type: "text",
            initial: profile?.party?.family_name || "",
            validators: [validate.required],
          },
        ]}
        onChange={onChange}
        onSubmit={onSubmit}
        serverError={serverErrorMessage}
      >
        {(submit) => (children(submit))}
      </QuickForm>
    </>
  );
};

export default UserIdentityForm;
