import useSWR from "swr";
import { estimationFetcher } from "../../../../helpers";
import { AnyObject } from "../../../../types";

export const useCarbonTerraEstimations = (query: AnyObject) => {
  let endpoint =
    "https://carbonterra.azurewebsites.net/v1/estimate/lld/zero-till/carbon-per-year";

  const { data, error } = useSWR([endpoint, query], estimationFetcher);

  if (error) {
    console.log(error);
  }

  return {
    estimations: data as {
      best_case: {
        annual_c02: number;
        total_c02: number;
        upfront: {
          removal: number;
          avoidance: number;
        };
      };
      worst_case: {
        annual_c02: number;
        total_c02: number;
        upfront: {
          removal: number;
          avoidance: number;
        };
      };
    },
    isLoading: !error && !data,
    isError: error,
  };
};
