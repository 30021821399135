import { useRef } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { mutate } from "swr";
import { yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { DocumentModel } from "../../../types";
import Button from "../../ui/Button";

type DocumentItemProps = {
  document: DocumentModel;
  parentId: string;
};

const DocumentItem = ({ document, parentId }: DocumentItemProps) => {
  const { token } = useAccessToken();
  const documentItem = useRef<HTMLDivElement | null>(null);

  const onSuccess = () => {
    mutate([
      `dossier/${parentId}/document?category=${document.category}&document_type=${document.document_type}`,
      token,
    ]);
  };
  const onFailure = () => {};

  const handleDeleteDocument = () => {
    documentItem.current?.classList.add("remove");
    yoAPI(
      `dossier/${parentId}/document/${document.document_id}`,
      {},
      token,
      onSuccess,
      onFailure,
      "DELETE"
    );
  };

  return (
    <div className="document-item" ref={documentItem}>
      <a className="document-item__link" href={document.link}>
        {document.filename}
      </a>
      <Button onClick={handleDeleteDocument} variant="danger">
        <FaRegTrashAlt />
      </Button>
    </div>
  );
};

export default DocumentItem;
