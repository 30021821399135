import { QuickForm } from "@catalyst-tech/catalyst-form";
import {
  FormChangeHandler,
  FormSubmitHandler,
  ProfileModel,
} from "../../../types";
import { validate } from "../../../validators/validate";
import Button from "../../ui/Button";

type ProfileFormProps = {
  profile?: Partial<ProfileModel>;
  onChange?: FormChangeHandler;
  submitLabel?: string;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const ProfileForm = ({
  profile,
  onChange,
  submitLabel,
  onSubmit,
  serverErrorMessage,
}: ProfileFormProps) => {
  return (
    <QuickForm
      fields={[
        {
          label: "legal first name",
          id: "given_name",
          type: "text",
          initial: profile?.party?.given_name || "",
          validators: [validate.required],
        },
        {
          label: "legal family name",
          id: "family_name",
          type: "text",
          initial: profile?.party?.family_name || "",
          validators: [validate.required],
        },
        {
          label: "account name",
          id: "farm_name",
          type: "text",
          initial: profile?.farm_name || "",
          validators: [validate.required],
          instructions: (
            <p className="font-size-14 color-neutral-600">
              Account name is just a nickname for your account, and it can be
              whatever you call your farm.
            </p>
          ),
        },
        {
          label: "province",
          id: "province",
          type: "text",
          initial: profile?.province || "",
          validators: [validate.required],
        },
        {
          label: "city",
          id: "city",
          type: "text",
          initial: profile?.city || "",
          validators: [validate.required],
        },
        {
          label: "profile picture",
          id: "pic",
          type: "file",
          initial: "",
          validators: [],
        },
        {
          label: "bio",
          id: "bio",
          type: "text",
          initial: profile?.bio || "",
          validators: [],
        },
        {
          label: "website",
          id: "website",
          type: "text",
          initial: profile?.website || "",
          validators: [],
        },
      ]}
      onChange={onChange}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
    >
      {(submit) => (
        <Button onClick={submit}>
          {submitLabel ? submitLabel : "save profile"}
        </Button>
      )}
    </QuickForm>
  );
};

export default ProfileForm;
