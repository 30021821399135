import { LandSectionModel } from "../../../types";

type LandSectionDisplayProps = {
  section: LandSectionModel;
};

const LandSectionDisplay = ({ section }: LandSectionDisplayProps) => {
  return (
    <div className="land-section-display">
      <div className="land-section-display__part">
        <p className="land-section-display__part-name">section</p>
        <p className="land-section-display__part-value">{section.section}</p>
      </div>
      <div className="land-section-display__part">
        <p className="land-section-display__part-name">township</p>
        <p className="land-section-display__part-value">{section.township}</p>
      </div>
      <div className="land-section-display__part">
        <p className="land-section-display__part-name">range</p>
        <p className="land-section-display__part-value">{section.range}</p>
      </div>
      <div className="land-section-display__part">
        <p className="land-section-display__part-name">meridian</p>
        <p className="land-section-display__part-value">{section.meridian}</p>
      </div>
    </div>
  );
};

export default LandSectionDisplay;
