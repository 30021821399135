type errorsPropsType = {
  list: string[];
};

const Errors: React.FC<errorsPropsType> = ({ list = [] }) => {
  return (
    <ul className="errors">
      {list.map((error) => (
        <li key={error} className="errors__issue">
          {error}
        </li>
      ))}
    </ul>
  );
};

export default Errors;
