import mapboxgl, { LngLatLike, Map, PointLike } from "mapbox-gl";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { KeyedMutator } from "swr";
import { yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { LandModel, UserModel } from "../../../types";
import IconTractorLoading from "../../ui/icons/IconTractorLoading";
import { UserContext } from "../user/providers/UserProvider";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibmlkYWxnaG9uYWltIiwiYSI6ImNrNnBhNHJkbDFmOXAzdnJyMnlhdGNlYW0ifQ.mxQwFFMKXrfq_tW95e1XWQ";

const mapStyles = {
  satellite: "mapbox://styles/mapbox/satellite-streets-v12",
  streets: "mapbox://styles/mapbox/streets-v12",
};

type LandMapProps = {
  lands: LandModel[];
  center: LngLatLike | null;
  mapStyle: "satellite" | "streets";
  editModeEnabled: boolean;
  mutateLands: KeyedMutator<any>;
};

const LandMap = ({
  lands,
  center,
  mapStyle,
  editModeEnabled,
  mutateLands,
}: LandMapProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const [lng, setLng] = useState(-100);
  const [lat, setLat] = useState(50);
  const [zoom, setZoom] = useState(1);
  const [initialAnimationPlayed, setInitialAnimationPlayed] = useState(false);
  const hoveredQuarterId = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const userLLDs = useMemo(
    () => lands.map((land) => land.description.description_string),
    [lands]
  );

  // console.log("lands count in map state: ", lands.length);

  useEffect(() => {
    if (!map.current) return;
    // ##  console.log("toggle map style");
    map.current.setStyle(mapStyles[mapStyle]);
  }, [mapStyle]);

  useEffect(() => {
    if (!map.current) return;
    if (!map.current.getLayer("quarters-grid")) return;
    if (!map.current.getLayer("quarters-owned")) return;
    if (!map.current.getLayer("quarters-selected")) return;
    if (!map.current.getLayer("quarters-hovered")) return;
    if (editModeEnabled) {
      // update layer paint properties
      map.current!.setPaintProperty("quarters-grid", "line-opacity", 1);
      map.current!.setPaintProperty("quarters-grid", "line-width", 2);
      map.current!.setPaintProperty("quarters-owned", "fill-opacity", 0);
      map.current!.setPaintProperty("quarters-selected", "fill-opacity", 1);
      map.current!.setPaintProperty("quarters-hovered", "fill-opacity", [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.5,
        0,
      ]);
    } else {
      // update layer paint properties
      map.current!.setPaintProperty("quarters-grid", "line-opacity", 0);
      map.current!.setPaintProperty("quarters-grid", "line-width", 1);
      map.current!.setPaintProperty("quarters-owned", "fill-opacity", 0.8);
      map.current!.setPaintProperty("quarters-selected", "fill-opacity", 0);
      map.current!.setPaintProperty("quarters-hovered", "fill-opacity", 0);
    }
    // map.current!.setFilter("quarters-selected", ["in", "lld", ...userLLDs]);
    // map.current!.setFilter("quarters-owned", ["in", "lld", ...userLLDs]);
  }, [editModeEnabled, userLLDs]);

  // update filtered lands on selected lands change
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (!map.current.getLayer("quarters-owned")) return;
    if (!map.current.getLayer("quarters-selected")) return;
    // ##  console.log("filtering layers");
    map.current!.setFilter("quarters-selected", ["in", "lld", ...userLLDs]);
    map.current!.setFilter("quarters-owned", ["in", "lld", ...userLLDs]);
  }, [userLLDs]);

  const handleAddLandQuarter = useCallback(
    (newLLD: string) => {
      // update the loading state
      setLoading(true);

      // handle API
      const onSuccess = (response: { lands: LandModel[] }) => {
        // get the newly created land from the API
        let newLand = response.lands[0];
        // mutate the user lands (in the swr hook)
        mutateLands({
          items: [...lands, newLand],
        });
        // update the loading state
        setLoading(false);
      };
      const onFailure = () => {
        setLoading(false);
      };
      yoAPI(
        `customer/${user.customer_id}/land`,
        { llds: [newLLD.replaceAll("-0", "-")] }, // TODO: no need to replace anymore
        token,
        onSuccess,
        onFailure
      );
    },
    [lands, token, user.customer_id, mutateLands]
  );

  const handleDeleteLandQuarter = useCallback(
    (landDescription: string) => {
      // update the loading state
      setLoading(true);

      // handle API
      // get the land to delete
      let search = lands.filter(
        (land) => land.description.description_string === landDescription
      );
      // warn & return if none is found
      if (search.length === 0) {
        console.warn(
          "could not find a land (to delete) with the description: ",
          landDescription
        );
        return;
      }
      // get the land to delete from the search array
      let landToDelete = search[0];
      const onSuccess = () => {
        // mutate the user lands (in the swr hook)
        mutateLands({
          items: lands.filter(
            (land) => land.description.description_string !== landDescription
          ),
        });
        // update the loading state
        setLoading(false);
      };
      const onFailure = () => {
        setLoading(false);
      };
      yoAPI(
        `customer/${user.customer_id}/land/${landToDelete.land_id}`,
        {},
        token,
        onSuccess,
        onFailure,
        "DELETE"
      );
    },
    [user.customer_id, token, mutateLands, lands]
  );

  // map initialization
  useEffect(() => {
    if (map.current) return;
    if (document.getElementsByClassName("map-container").length === 0) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current as string | HTMLElement, // container ID
      style: mapStyles[mapStyle], // style URL
      center: [lng, lat], // starting position [lng, lat]
      zoom: zoom, // starting zoom
      pitch: 0,
      doubleClickZoom: false,
      attributionControl: false,
    });
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      }),
      "top-left"
    );

    // map.current.addControl(new mapboxgl.FullscreenControl());
  }, [lands, lat, lng, zoom, mapStyle]);

  // animate to first user lands
  useEffect(() => {
    if (initialAnimationPlayed) return;
    if (lands.length === 0) return;
    
    setInitialAnimationPlayed(true);

    let newCenter = lands[0].center as [number, number];
    newCenter = [newCenter[1] + 0.004, newCenter[0]];

    map.current!.flyTo({
      center: newCenter,
      essential: true,
      zoom: 15,
      duration: 6000,
      easing: (x: number) => 1 - Math.sqrt(1 - Math.pow(x, 2)),
    });
  }, [initialAnimationPlayed, lands]);

  useEffect(() => {
    if (!map.current || !center) return; // wait for map to initialize
    map.current!.flyTo({
      center: center,
      essential: true,
      zoom: 15,
    });
  }, [center]);

  // update current location & zoom
  useEffect(() => {
    if (map.current) {
      map.current.on("move", () => {
        setLng(map.current!.getCenter().lng);
        setLat(map.current!.getCenter().lat);
        setZoom(map.current!.getZoom());
      });
    }
  });

  // load sources & layers
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    const handleMapStyleChanges = () => {
      // ##  console.log("adding sources & layers");

      // add tile set source if it doesn't exist
      if (!map.current!.getSource("quarters")) {
        map.current!.addSource("quarters", {
          type: "vector",
          url: "mapbox://nidalghonaim.3taawnuq",
          promoteId: "lld",
        });
      }

      // add points tile set source if it doesn't exist
      if (!map.current!.getSource("quarters-points")) {
        map.current!.addSource("quarters-points", {
          type: "vector",
          url: "mapbox://nidalghonaim.dm6mkbn5",
          promoteId: "lld",
        });
      }

      // add locked style layer if it doesn't exist
      if (typeof map.current!.getLayer("quarters-owned") === "undefined") {
        map.current!.addLayer({
          id: "quarters-owned",
          minzoom: 10,
          type: "fill",
          source: "quarters",
          "source-layer": "QSECT-OPT-no-0-6wnl73",
          layout: {},
          paint: {
            "fill-color": "#67c5e4",
            "fill-opacity": 0.8,
          },
          filter: ["in", "lld", ...userLLDs],
        });
      }

      // add grid style layer if it doesn't exist
      if (typeof map.current!.getLayer("quarters-grid") === "undefined") {
        map.current!.addLayer({
          id: "quarters-grid",
          minzoom: 10,
          type: "line",
          source: "quarters",
          "source-layer": "QSECT-OPT-no-0-6wnl73",
          layout: {},
          paint: {
            "line-color": "#47afd1",
            "line-opacity": 0,
            "line-width": 1,
            "line-dasharray": [1, 1],
            "line-offset": 4,
          },
        });
      }

      // add hovered style layer if it doesn't exist
      if (typeof map.current!.getLayer("quarters-hovered") === "undefined") {
        map.current!.addLayer({
          id: "quarters-hovered",
          minzoom: 10,
          type: "fill",
          source: "quarters",
          "source-layer": "QSECT-OPT-no-0-6wnl73",
          layout: {},
          paint: {
            "fill-color": "#fafaa8",
            "fill-opacity": 0,
          },
        });
      }

      // add selected style layer if it doesn't exist
      if (typeof map.current!.getLayer("quarters-selected") === "undefined") {
        // ##  console.log(typeof map.current!.getLayer("quarters-selected"));

        // Add the image to the map style.
        if (!map.current!.hasImage("pattern-52345")) {
          const image = new Image(32, 32);
          image.src = "/patterns/pattern-10.svg";
          map.current!.addImage("pattern-52345", image);
        }
        map.current!.addLayer({
          id: "quarters-selected",
          minzoom: 10,
          type: "fill",
          source: "quarters",
          "source-layer": "QSECT-OPT-no-0-6wnl73",
          paint: {
            "fill-pattern": "pattern-52345",
            "fill-opacity": 0,
          },
          filter: ["in", "lld", ...userLLDs],
        });
      }

      // // add points guide layer if it doesn't exist
      // if (!map.current!.getLayer("quarters-points-guide")) {
      //   map.current!.addLayer({
      //     id: "quarters-points-guide",
      //     minzoom: 10,
      //     type: "circle",
      //     source: "quarters-points",
      //     "source-layer": "QUARTERS-LABELS-7j07kk",
      //     layout: {},
      //     paint: {
      //       // "fill-color": "red",
      //       // "icon-image": ""
      //     },
      //   });
      // }

      // add labels layer if it doesn't exist
      if (typeof map.current!.getLayer("quarters-labels") === "undefined") {
        map.current!.addLayer({
          id: "quarters-labels",
          minzoom: 10,
          type: "symbol",
          source: "quarters-points",
          "source-layer": "QUARTERS-LABELS-7j07kk",
          layout: {
            "text-field": ["get", "lld"],
            "text-variable-anchor": ["top"],
            // "text-radial-offset": 0.5,
            // "text-justify": "auto",
            "text-size": [
              "interpolate",
              // Set the exponential rate of change to 0.5
              ["linear"],
              ["zoom"],
              // When zoom is 15, buildings will be beige.
              12.5,
              12,
              // When zoom is 18 or higher, buildings will be yellow.
              20,
              32,
            ],
          },
          paint: {
            "text-opacity": [
              "interpolate",
              // Set the exponential rate of change to 0.5
              ["linear"],
              ["zoom"],
              // When zoom is 15, buildings will be beige.
              13,
              0,
              // When zoom is 18 or higher, buildings will be yellow.
              13.2,
              1,
            ],
            "text-color": "snow",
            "text-halo-width": 1,
            "text-halo-color": "#0b321f",
          },
        });
      }

      if (editModeEnabled) {
        // update layer paint properties
        map.current!.setPaintProperty("quarters-grid", "line-opacity", 1);
        map.current!.setPaintProperty("quarters-grid", "line-width", 2);
        map.current!.setPaintProperty("quarters-owned", "fill-opacity", 0);
        map.current!.setPaintProperty("quarters-selected", "fill-opacity", 1);
        map.current!.setPaintProperty("quarters-hovered", "fill-opacity", [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.5,
          0,
        ]);
        // update layer filters
        // map.current!.setFilter("quarters-selected", ["in", "lld", ...userLLDs]);
        // map.current!.setFilter("quarters-owned", ["in", "lld", ...userLLDs]);
      } else {
        // update layer paint properties
        map.current!.setPaintProperty("quarters-grid", "line-opacity", 0);
        map.current!.setPaintProperty("quarters-grid", "line-width", 1);
        map.current!.setPaintProperty("quarters-owned", "fill-opacity", 0.8);
        map.current!.setPaintProperty("quarters-selected", "fill-opacity", 0);
        map.current!.setPaintProperty("quarters-hovered", "fill-opacity", 0);
        // update layer filters
        // map.current!.setFilter("quarters-selected", ["in", "lld", ...userLLDs]);
        // map.current!.setFilter("quarters-owned", ["in", "lld", ...userLLDs]);
      }

      map.current!.setFilter("quarters-selected", ["in", "lld", ...userLLDs]);
      map.current!.setFilter("quarters-owned", ["in", "lld", ...userLLDs]);
    };

    map.current.on("styledata", handleMapStyleChanges);

    return () => {
      map.current!.off("styledata", handleMapStyleChanges);
    };
  }, [editModeEnabled, userLLDs]);

  // handle land hovering
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    const handleQuarterHover = (
      event: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData
    ) => {
      if (!editModeEnabled) return; // check if edit mode is enabled
      if (event.features!.length > 0) {
        if (hoveredQuarterId.current !== null) {
          map.current!.setFeatureState(
            {
              source: "quarters",
              sourceLayer: "QSECT-OPT-no-0-6wnl73",
              id: hoveredQuarterId.current,
            },
            { hover: false }
          );
        }
        hoveredQuarterId.current = event.features![0].id;
        map.current!.setFeatureState(
          {
            source: "quarters",
            sourceLayer: "QSECT-OPT-no-0-6wnl73",
            id: hoveredQuarterId.current,
          },
          { hover: true }
        );
      }
    };

    map.current!.on("mousemove", "quarters-hovered", handleQuarterHover);

    return () => {
      map.current!.off("mousemove", "quarters-hovered", handleQuarterHover);
    };
  }, [editModeEnabled]);

  // handle land selection
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    const handleQuarterClick = (
      event: mapboxgl.MapMouseEvent & mapboxgl.EventData
    ) => {
      if (!editModeEnabled) return; // check if edit mode is enabled
      // Set the click point
      const point = [event.point.x, event.point.y] as PointLike;
      // Find features intersecting the click point
      const selectedFeatures = map.current!.queryRenderedFeatures(point, {
        layers: ["quarters-hovered"],
      });
      // if there are features intersecting the click point
      if (selectedFeatures.length && selectedFeatures[0].properties) {
        // get the LLD of the feature intersected from the feature's properties
        let selectedQuarter = selectedFeatures[0].properties!.lld;
        // if the selected LLD is already in the user's LLDs
        if (userLLDs.includes(selectedQuarter)) {
          // delete it
          handleDeleteLandQuarter(selectedQuarter);
        } else {
          // otherwise, add it to the user's LLDs
          handleAddLandQuarter(selectedQuarter);
        }
      }
    };

    map.current!.on("click", handleQuarterClick);

    return () => {
      map.current!.off("click", handleQuarterClick);
    };
  }, [
    editModeEnabled,
    userLLDs,
    handleAddLandQuarter,
    handleDeleteLandQuarter,
  ]);

  return (
    <div className="land-map">
      <div ref={mapContainer} className="map-container" />
      <div className="land-map__details">
        <p>quarters: {userLLDs.length}</p>
        <p>lng: {lng.toFixed(4)}</p>
        <p>lat: {lat.toFixed(4)}</p>
        <p>zoom: {zoom.toFixed(1)}</p>
      </div>
      <img
        src="/patterns/pattern-10.svg"
        alt="pattern"
        style={{ display: "none" }}
      />
      {loading && (
        <div className="land-map__loading-overlay">
          <IconTractorLoading />
        </div>
      )}
    </div>
  );
};

export default LandMap;
