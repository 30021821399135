import { AnyObject } from "@catalyst-tech/catalyst-form/dist/types";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserModel } from "../../../types";
import IconLocation from "../../ui/icons/IconLocation";
import { UserContext } from "../user/providers/UserProvider";

type ProfileViewProps = {
  form?: AnyObject;
};

const ProfileView = ({ form }: ProfileViewProps) => {
  const user = useContext(UserContext) as UserModel;
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [farmName, setFarmName] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [bio, setBio] = useState("");
  const [website, setWebsite] = useState("");

  useEffect(() => {
    if (!form && user) {
      setGivenName(user.party.given_name);
      setFamilyName(user.party.family_name);
      setFarmName(user.farm_name);
      setProvince(user.province);
      setCity(user.city);
      setProfilePicture(user.pic as string);
      setBio(user.bio || "");
      setWebsite(user.website);
    }
    if (form) {
      if (form && "given_name" in form) setGivenName(form.given_name.value);
      if (form && "family_name" in form) setFamilyName(form.family_name.value);
      if (form && "farm_name" in form) setFarmName(form.farm_name.value);
      if (form && "province" in form) setProvince(form.province.value);
      if (form && "city" in form) setCity(form.city.value);
      if (form?.pic?.value)
        setProfilePicture(URL.createObjectURL(form.pic.value));
      if (form && "bio" in form) setBio(form.bio.value);
      if (form && "website" in form) setWebsite(form.website.value);
    }
  }, [form, user]);

  return (
    <div className="profile-view">
      <img
        className="profile-view__image"
        src={profilePicture || (user.pic as string) || "/images/no-avatar.png"}
        alt=""
      />
      <div className="profile-view__details">
        <div className="profile-view__group">
          <p className="profile-view__name">
            {givenName} {familyName}
          </p>
          <div className="profile-view__location">
            <IconLocation />
            <p>
              {city && city + ","} {province && province + ","} canada
            </p>
          </div>
        </div>
        {farmName && (
          <div className="profile-view__group">
            <p className="profile-view__title">farm name</p>
            <p className="profile-view__value">{farmName}</p>
          </div>
        )}
        {bio && (
          <div className="profile-view__group">
            <p className="profile-view__title">bio</p>
            <p className="profile-view__value">{bio}</p>
          </div>
        )}
        {website && (
          <div className="profile-view__group">
            <p className="profile-view__title">website</p>
            <a href={website} target="_blank" rel="noreferrer">
              {website
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "")}
            </a>
          </div>
        )}
        {!form && (
          <Link to="/profile/edit" className="toRight">
            Edit Profile
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProfileView;
