import useSWR from "swr";
import { fetcher } from "../helpers";
import { AnyObject } from "../types";

export const useIndex = (
  token: string,
  endpoint: string,
  query?: AnyObject,
  listKey?: string,
  version: string = "v1"
  // currentPage: number,
  // itemsPerPage: number
) => {
  // const { data, error } = useSWR(
  //   [
  //     `${endpoint}?page=${currentPage}&items_per_page=${itemsPerPage}`,
  //     msalInstance,
  //   ],
  //   fetcher
  // );
  let queryStrings: string[] = [];
  if (query) {
    Object.entries(query).map(([key, value]) =>
      queryStrings.push(key + "=" + value)
    );
  }

  if (queryStrings.length !== 0) endpoint += "?" + queryStrings.join("&");

  const { data, error } = useSWR(
    token ? [`${endpoint}`, token, version] : null,
    fetcher
  );

  let key = "items";
  if (listKey) key = listKey;

  return {
    items: data ? (data[key] as AnyObject[]) : [],
    // itemCount: data ? (data.item_count as number) : 0,
    // pageCount: data ? (data.page_count as number) : 0,
    isLoading: !error && !data,
    isError: error,
  };
};
