import Widget from "../layout/Widget";
import CreditHoldings from "../features/carbon-credit/CreditHoldings";
import EnrollmentsWidget from "../features/enrollment/EnrollmentsWidget";
import LandEditor from "../features/land/LandEditor";
import ListingList from "../features/listing/ListingList";
// import CreditsPortfolio from "../features/carbon-credit/CreditsPortfolio";

const DashboardPage = () => {
  return (
    <div className="widget-grid ">
      <div className="widget-grid__main">
        <EnrollmentsWidget />
        <Widget title={"my lands"}>
          <LandEditor />
        </Widget>
      </div>
      <div className="widget-grid__aside">
        <Widget title="my carbon credits">
          <CreditHoldings />
        </Widget>
        <Widget title="listing history">
          <ListingList />
        </Widget>
        <Widget title="portfolio">
          {/* <CreditsPortfolio /> */}
        </Widget>
      </div>
    </div>
  );
};

export default DashboardPage;
