import PostEnrollmentDetails from "../features/enrollment/no-till/steps/PostEnrollmentDetails";
import Widget from "../layout/Widget";

const TestEnrollmentPage = () => {
  return (
    <div>
      <Widget title="something">
        <PostEnrollmentDetails />
      </Widget>
    </div>
  );
};

export default TestEnrollmentPage;
