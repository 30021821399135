import LandEditor from "../features/land/LandEditor";
import Widget from "../layout/Widget";

const MyFarmPage = () => {
  return (
    <Widget title={"my lands"}>
      <LandEditor />
    </Widget>
  );
};

export default MyFarmPage;
