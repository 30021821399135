import { nanoid } from "nanoid";
import { LandModel, LandSectionModel } from "../../../types";
import LandSectionDisplay from "../land-section/LandSectionDisplay";
import LandBox from "./LandBox";

type LandSelectProps = {
  sections: LandSectionModel[];
  selectedLands: LandModel[];
  onChange: (land: LandModel, action: "add" | "remove") => void;
};

const LandSelect = ({ selectedLands, sections, onChange }: LandSelectProps) => {
  return (
    <div className="land-select">
      {sections.map((section) => (
        <div className="land-select__section" key={nanoid()}>
          <LandSectionDisplay section={section} />
          <div className="land-select__section-lands">
            {section.lands.map((land) => (
              <LandBox
                selectedLands={selectedLands}
                key={land.land_id}
                land={land}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LandSelect;
