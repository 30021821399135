import { useContext } from "react";
import { AnyObject, UserModel } from "../../../types";
import ResourceList from "../../ResourceList";
import { UserContext } from "../user/providers/UserProvider";

type BusinessListProps = {
  onSuccess?: (items: AnyObject[]) => void;
};

const BusinessList = ({ onSuccess }: BusinessListProps) => {
  const user = useContext(UserContext) as UserModel;

  return (
    <ResourceList
      resourceName="business"
      endpoint={`customer/${user.customer_id}/business`}
      sortKey="business_id"
      onSuccess={onSuccess}
    />
  );
};

export default BusinessList;
