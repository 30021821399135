import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { LandModel } from "../../../../types";

export const useEnrollmentLands = (token: string, enrollmentId?: string) => {
  const { data, error } = useSWR(
    token ? [`dossier/${enrollmentId}/land`, token, "v2"] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    lands: data ? (data["items"] as LandModel[]) : [],
    isLoading: !error && !data,
    isError: error,
  };
};
