import { nanoid } from "nanoid";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatMoney,
  getCurrentYear,
  RECOMMENDED_SELL_PRICE_PREMIUM,
  RECOMMENDED_SELL_PRICE_STANDARD,
} from "../../../../../helpers";
import { useAccessToken } from "../../../../../hooks/useAccessToken";
import { AnyObject, EnrollmentModel } from "../../../../../types";
import Button from "../../../../ui/Button";
import ErrorMessage from "../../../../ui/ErrorMessage";
import Loading from "../../../../ui/Loading";
import OrderedList from "../../../../ui/OrderedList";
import PopupTip from "../../../../ui/PopupTip";
import { useEnrollment } from "../../hooks/useEnrollment";

const PostEnrollmentDetails = () => {
  const { enrollmentId } = useParams();
  const { token } = useAccessToken();
  const { enrollment, isLoading, isError } = useEnrollment(token, enrollmentId);
  const navigate = useNavigate();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting enrollment data" />;

  const getStandardValue = (enrollment: EnrollmentModel) => {
    let credits = enrollment.credit_schedule.reduce((previous, current) => {
      if (
        current.credit_type === "avoidance" &&
        current.vintage <= getCurrentYear()
      ) {
        return previous + current.credits;
      }
      return previous;
    }, 0);
    return credits;
  };

  const getPremiumValue = (enrollment: EnrollmentModel) => {
    let credits = enrollment.credit_schedule.reduce((previous, current) => {
      if (
        current.credit_type === "removal" &&
        current.vintage <= getCurrentYear()
      ) {
        return previous + current.credits;
      }
      return previous;
    }, 0);
    return credits;
  };

  const getTotalCredits = (enrollment: EnrollmentModel) => {
    return parseFloat(
      (getStandardValue(enrollment) + getPremiumValue(enrollment)).toFixed(1)
    );
  };

  const getTotalValue = (enrollment: EnrollmentModel) => {
    let standardValue =
      getStandardValue(enrollment) * RECOMMENDED_SELL_PRICE_STANDARD;
    let premiumValue =
      getPremiumValue(enrollment) * RECOMMENDED_SELL_PRICE_PREMIUM;
    return standardValue + premiumValue;
  };

  const getUpfrontSchedule = (enrollment: EnrollmentModel) => {
    let standard = enrollment.credit_schedule.filter(
      (schedule) =>
        schedule.credit_type === "avoidance" &&
        schedule.vintage <= getCurrentYear()
    );
    let standardTotal = standard.reduce((previous: AnyObject, current) => {
      let previousCredits = previous.credits || 0;
      return {
        vintage: current.vintage,
        type: current.credit_type,
        credits: previousCredits + current.credits,
      };
    }, {});
    let premium = enrollment.credit_schedule.filter(
      (schedule) =>
        schedule.credit_type === "removal" &&
        schedule.vintage <= getCurrentYear()
    );
    let premiumTotal = premium.reduce((previous: AnyObject, current) => {
      let previousCredits = previous.credits || 0;
      return {
        vintage: current.vintage,
        type: current.credit_type,
        credits: previousCredits + current.credits,
      };
    }, {});

    return [standardTotal, premiumTotal];
  };

  return (
    <>
      <div className="temp-congrats">
        <img
          src="/images/happy-farmer.jpg"
          alt="happy farmer"
          className="temp-congrats__image"
        />
        <div className="temp-congrats__right">
          <h1>Congratulations!</h1>
          <div>
            <p>
              You have enrolled your lands into our{" "}
              <span className="bold color-brand-500">No-Till</span> carbon
              credits program.
            </p>
            <p>
              Your enrollment generated a total <b>upfront</b> amount of{" "}
              <span className="color-brand-500 bold font-size-20">
                {getTotalCredits(enrollment).toFixed(1)}
              </span>{" "}
              carbon credits with an estimated value of{" "}
              <span className="color-brand-500 bold font-size-20">
                {formatMoney(getTotalValue(enrollment))}
              </span>
            </p>
          </div>
          <table className="table">
            <thead className="table__head">
              <tr className="table__row">
                <th className="table__cell">Credit Origin Year</th>
                <th className="table__cell">Credit Type</th>
                <th className="table__cell">Credit Amount</th>
                <th className="table__cell">
                  Estimated Credit Value{" "}
                  <PopupTip content={"based on our recommended sell prices."} />
                </th>
              </tr>
            </thead>
            <tbody className="table__body">
              {getUpfrontSchedule(enrollment).map((schedule) => (
                <tr className="table__row" key={nanoid()}>
                  <td className="table__cell">{schedule.vintage}</td>
                  <td className="table__cell">
                    <p>
                      {schedule.type === "avoidance" ? "standard" : "premium"}
                    </p>
                  </td>
                  <td className="table__cell">{schedule.credits.toFixed(1)}</td>
                  <td className="table__cell">
                    {schedule.type === "avoidance"
                      ? formatMoney(
                          schedule.credits * RECOMMENDED_SELL_PRICE_STANDARD
                        )
                      : formatMoney(
                          schedule.credits * RECOMMENDED_SELL_PRICE_PREMIUM
                        )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>What happens next?</h3>
          <OrderedList
            items={[
              <>we review your enrollment details.</>,
              <>
                If your enrollment is verified, your credits become available to
                you to list for sale.
              </>,
              <>
                You list your credits for sale in your{" "}
                <Button onClick={() => navigate("/carbon-credits")} inline>
                  carbon credits page
                </Button>
              </>,
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default PostEnrollmentDetails;
