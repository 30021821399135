import { ReactNode } from "react";
import { conditionalClassNames } from "../../helpers";

type ToggleProps = {
  state: boolean;
  trueStateText: string;
  trueStateIcon: ReactNode;
  falseStateText: string;
  falseStateIcon: ReactNode;
  onToggle: () => void;
};

const Toggle = ({
  state,
  trueStateText,
  trueStateIcon,
  falseStateText,
  falseStateIcon,
  onToggle,
}: ToggleProps) => {
  return (
    <div className="toggle">
      <button
        className={conditionalClassNames({
          toggle__button: true,
          "toggle__button--active": state === false,
        })}
        onClick={onToggle}
      >
        {falseStateIcon} {falseStateText}
      </button>
      <button
        className={conditionalClassNames({
          toggle__button: true,
          "toggle__button--active": state === true,
        })}
        onClick={onToggle}
      >
        {trueStateIcon} {trueStateText}
      </button>
    </div>
  );
};

export default Toggle;
