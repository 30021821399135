const IconTractorLoading = () => {
  return (
    <svg
      width={273}
      height={209}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tractor"
    >
      <path
        className="tractor__body"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.393 98.073c-20.254-16.268-49.487-13.515-66.786 5.398-1.173 1.285-3.183 1.44-4.459.256l-3.195-2.964c-1.203-1.117-1.289-3-.133-4.166a64.125 64.125 0 0127.973-16.573c1.223-.35 2.129-1.398 2.246-2.664l3.695-39.495a2.695 2.695 0 00-2.683-2.946h-.09a2.46 2.46 0 01-1.656-.64c-1.674-1.522-.577-4.307 1.685-4.279l71.16.89a3.003 3.003 0 012.887 2.33l9.938 43.37a2.998 2.998 0 002.923 2.33h4.112c.55 0 1-.448 1-1v-10c0-.553-.45-1-1-1h-.5a1 1 0 01-1-1v-10a.998.998 0 011-1h.5c.55 0 1-.448 1-1v-5a3 3 0 013-3h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-.5a1 1 0 00-1 1v4a1 1 0 001 1h.5c.551 0 1 .447 1 1v10a1 1 0 01-1 1h-.5a1 1 0 00-1 1v10.108c0 .265.103.508.275.688a.991.991 0 00.609.305l58.215 6.68a3 3 0 012.504 2.033l6.895 20.679c.799 2.402-1.602 4.771-4.094 4.324-19.824-3.556-44.492 9.501-43.121 42.902.072 1.76-1.303 3.277-3.065 3.277h-50.751a2.998 2.998 0 01-2.752-1.808l-1.118-2.582a3.071 3.071 0 01-.07-2.247c6.563-19.004.932-40.893-15.644-54.206zm31.449 20.837c-3.512-9.171-9.176-17.675-16.957-24.715a64.515 64.515 0 00-15.971-10.626l-1.689-3.55a7 7 0 00-5.686-3.961l-6.398-.581a1.002 1.002 0 01-.848-.653L81.25 55.575a1 1 0 00-.94-.656h-2.576a1 1 0 00-.98 1.2l4.408 21.64a64.878 64.878 0 00-13.67.275c-1.994.252-3.773-1.381-3.566-3.38l3.8-36.525a3 3 0 013.006-2.69l60.381.463a2.998 2.998 0 012.908 2.356l8.215 37.422a2.32 2.32 0 01-3.867 2.175l-7.242-6.911a3 3 0 00-4.192.048l-4.677 4.676-5.01-5.01a1 1 0 01.031-1.445l4.461-4.088a.991.991 0 00.25-1.114.99.99 0 00-.219-.33l-.566-.567a1 1 0 00-1.402-.013l-12.561 12.111a1 1 0 00-.012 1.427l.573.573a1 1 0 001.414 0l4.586-4.586a1 1 0 011.414 0l5.041 5.042-1.207 1.207a3 3 0 00-.076 4.163l29.732 32.019c.514.555.801 1.284.801 2.041v.822c0 1.656-1.344 3-3 3h-7.797c-1.279 0-2.412-.814-2.869-2.01z"
        fill="black"
      />
      <path
        className="tractor__back-wheel"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.51 92.919a2 2 0 00-2 2c-1.229.242-2.44.531-3.63.873l-.454.135-.578.177-.282.091c-.238.078-.474.157-.709.239a2 2 0 00-2.732-.732l-10.393 6a2 2 0 00-.73 2.732l.025.045a41.809 41.809 0 00-3.337 3.25 43.03 43.03 0 00-.78.869l-.047-.027a2.001 2.001 0 00-2.732.733l-6 10.391a2.002 2.002 0 00.732 2.733l.014.008a41.168 41.168 0 00-1.352 4.793l-.175.85c-1.104 0-2 .896-2 2v12c0 .392.113.757.308 1.066.34.541.934.906 1.614.932a42.142 42.142 0 001.494 5.715 1.99 1.99 0 00-.826 1.127 1.989 1.989 0 00.19 1.544l6 10.392a2.002 2.002 0 002.54.832 42.465 42.465 0 004.219 4.266 1.998 1.998 0 00.845 2.503l10.393 6a2 2 0 002.512-.422 41.765 41.765 0 005.914 1.62 2 2 0 001.957 1.585h12a1.994 1.994 0 001.94-1.509 41.891 41.891 0 006.034-1.586c.623.622 1.61.775 2.409.313l10.392-6a2.003 2.003 0 00.938-2.232 42.409 42.409 0 004.431-4.432 2.002 2.002 0 002.233-.938l6-10.391c.201-.347.285-.729.265-1.101l-.006-.086a1.903 1.903 0 00-.068-.366 1.826 1.826 0 00-.217-.491 1.642 1.642 0 00-.287-.366c.412-1.193.77-2.408 1.074-3.647.116-.467.223-.937.323-1.41l.109-.542.084-.437c.061-.016.123-.035.184-.056l.091-.036a2.001 2.001 0 001.231-1.847v-12a2 2 0 00-1.584-1.957 41.944 41.944 0 00-1.619-5.917c.334-.289.553-.673.642-1.087l.016-.083a1.984 1.984 0 00-.238-1.34l-6-10.391a2 2 0 00-2.492-.851 42.559 42.559 0 00-4.278-4.215 1.975 1.975 0 00.037-1.52 2.001 2.001 0 00-.867-1.017l-10.393-6a1.993 1.993 0 00-1.343-.238 1.993 1.993 0 00-1.328.872 41.885 41.885 0 00-5.715-1.493 2 2 0 00-1.998-1.923h-12zm6.5 73c16.293 0 29.5-13.208 29.5-29.5s-13.207-29.5-29.5-29.5-29.5 13.208-29.5 29.5 13.207 29.5 29.5 29.5z"
        fill="black"
      />
      <path
        className="tractor__back-wheel"
        d="M85.51 136.419c0 6.351-5.149 11.5-11.5 11.5-6.352 0-11.5-5.149-11.5-11.5s5.149-11.5 11.5-11.5 11.5 5.149 11.5 11.5zM78.51 115.919a5 5 0 11-10 0 5 5 0 0110 0zM79.51 156.919a5 5 0 11-10 0 5 5 0 0110 0zM62.696 118.741a5 5 0 11-7.07 7.071 5 5 0 017.07-7.071zM92.394 147.026a5 5 0 11-7.071 7.071 5 5 0 017.07-7.071zM91.688 125.106a5.001 5.001 0 01-7.071-7.071 5 5 0 117.07 7.071zM63.403 154.803a5 5 0 11-7.071-7.071 5 5 0 017.07 7.071zM99.51 135.919a5 5 0 11-10 0 5 5 0 0110 0zM58.51 135.919a5 5 0 11-10 0 5 5 0 0110 0z"
        fill="black"
      />
      <path
        className="tractor__back-wheel"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.51 92.919a2 2 0 00-2 1.996 41.844 41.844 0 00-5.647 1.53l-.006-.011a2 2 0 00-2.732-.732l-10.393 6a2 2 0 00-.731 2.732l.026.045a42.283 42.283 0 00-4.118 4.119l-.046-.027a2.001 2.001 0 00-2.732.733l-6 10.391a2.001 2.001 0 00.732 2.733l.013.007a41.882 41.882 0 00-1.529 5.644 2 2 0 00-1.997 2v12a2 2 0 001.92 1.999 41.87 41.87 0 001.493 5.715 2 2 0 00-.632 2.67l6 10.392a2 2 0 002.541.83 42.446 42.446 0 004.216 4.27 2 2 0 00.846 2.501l10.393 6a2 2 0 002.512-.422 41.735 41.735 0 005.913 1.616c.19.907.994 1.589 1.958 1.589h12c.935 0 1.72-.642 1.94-1.509a41.813 41.813 0 006.029-1.59 2 2 0 002.414.317l10.392-6a2.002 2.002 0 00.938-2.23 42.368 42.368 0 004.434-4.433 2.001 2.001 0 002.23-.939l6-10.391a2.003 2.003 0 00-.316-2.413 41.853 41.853 0 001.593-6.033 2.001 2.001 0 001.506-1.939v-12c0-.962-.68-1.766-1.586-1.957a41.804 41.804 0 00-1.617-5.917 2.001 2.001 0 00.42-2.51l-6-10.391a2.002 2.002 0 00-2.498-.849 42.413 42.413 0 00-4.272-4.217 2 2 0 00-.83-2.537l-10.393-6a2 2 0 00-2.668.629 41.862 41.862 0 00-5.718-1.493 2 2 0 00-1.998-1.918h-12zm6.5 73c16.293 0 29.5-13.208 29.5-29.5s-13.207-29.5-29.5-29.5-29.5 13.208-29.5 29.5 13.207 29.5 29.5 29.5z"
        fill="black"
      />
      <path
        className="tractor__back-wheel"
        d="M85.51 136.419c0 6.351-5.149 11.5-11.5 11.5-6.352 0-11.5-5.149-11.5-11.5s5.149-11.5 11.5-11.5 11.5 5.149 11.5 11.5zM78.51 115.919a5 5 0 11-10 0 5 5 0 0110 0zM79.51 156.919a5 5 0 11-10 0 5 5 0 0110 0zM62.696 118.741a5 5 0 11-7.07 7.071 5 5 0 017.07-7.071zM92.394 147.026a5 5 0 11-7.071 7.071 5 5 0 017.07-7.071zM91.688 125.106a5.001 5.001 0 01-7.071-7.071 5 5 0 117.07 7.071zM63.403 154.803a5 5 0 11-7.071-7.071 5 5 0 017.07 7.071zM99.51 135.919a5 5 0 11-10 0 5 5 0 0110 0zM58.51 135.919a5 5 0 11-10 0 5 5 0 0110 0z"
        fill="black"
      />
      <path
        className="tractor__front-wheel"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.77 117.919c-.781 0-1.414.633-1.414 1.413-.868.171-1.723.376-2.564.617l-.322.095-.408.126-.199.064c-.168.055-.335.111-.501.169a1.413 1.413 0 00-1.931-.517l-7.344 4.24a1.412 1.412 0 00-.516 1.93l.018.032a29.664 29.664 0 00-2.91 2.911l-.033-.019a1.413 1.413 0 00-1.931.518l-4.24 7.343a1.414 1.414 0 00.518 1.931l.009.005a29.501 29.501 0 00-.955 3.388l-.124.601c-.78 0-1.413.632-1.413 1.413v8.48a1.412 1.412 0 001.358 1.412 29.113 29.113 0 001.056 4.038 1.41 1.41 0 00-.584.797c-.101.354-.064.747.135 1.091l4.24 7.344a1.415 1.415 0 001.794.587 29.992 29.992 0 002.982 3.015 1.412 1.412 0 00.597 1.769l7.345 4.24c.597.345 1.34.204 1.774-.298 1.352.478 2.747.864 4.18 1.145.135.64.702 1.12 1.383 1.12h8.48a1.41 1.41 0 001.37-1.066 29.593 29.593 0 004.265-1.122c.44.44 1.138.548 1.702.222l7.344-4.24c.563-.325.817-.978.663-1.578a29.847 29.847 0 003.131-3.131 1.416 1.416 0 001.578-.663l4.24-7.343c.142-.245.201-.515.188-.778l-.004-.061a1.426 1.426 0 00-.405-.865 29.25 29.25 0 00.987-3.573l.077-.383.06-.309c.042-.011.087-.024.129-.04l.065-.025c.511-.213.87-.717.87-1.305v-8.48c0-.68-.479-1.248-1.12-1.383a29.496 29.496 0 00-1.144-4.181c.236-.205.391-.476.454-.769l.011-.058a1.399 1.399 0 00-.168-.947l-4.24-7.343a1.414 1.414 0 00-1.761-.602 30.033 30.033 0 00-3.023-2.978 1.399 1.399 0 00.026-1.075 1.413 1.413 0 00-.612-.718l-7.345-4.24a1.405 1.405 0 00-.949-.168 1.407 1.407 0 00-.939.616 29.657 29.657 0 00-4.038-1.055 1.413 1.413 0 00-1.412-1.359h-8.48zm4.593 51.587c11.514 0 20.847-9.334 20.847-20.847 0-11.513-9.333-20.847-20.847-20.847-11.514 0-20.847 9.334-20.847 20.847 0 11.513 9.333 20.847 20.847 20.847z"
        fill="black"
      />
      <path
        className="tractor__front-wheel"
        d="M222.49 148.659a8.128 8.128 0 11-16.255-.001 8.128 8.128 0 0116.255.001zM217.543 134.172a3.533 3.533 0 11-7.067.001 3.533 3.533 0 017.067-.001zM218.25 163.146a3.533 3.533 0 11-7.067-.001 3.533 3.533 0 017.067.001zM206.368 136.167a3.532 3.532 0 11-4.997 4.996 3.534 3.534 0 014.997-4.996zM227.354 156.155a3.533 3.533 0 11-4.996 4.997 3.533 3.533 0 014.996-4.997zM226.856 140.664a3.533 3.533 0 11-4.997-4.996 3.533 3.533 0 014.997 4.996zM206.867 161.65a3.532 3.532 0 11-4.996-4.996 3.532 3.532 0 114.996 4.996zM232.383 148.306a3.533 3.533 0 11-7.067 0 3.533 3.533 0 017.067 0zM203.41 148.306a3.533 3.533 0 11-7.067-.001 3.533 3.533 0 017.067.001z"
        fill="black"
      />
      <path
        className="tractor__front-wheel"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.77 117.919c-.78 0-1.412.631-1.414 1.41a29.536 29.536 0 00-3.99 1.081l-.004-.007a1.413 1.413 0 00-1.931-.517l-7.344 4.24a1.413 1.413 0 00-.517 1.93l.019.032a29.958 29.958 0 00-2.911 2.911l-.032-.019a1.413 1.413 0 00-1.931.518l-4.24 7.343a1.414 1.414 0 00.518 1.931l.009.005a29.67 29.67 0 00-1.081 3.989c-.779 0-1.411.633-1.411 1.413v8.48c0 .762.603 1.383 1.357 1.412a29.71 29.71 0 001.055 4.039 1.414 1.414 0 00-.447 1.887l4.24 7.344a1.413 1.413 0 001.796.586 29.992 29.992 0 002.979 3.018 1.414 1.414 0 00.598 1.767l7.345 4.24c.597.345 1.34.205 1.774-.298 1.351.478 2.747.861 4.179 1.142a1.415 1.415 0 001.384 1.123h8.48c.66 0 1.215-.454 1.37-1.066a29.512 29.512 0 004.261-1.124c.442.441 1.14.551 1.706.224l7.344-4.24c.562-.325.817-.977.663-1.576a29.894 29.894 0 003.133-3.133 1.415 1.415 0 001.576-.663l4.24-7.343a1.414 1.414 0 00-.223-1.705 29.721 29.721 0 001.126-4.264 1.415 1.415 0 001.064-1.37v-8.48c0-.68-.481-1.248-1.121-1.383a29.493 29.493 0 00-1.143-4.181 1.414 1.414 0 00.297-1.774l-4.24-7.343a1.414 1.414 0 00-1.765-.6 29.907 29.907 0 00-3.019-2.98 1.413 1.413 0 00-.586-1.793l-7.345-4.24a1.414 1.414 0 00-1.886.444 29.685 29.685 0 00-4.04-1.055 1.414 1.414 0 00-1.412-1.355h-8.48zm4.593 51.587c11.514 0 20.847-9.334 20.847-20.847 0-11.513-9.333-20.847-20.847-20.847-11.514 0-20.847 9.334-20.847 20.847 0 11.513 9.333 20.847 20.847 20.847z"
        fill="black"
      />
      <path
        className="tractor__front-wheel"
        d="M222.49 148.659a8.128 8.128 0 11-16.255-.001 8.128 8.128 0 0116.255.001zM217.543 134.172a3.533 3.533 0 11-7.067.001 3.533 3.533 0 017.067-.001zM218.25 163.146a3.533 3.533 0 11-7.067-.001 3.533 3.533 0 017.067.001zM206.368 136.167a3.532 3.532 0 11-4.997 4.996 3.534 3.534 0 014.997-4.996zM227.354 156.155a3.533 3.533 0 11-4.996 4.997 3.533 3.533 0 014.996-4.997zM226.856 140.664a3.533 3.533 0 11-4.997-4.996 3.533 3.533 0 014.997 4.996zM206.867 161.65a3.532 3.532 0 11-4.996-4.996 3.532 3.532 0 114.996 4.996zM232.383 148.306a3.533 3.533 0 11-7.067 0 3.533 3.533 0 017.067 0zM203.41 148.306a3.533 3.533 0 11-7.067-.001 3.533 3.533 0 017.067.001z"
        fill="black"
      />
    </svg>
  );
};

export default IconTractorLoading;
