import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { LandModel, UserModel } from "../../../../types";

export const useUserLands = (token: string, user: UserModel) => {
  const { data, error, mutate, isValidating } = useSWR(
    token
      ? [`customer/{${user.customer_id}}/land?page_size=1000`, token]
      : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }
  
  return {
    lands: data && "items" in data ? (data.items as LandModel[]) : [],
    mutateLands: mutate,
    isLoading: !error && !data,
    isValidating: isValidating,
    isError: error,
  };
};
