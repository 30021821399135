import { createContext, ReactNode } from "react";
import { useAccessToken } from "../../../../hooks/useAccessToken";
import { useUser } from "../../../../hooks/useUser";
import { UserModel } from "../../../../types";
import Loading from "../../../ui/Loading";
import FailedGettingUser from "../../../ui/FailedGettingUser";
import { useAuth0 } from "@auth0/auth0-react";

export const UserContext = createContext<UserModel | null>(null);

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const { isAuthenticated } = useAuth0();
  const { token } = useAccessToken();
  const { user, isLoading, isError } = useUser(token);

  if (!isAuthenticated) return <>{children}</>;

  if (isLoading) return <Loading fullscreen />;
  if (isError) return <FailedGettingUser />;

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserProvider;
