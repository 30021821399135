import { QuickForm } from "@catalyst-tech/catalyst-form";
import { BusinessModel, FormSubmitHandler } from "../../../types";
import { validate } from "../../../validators/validate";
import Button from "../../ui/Button";

type BusinessFormProps = {
  business?: Partial<BusinessModel>;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const BusinessForm = ({
  business,
  onSubmit,
  serverErrorMessage,
}: BusinessFormProps) => {
  return (
    <QuickForm
      fields={[
        {
          label: "business name",
          id: "business_name",
          type: "text",
          initial: business?.business_name || "",
          validators: [validate.required],
        },
        {
          label: "business number",
          id: "business_number",
          type: "text",
          initial: business?.business_number || "",
          validators: [validate.required, validate.businessNumber],
          instructions: <p className="subtitle">example: 123456789RT1234</p>,
        },
      ]}
      onSubmit={onSubmit}
      serverError={serverErrorMessage}
    >
      {(submit) => <Button onClick={submit}>add business</Button>}
    </QuickForm>
  );
};

export default BusinessForm;
