import { LngLatLike } from "mapbox-gl";
import { useCallback, useContext, useState } from "react";
import {
  FaEye,
  FaInfoCircle,
  FaPen,
  FaFilter,
  FaSatelliteDish,
  FaRoad,
} from "react-icons/fa";
import { MdFullscreen } from "react-icons/md";
import { compareTimeStamp, yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { UserModel } from "../../../types";
import Button from "../../ui/Button";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import Toggle from "../../ui/Toggle";
import { UserContext } from "../user/providers/UserProvider";
import { useUserLands } from "./hooks/useUserLands";
import LandItem from "./LandItem";
import LandMap from "./LandMap";
import LandNew from "./LandNew";

var stringSimilarity = require("string-similarity");

const LandEditor = () => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { lands, isLoading, isError, mutateLands } = useUserLands(token, user);
  const [center, setCenter] = useState<LngLatLike | null>(null);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const toggleEditMode = () => setEditModeEnabled((current) => !current);
  const [ownedLandsSearchTerm, setOwnedLandsSearchTerm] = useState("");
  const [mapStyle, setMapStyle] = useState<"satellite" | "streets">(
    "satellite"
  );
  const toggleMapStyle = () =>
    setMapStyle((current) =>
      current === "satellite" ? "streets" : "satellite"
    );

  const handleLandLocate = useCallback((center: LngLatLike) => {
    let newCenter = center as [number, number];
    // moving the center to the left to compensate for the editor ui
    setCenter([newCenter[0] + 0.004, newCenter[1]]);
  }, []);

  const handleLandCreated = (response: any) => {
    let newCenter = response.lands[0].center as [number, number];
    setCenter([newCenter[1] + 0.004, newCenter[0]]);
    mutateLands();
  };

  const handleLandDelete = useCallback(
    (landId: string) => {
      const onSuccess = () => {
        typeof mutateLands === "function" &&
          mutateLands({
            items: lands.filter((land) => land.land_id !== landId),
          });
      };
      const onFailure = () => {};
      yoAPI(
        `customer/${user.customer_id}/land/${landId}`,
        {},
        token,
        onSuccess,
        onFailure,
        "DELETE"
      );
    },
    [lands, mutateLands, token, user.customer_id]
  );

  const fullscreen = () => {
    let mapEditor = document.getElementsByClassName("land-editor")[0];
    if (!document.fullscreenElement) {
      mapEditor.requestFullscreen();
      mapEditor.classList.add("fullscreen");
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      mapEditor.classList.remove("fullscreen");
    }
  };

  const handleOwnedLandsSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOwnedLandsSearchTerm(event.target.value);
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting user lands" />;

  return (
    <div className="land-editor">
      <LandMap
        lands={lands}
        center={center}
        editModeEnabled={editModeEnabled}
        mapStyle={mapStyle}
        mutateLands={mutateLands}
      />
      <div className="land-editor__ui">
        <div className="land-editor__controls">
          <div className="land-editor__map-style-toggle">
            <Toggle
              state={mapStyle === "streets"}
              falseStateText={"satellite"}
              falseStateIcon={<FaSatelliteDish />}
              trueStateText={"streets"}
              trueStateIcon={<FaRoad />}
              onToggle={toggleMapStyle}
            />
          </div>
          <LandNew
            asModal
            buttonVariant="second"
            onSuccess={handleLandCreated}
          />
          <Toggle
            state={editModeEnabled}
            falseStateText={"view"}
            falseStateIcon={<FaEye />}
            trueStateText={"edit"}
            trueStateIcon={<FaPen />}
            onToggle={toggleEditMode}
          />
          <Button onClick={fullscreen} variant="second">
            <MdFullscreen />
          </Button>
        </div>
        <div className="land-editor__owned-lands-search ">
          <FaFilter />
          <input
            type="text"
            name="owned-land"
            id="owned-land"
            className="form__input"
            placeholder="Filter your lands (LLD)"
            value={ownedLandsSearchTerm}
            onChange={handleOwnedLandsSearchChange}
          />
        </div>
        <div className="land-list land-list--map-editor">
          {lands.length ? (
            lands
              .sort((a, b) =>
                compareTimeStamp(a, b, "modified_ts", "new first")
              )
              .map((land) => {
                let similarity = stringSimilarity.compareTwoStrings(
                  ownedLandsSearchTerm,
                  land.description.description_string
                );

                if (similarity < 0.2 && ownedLandsSearchTerm.trim().length > 0)
                  return null;

                return (
                  <LandItem
                    key={land.land_id}
                    land={land}
                    onLocate={handleLandLocate}
                    onDelete={handleLandDelete}
                    mapEditor
                  />
                );
              })
          ) : (
            <p className="empty">
              <FaInfoCircle /> You haven't added any lands yet.
            </p>
          )}
        </div>
      </div>

      {/* {isValidating && <p>validating</p>} */}
    </div>
  );
};

export default LandEditor;
