import { useAuth0 } from "@auth0/auth0-react";
import Button from "../ui/Button";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  
  return <Button onClick={() => loginWithRedirect()}>login</Button>;
};

export default Login;
