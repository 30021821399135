const IconLocation = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a5.657 5.657 0 10-8 8l4 4 4-4a5.657 5.657 0 000-8zM6 9a3 3 0 100-6 3 3 0 000 6z"
      />
    </svg>
  );
};

export default IconLocation;
