const IconLoadingBig = () => {
  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32zM6.4 32c0 14.139 11.461 25.6 25.6 25.6 14.139 0 25.6-11.462 25.6-25.6C57.6 17.861 46.138 6.4 32 6.4 17.861 6.4 6.4 17.861 6.4 32z"
        fill="#B0D8C4"
      />
      <path
        d="M64 32A32 32 0 0032 0v6.4A25.6 25.6 0 0157.6 32H64zM62.127 35.903l-.168.95-.167.95a.059.059 0 00.048.068l.46.08a.058.058 0 00.068-.047l.334-1.9a.058.058 0 00-.047-.067l-.46-.081a.058.058 0 00-.068.047zM59.684 35.919a.058.058 0 01.05.065l-.12.957-.122.956a.059.059 0 01-.065.051l-.464-.059a.058.058 0 01-.05-.065l.242-1.913a.058.058 0 01.065-.051l.464.059z"
        fill="#228653"
      />
      <path
        d="M61.5 37.716l-.259.258a.059.059 0 01-.041.017h-.828a.058.058 0 01-.042-.017l-.258-.258a.059.059 0 01-.017-.042v-2.021l-.275-.275a.058.058 0 01-.017-.042v-.56h-.38a.058.058 0 01-.058-.058v-.234h-.526a.058.058 0 01-.059-.059v-1.928c0-.033.026-.059.059-.059h.467c.032 0 .059.026.059.059v.233l.412.275c.016.011.026.03.026.049v.553h.672a.059.059 0 00.059-.058v-.468c0-.032.026-.058.058-.058h.468c.032 0 .058.026.058.058v.468c0 .032.026.058.059.058h.672v-.553c0-.02.01-.038.026-.049l.412-.275v-.233c0-.033.026-.059.059-.059h.467c.032 0 .059.026.059.059v1.928a.058.058 0 01-.059.059h-.526v.234a.058.058 0 01-.058.058h-.38v.56c0 .016-.006.03-.017.042l-.276.275v2.021c0 .016-.006.03-.017.042z"
        fill="#228653"
      />
      <path d="M60.785 33.315v-1.753" stroke="#228653" strokeWidth={0.292} />
    </svg>
  );
};

export default IconLoadingBig;
