import React, { ReactNode } from "react";
import Errors from "../../form/Errors";
import Button from "../../ui/Button";

export type MoneySliderFieldType = {
  maxValue: number;
  label: string;
  id: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDirectChange: (targetInput: string, newValue: any) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  errors: { [key: string]: string[] };
  instructions: ReactNode;
  tweakControls?: boolean;
};

export const MoneySliderField = ({
  maxValue,
  label,
  id,
  value,
  handleChange,
  handleDirectChange,
  handleBlur,
  errors,
  instructions,
  tweakControls = false,
}: MoneySliderFieldType) => {
  const increaseBy1 = () => {
    let currentValue = value || "0";
    let newAmount = parseFloat(currentValue) + 1;
    if (newAmount > maxValue) return;
    let newValue = newAmount.toString();
    handleDirectChange(id, newValue);
  };

  const reduceBy1 = () => {
    let currentValue = value || "0";
    let newAmount = parseFloat(currentValue) - 1;
    if (newAmount < 0) return;
    let newValue = newAmount.toString();
    handleDirectChange(id, newValue);
  };

  const allIn = () => {
    let newAmount = maxValue;
    let newValue = newAmount.toString();
    handleDirectChange(id, newValue);
  };

  return (
    <div className="form__group">
      <label className="form__label" htmlFor={id}>
        {label}
      </label>
      {instructions}
      <input
        className="form__input"
        type="text"
        id={id}
        name={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Errors list={errors[id]} />
      <input
        type="range"
        id={`${id}-slider`}
        name={id}
        min="0"
        max={maxValue}
        value={value || 0}
        onChange={handleChange}
        style={{ accentColor: "hsl(195, 50%, 45%)" }}
      ></input>
      {tweakControls && (
        <div className="flex">
          <Button variant="second" stretch onClick={reduceBy1}>
            -1
          </Button>
          <Button variant="second" stretch onClick={increaseBy1}>
            +1
          </Button>
          <Button variant="second" stretch onClick={allIn}>
            all
          </Button>
        </div>
      )}
    </div>
  );
};
