import EnrollmentsWidget from "../features/enrollment/EnrollmentsWidget";

const EnrollmentsPage = () => {
  return (
    <div className="list">
      <EnrollmentsWidget />
    </div>
  );
};

export default EnrollmentsPage;
