import { ReactNode } from "react";
import { conditionalClassNames } from "../../helpers";

type ButtonProps = {
  children: ReactNode;
  customClasses?: string;
  variant?: "primary" | "second" | "third" | "danger";
  stretch?: boolean;
  inline?: boolean;
  disabled?: boolean;
  dataTest?: string;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  customClasses,
  variant = "primary",
  stretch = false,
  inline = false,
  disabled = false,
  dataTest,
  onClick,
}) => {
  return (
    <button
      className={conditionalClassNames(
        {
          button: true,
          "button--second": variant === "second",
          "button--third": variant === "third",
          "button--danger": variant === "danger",
          "button--stretch": stretch,
          "button--inline": inline,
        },
        customClasses
      )}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
    >
      {children}
    </button>
  );
};

export default Button;
