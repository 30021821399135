import { LngLatLike } from "mapbox-gl";
import { memo, useEffect, useState } from "react";
import { FaMapMarkerAlt, FaRegTrashAlt } from "react-icons/fa";
import {
  conditionalClassNames,
  getLandBounds,
  getLandDescription,
} from "../../../helpers";
import { useModal } from "../../../hooks/useModal";
import { LandModel } from "../../../types";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import ModalControls from "../../ui/ModalControls";
import LandImage from "./LandImage";

type LandItemProps = {
  land: LandModel;
  onLocate?: (center: LngLatLike) => void;
  onDelete?: (landId: string) => void;
  mapEditor?: boolean;
};

const LandItem = memo(
  ({ land, onLocate, onDelete, mapEditor = false }: LandItemProps) => {
    const [landBounds, setLandBounds] = useState<number[]>([]);
    const { modal } = useModal();

    useEffect(() => {
      if ("corners" in land) setLandBounds(getLandBounds(land));
    }, [land]);

    const handleLandLocate = () => {
      let center: LngLatLike = [land.center[1], land.center[0]];
      typeof onLocate === "function" && onLocate(center);
    };

    const promptLandDelete = () => modal.open(land.land_id);

    const handleLandDelete = () => {
      typeof onDelete === "function" && onDelete(land.land_id);
    };

    if (mapEditor) {
      return (
        <li
          className={conditionalClassNames({
            "land-item": true,
            "land-item--map-editor": mapEditor,
          })}
        >
          <LandImage landBounds={landBounds} imageSize={78} />
          <div className="land-item__body">
            <p className="land-item__description">
              {"description" in land
                ? land.description.description_string
                : getLandDescription(land)}
            </p>
            <div className="land-item__controls">
              <Button onClick={handleLandLocate} variant="primary" stretch>
                <FaMapMarkerAlt />
              </Button>
              <Button onClick={promptLandDelete} variant="second">
                <FaRegTrashAlt />
              </Button>
            </div>
          </div>
          <Modal
            id={land.land_id}
            visibility={modal.visibility(land.land_id)}
            handleClose={modal.close}
            title={"delete land"}
          >
            <p>
              are you sure you want to delete land:{" "}
              <b>{land.description.description_string}</b> ?
            </p>
            <ModalControls>
              <Button onClick={handleLandDelete} variant="second">
                Delete
              </Button>
              <Button onClick={modal.close} variant="primary">
                Cancel
              </Button>
            </ModalControls>
          </Modal>
        </li>
      );
    }

    return (
      <li className={"land-item"}>
        <LandImage landBounds={landBounds} imageSize={mapEditor ? 78 : 200} />
        <div className="land-item__body">
          <p className="land-item__description">
            {"description" in land
              ? land.description.description_string
              : getLandDescription(land)}
          </p>
          <div className="land-item__controls">
            <Button onClick={promptLandDelete} variant="second">
              <FaRegTrashAlt />
            </Button>
          </div>
        </div>
        <Modal
          id={land.land_id}
          visibility={modal.visibility(land.land_id)}
          handleClose={modal.close}
          title={"delete land"}
        >
          <p>
            are you sure you want to delete land:{" "}
            <b>{land.description.description_string}</b> ?
          </p>
          <ModalControls>
            <Button onClick={handleLandDelete} variant="second">
              Delete
            </Button>
            <Button onClick={modal.close} variant="primary">
              Cancel
            </Button>
          </ModalControls>
        </Modal>
      </li>
    );
  }
);

export default LandItem;

// [[50.491340574340256,-103.86177892381608],[50.491340574340256,-103.85045734885902],[50.49867700713035,-103.85045734885902],[50.49867700713035,-103.86177892381608]]
// [-103.86177892381608,50.491340574340256,-103.85045734885902,50.49867700713035]
