import { ReactNode } from "react";

type SelectedItemsProps = {
  items: string[];
  label: string;
  noItemsNote?: string;
  children?: ReactNode;
};

const SelectedItems = ({
  items,
  label,
  noItemsNote,
  children,
}: SelectedItemsProps) => {
  return (
    <div className="selected-items">
      <div className="selected-items__details">
        <h5>{label}:</h5>
        {items.length ? (
          <div className="selected-items__items">
            {items.map((item) => (
              <p className="selected-items__item" key={item}>
                {item}
              </p>
            ))}
          </div>
        ) : (
          <p className="subtitle">{noItemsNote}</p>
        )}
      </div>
      {children}
    </div>
  );
};

export default SelectedItems;
