import { EARLIEST_YEAR_SUPPORTED } from "../config/appConfig";
import { getCurrentYear } from "../helpers";
import { expressions } from "./regularExpressions";

export const validate = {
  required: (value: string) => {
    // function name "required" is ued to add "required" class to inputs.
    // tests
    if (value === "") {
      return { validity: false, issue: "can not be empty" };
    }
    // if all pass
    return { validity: true };
  },
  email: (value: string) => {
    // tests
    if (!value.match(expressions.email)) {
      return {
        validity: false,
        issue: "incorrect email",
      };
    }
    // if all pass
    return { validity: true };
  },
  url: (value: string) => {
    // don't validate empty strings. To make it requited use required().
    if (value !== "") {
      // tests
      if (!value.match(expressions.url)) {
        return {
          validity: false,
          issue: "incorrect URL format. Example: website.com",
        };
      }
    }
    // if all pass
    return { validity: true };
  },
  LLD: (value: string) => {
    // tests
    if (!value.match(expressions.LLD)) {
      return {
        validity: false,
        issue: "unsupported LLD format. Supported format: NE-2-2-2-W2",
      };
    }
    // if all pass
    return { validity: true };
  },
  latitude: (value: string) => {
    // tests
    if (!value.match(expressions.latitude)) {
      return { validity: false, issue: "unsupported latitude format" };
    }
    // if all pass
    return { validity: true };
  },
  longitude: (value: string) => {
    // tests
    if (!value.match(expressions.longitude)) {
      return { validity: false, issue: "unsupported latitude format" };
    }
    // if all pass
    return { validity: true };
  },
  noTillStartYear: (value: string) => {
    const intValue = parseInt(value);
    // tests
    if (isNaN(Number(value))) {
      return {
        validity: false,
        issue: "has to be a number",
      };
    }
    if (intValue < EARLIEST_YEAR_SUPPORTED) {
      return {
        validity: false,
        issue: "can not be earlier than " + EARLIEST_YEAR_SUPPORTED,
      };
    }
    if (intValue > getCurrentYear()) {
      return { validity: false, issue: "can not be in the future" };
    }
    // if all pass
    return { validity: true };
  },
  numberAboveZero: (value: string) => {
    // tests
    if (isNaN(Number(value))) {
      return {
        validity: false,
        issue: "has to be a number",
      };
    }
    if (Number(value) <= 0) {
      return {
        validity: false,
        issue: "has to be above 0",
      };
    }
    // if all pass
    return { validity: true };
  },
  businessNumber: (value: string) => {
    // tests
    if (!value.match(expressions.businessNumber)) {
      return {
        validity: false,
        issue: "unsupported business number format (e.g. 123456789RT1234)",
      };
    }
    // if all pass
    return { validity: true };
  },
  phoneNumber: (value: string) => {
    // tests
    if (isNaN(Number(value))) {
      return {
        validity: false,
        issue: "has unsupported characters (only numbers allowed)",
      };
    }
    if (value.length > 10) {
      return {
        validity: false,
        issue: "this input allows 10 numbers at most",
      };
    }
    // if all pass
    return { validity: true };
  },
};
