import { useAuth0 } from "@auth0/auth0-react";
import { conditionalClassNames } from "../../helpers";
import Widget from "../layout/Widget";

const TermsAndConditions = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div
      className={conditionalClassNames({
        "margin-24": !isAuthenticated,
      })}
    >
      <Widget title="Terms of Use">
        <p>
          <strong>Last Updated</strong>: October 25, 2022
        </p>
        <p>
          The following terms of use (“<strong>Terms of Use</strong>”) form a
          legal agreement between the Person visiting, browsing, accessing,
          downloading, installing or otherwise using (the terms “
          <strong>use</strong>” and “<strong>using</strong>” will refer to any
          of the foregoing) the Sites (such person, “<strong>you</strong>”) and
          CarbonTerra Limited (“<strong>CarbonTerra</strong>”) as of the earlier
          of: (a) the date you first access or use the Sites; and (b) the date
          that you become a Registered User (the “
          <strong>Effective Date</strong>
          ”).
        </p>
        <p>
          <strong>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SITES.{" "}
          </strong>
          <strong>
            IF YOU DO NOT ACCEPT AND AGREE TO BE BOUND BY THESE TERMS OF USE,
            PLEASE IMMEDIATELY CEASE ANY FURTHER USE OF THE SITES. YOU SHOULD
            PRINT A COPY OF THESE TERMS OF USE FOR FUTURE REFERENCE.
          </strong>
        </p>
        <ol>
          <li>Definitions</li>
        </ol>
        <p>
          Capitalized terms used and not otherwise defined in these Terms of Use
          have the meanings ascribed to them in Exhibit A (Glossary).
        </p>
        <ol>
          <li>Acceptance</li>
        </ol>
        <p>
          By: (a) accessing or using the Sites; or (b) become a Registered User,
          which may include indicating your acceptance of these Terms of Use by
          [clicking “I Accept”] as part of the registration process, you confirm
          that you have read, understood, accepted and agree to be bound by and
          comply with these Terms of Use as updated from time to time in
          accordance with Section 3. By taking any of the foregoing actions, you
          represent and warrant to CarbonTerra that: (i) you have the capacity
          to enter into this binding legal agreement; and (ii) all information
          supplied by you to CarbonTerra is true, accurate, and complete. If you
          are using the Sites on behalf of another Person, you represent and
          warrant to CarbonTerra that you have the authority to bind such Person
          to these Terms of Use as updated from time to time in accordance with
          Section 3.
        </p>
        <ol>
          <li>
            Changes
            <ul>
              <li>
                CarbonTerra reserves the right to change these Terms of Use, the
                Privacy Policy, and any other terms incorporated by reference
                herein, at any time by posting a new version to the Sites. Any
                and all such modifications are effective immediately upon
                posting and apply to all access to and continued use of the
                Sites. Your continued access to or use of the Sites after any
                changes to these Terms of Use indicates your acceptance of such
                changes. It is your responsibility to review these Terms of Use
                regularly in order to be aware of any such modifications.
              </li>
              <li>
                CarbonTerra reserves the right to change, withdraw, suspend or
                terminate the Sites at any time without notice. CarbonTerra will
                not be liable if your access to the Sites is restricted or the
                Sites are not available to you at any time, for any period, and
                for any reason.
              </li>
              <li>
                CarbonTerra reserves the right to suspend, deactivate, or
                replace a MyTerra Account at any time and for any reason.
              </li>
            </ul>
          </li>
          <li>Your MyTerra Account</li>
        </ol>
        <p>
          You must be a Registered User in order to access the MyTerra Platform.
          As part of the registration process, you will be required to provide
          certain information to CarbonTerra, including your name, email
          address, farm name, and geographic location (collectively,
          “Registration Information”) and to select a password. You are
          responsible for maintaining the confidentiality of your password and
          MyTerra Account and for all use of your password and MyTerra Account.
          You agree to: (a) immediately notify CarbonTerra of any actual or
          suspected unauthorized use of your password or MyTerra Account; and
          (b) ensure that you log out of your MyTerra Account at the end of each
          session. You agree that CarbonTerra will not be liable for any loss or
          damage arising from your failure to keep your password or MyTerra
          Account secure.
        </p>
        <ol>
          <li>Assessment</li>
        </ol>
        <p>
          The MyTerra Platform allows Registered Users to provide CarbonTerra
          with additional information, such as information about the land that
          you own (collectively, “Assessment Information”), for the purpose of
          assessing carbon credits and/or providing agronomic recommendations.
          Nothing in these Terms of Use will obligate either Party to enter into
          a Commitment Agreement. Without limiting the foregoing, undertaking
          any of the following actions will not obligate either Party to enter
          into a Commitment Agreement: (a) providing the Sites; (b) accessing or
          using the Sites; (c) becoming a Registered User; (d) issuing a MyTerra
          Account; (e) submitting Assessment Information to CarbonTerra; (f)
          evaluating Assessment Information; or (g) [providing an estimate of
          credits].
        </p>
        <ol>
          <li>
            Your Use of the Sites
            <ul>
              <li>
                You will not (and will not permit any other Person to):
                <ul>
                  <li>
                    share, transfer or otherwise provide access to your MyTerra
                    Account to another Person;
                  </li>
                  <li>
                    sub-license, publish, market, advertise, sell, rent, lend,
                    lease or in any way distribute the Sites or any intellectual
                    property rights therein, or otherwise make the Sites
                    available to others;
                  </li>
                  <li>
                    remove or obscure any proprietary notices or labels on the
                    Sites, including brand, copyright, trademark and patent or
                    patent pending notices;
                  </li>
                  <li>
                    use the Sites to permit timesharing or service bureau use;
                  </li>
                  <li>
                    access or use the Sites for the purpose of building a
                    similar or competitive product or service;
                  </li>
                  <li>
                    access or use Content on or in the Sites that is not
                    intended for you;
                  </li>
                  <li>
                    access or use the Sites in violation of any applicable law
                    or intellectual property right, in a manner that threatens
                    the security or functionality of the Sites, or for any
                    purpose or in any manner not expressly permitted in these
                    Terms of Use;
                  </li>
                  <li>
                    perform any vulnerability, penetration or similar testing of
                    the Sites;
                  </li>
                  <li>
                    attempt to gain unauthorized access to the Sites, or to
                    circumvent, bypass or violate the security of the Sites;
                  </li>
                  <li>
                    copy, Modify, reverse engineer, reverse assemble,
                    disassemble, or decompile the Sites or any part thereof or
                    otherwise attempt to discover any source code;
                  </li>
                  <li>
                    disable, overly burden, impair, or otherwise interfere with
                    servers or networks connected to Sites; or
                  </li>
                  <li>
                    use the Sites to send, upload, collect, transmit, store,
                    use, post, publish or otherwise communicate any Content
                    that: (A) contains any computer viruses, worms, malicious
                    code, or any software intended to damage or alter a computer
                    system or data; (B) you do not have the lawful right to
                    send, upload, collect, transmit, store, use, post, publish,
                    or otherwise communicate; (C) is false, intentionally
                    misleading, or impersonates any other person; (D) is
                    libelous, slanderous, defamatory, bullying, harassing,
                    abusive, threatening, vulgar, obscene, or offensive, or that
                    contains pornography, nudity, or graphic or gratuitous
                    violence, or that promotes violence, racism, discrimination,
                    bigotry, hatred, or physical harm of any kind against any
                    group or individual; (E) is harmful to minors in any way or
                    targeted at minors; (F) infringes, violates or otherwise
                    misappropriates the intellectual property or other rights of
                    any third party (including any moral right, privacy right or
                    right of publicity); or (G) encourages any conduct that may
                    violate any applicable laws or would give rise to civil or
                    criminal liability.
                  </li>
                </ul>
              </li>
              <li>
                Without limiting the foregoing, you represent and warrant to and
                covenant with CarbonTerra that your use of the Sites will not:
                <ul>
                  <li>
                    in any manner violate any applicable federal, provincial,
                    local, or international law or regulation including, without
                    limitation, any laws regarding the export of data or
                    software, patent, trademark, trade secret, copyright, or
                    other intellectual property, legal rights (including the
                    rights of publicity and privacy of others) or contain any
                    material that could give rise to any civil or criminal
                    liability under applicable laws or regulations or that
                    otherwise may be in conflict with these Terms of Use and the
                    Privacy Policy;
                  </li>
                  <li>
                    in any manner violate the terms and conditions of any
                    third-party website that is linked to the Sites;
                  </li>
                  <li>
                    include or contain any material that is exploitive, obscene,
                    harmful, threatening, abusive, harassing, hateful,
                    defamatory, sexually explicit or pornographic, violent,
                    inflammatory, or discriminatory based on race, sex,
                    religion, nationality, disability, sexual orientation, or
                    age or other such legally prohibited ground or be otherwise
                    objectionable, such determination to be made in CarbonTerra’
                    sole discretion;
                  </li>
                  <li>
                    involve stalking, attempting to exploit or harm any
                    individual (including minors) in any way by exposing them to
                    inappropriate content or otherwise or ask for personal
                    information as prohibited under applicable laws,
                    regulations, or code;
                  </li>
                  <li>
                    involve, provide, or contribute any false, inaccurate, or
                    misleading information;
                  </li>
                  <li>
                    include sending, knowingly receiving, uploading,
                    downloading, using, or reusing any material that does not
                    comply with these Terms of Use;
                  </li>
                  <li>
                    involve impersonating or attempting to impersonate
                    CarbonTerra, a CarbonTerra employee, another user of the
                    Sites, or any other Person (including, without limitation,
                    by using email addresses, or screen names associated with
                    any of the foregoing);
                  </li>
                  <li>
                    transmit, or procure the sending of, any advertisements or
                    promotions without CarbonTerra’s prior written consent,
                    sales, or encourage any other commercial activities,
                    including, without limitation, any “spam”, “junk mail”,
                    “chain letter”, contests, sweepstakes and other sales
                    promotions, barter, or advertising or any other similar
                    solicitation;
                  </li>
                  <li>
                    encourage any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of the Sites, or which, as
                    determined by CarbonTerra, may harm CarbonTerra or users of
                    the Sites or expose them to liability;
                  </li>
                  <li>
                    cause annoyance, inconvenience, or needless anxiety or be
                    likely to upset, embarrass, or alarm any other person;
                  </li>
                  <li>
                    promote any illegal activity, or advocate, promote, or
                    assist any unlawful act; or
                  </li>
                  <li>
                    give the impression that they originate from or are endorsed
                    by CarbonTerra or any other Person, if this is not the case.
                  </li>
                </ul>
              </li>
              <li>
                You will accept all patches, bug fixes and updates made by or on
                behalf of CarbonTerra to the Sites.
              </li>
              <li>
                CarbonTerra may, at its discretion:
                <ul>
                  <li>
                    suspend your access to or use of the Sites:
                    <ul>
                      <li>for scheduled maintenance;</li>
                      <li>
                        if you violate any provision of these Terms of Use; or
                      </li>
                      <li>to address any emergency security concerns;</li>
                    </ul>
                  </li>
                  <li>Modify the Sites; and</li>
                  <li>engage third parties to provide the Sites.</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <ol>
          <li>
            Intellectual Property Rights; Reservation of Rights
            <ul>
              <li>
                You retain all ownership and intellectual property rights in and
                to your User Information. You grant to CarbonTerra a
                non-exclusive, worldwide, royalty-free, irrevocable,
                sublicensable, fully paid-up right to access, collect, use,
                process, Modify, store, transfer, disclose and transmit the User
                Information to: (i) provide the Sites; (ii) improve and enhance
                the Sites and other products or services offered by CarbonTerra
                and its Affiliates; (iii) collect and analyze data and other
                information relating to the provision, use and performance of
                the Sites and related systems and technologies; and (iv) produce
                data, information and other materials (such as usage data) that
                are not identified as relating to a particular individual or
                company (such data, information and materials, “
                <strong>Aggregated Data</strong>
                ”). CarbonTerra will own all Aggregated Data and may use,
                process, store, disclose and transmit Aggregated Data for any
                purpose and without restriction or obligation to you of any
                kind. You hereby assign and transfer to CarbonTerra, and agree
                to assign and transfer to CarbonTerra, as and when created, all
                rights in any User Information contained in the Aggregated Data
                that may be owned by you to the extent that any such rights have
                not automatically and immediately vested in CarbonTerra.
              </li>
              <li>
                CarbonTerra will own all Collected Information and may use,
                process, store, disclose and transmit Collected Information for
                any purpose and without restriction or obligation to you of any
                kind. You hereby assign and transfer to CarbonTerra, and agree
                to assign and transfer to CarbonTerra, as and when created, all
                rights in any Collected Information that may be owned by you to
                the extent that any such rights have not automatically and
                immediately vested in CarbonTerra.
              </li>
              <li>
                You acknowledge and agree that your access to or use of the
                Sites may involve downloading copies of Content from the Sites
                to your personal device. Subject to your compliance with these
                Terms of Use, CarbonTerra grants you a limited, revocable,
                non-exclusive, non-assignable and non-transferable license to
                access, display, review, download and print single copies of
                Content made available to you on or through the Sites.
              </li>
              <li>
                CarbonTerra or its licensors and third party suppliers, as
                applicable, retain all ownership and intellectual property
                rights in and to: (i) the Sites; (ii) anything developed or
                delivered by or on behalf of CarbonTerra under these Terms of
                Use; and (iii) any Modifications to the foregoing (i) and (ii).
              </li>
              <li>
                The Sites are made available or licensed and not “sold” to you
                and are protected by copyright. You are prohibited from
                modifying, copying, reproducing, publishing, posting,
                transmitting, distributing, creating derivative works from,
                decompiling, transferring or selling any part of the Sites, or
                sharing or granting access in any of the foregoing to any third
                party for any purpose.
              </li>
              <li>
                To the extent that you submit ideas, suggestions, documents, or
                proposals regarding the Sites to CarbonTerra (“
                <strong>Feedback</strong>”), you acknowledge and agree that:
                <ul>
                  <li>
                    the Feedback does not contain confidential or proprietary
                    information and CarbonTerra is not under any obligation of
                    confidentiality with respect to the Feedback;
                  </li>
                  <li>
                    CarbonTerra will be entitled to use, commercialize or
                    disclose (or to choose not to use, commercialize, or
                    disclose) the Feedback for any purpose, in any way, in any
                    manner, and to anyone worldwide without any compensation or
                    reimbursement of any kind to you for such use;
                  </li>
                  <li>
                    you waive any moral rights or other rights of authorship in
                    the Feedback as a condition of submitting the Feedback.
                  </li>
                </ul>
              </li>
              <li>
                Any trademarks, trade names, domain names, graphics or logos
                appearing on or in the Sites, including “MyTerra”,
                “CarbonTerra”, “Atlas”, “Agronomax”, “Agraterra” (collectively,
                “<strong>Marks</strong>”) are the exclusive property of
                CarbonTerra, CarbonTerra’s Affiliates, or CarbonTerra’s
                third-party suppliers. You may not use the Marks any manner
                without CarbonTerra’s express written consent.
              </li>
              <li>
                All rights not expressly granted by CarbonTerra to you under
                these Terms of Use are reserved.
              </li>
            </ul>
          </li>
          <li>
            Privacy
            <ul>
              <li>
                <u>Privacy Policy</u>. The terms of CarbonTerra’s privacy policy
                located at{" "}
                <strong>https://carbonterra.com/privacy-policy/</strong> (the “
                <strong>Privacy Policy</strong>”) are incorporated into these
                Terms of Use by reference.
              </li>
              <li>
                <u>Personal Information</u>. You agree that CarbonTerra may
                access, use, collect, store and disclose your Personal
                Information: (a) to comply with Privacy Laws; and (b) for the
                purposes authorized under these Terms of Use, including the
                Privacy Policy incorporated by reference herein. You understand
                that CarbonTerra will handle your Personal Information in
                accordance with the Privacy Policy. You represent and warrant to
                and covenant with CarbonTerra that you have and will continue to
                maintain all necessary authority and consent under applicable
                Privacy Laws to transfer Personal Information to CarbonTerra for
                the purposes described in these Terms of Use, including the
                Privacy Policy incorporated by reference herein.
              </li>
              <li>
                <u>Communications Not Confidential</u>. CarbonTerra cannot
                guarantee the confidentiality of any communications made in or
                through the Sites or the security of Personal Information
                communicated over the Internet, public networks or otherwise
                transmitted to the Sites. If you choose to transmit Personal
                Information, you do so at your own risk. CarbonTerra is not
                responsible for circumvention of any privacy settings or
                security measures contained on the Sites.
              </li>
            </ul>
          </li>
          <li>Additional Terms</li>
        </ol>
        <p>
          Your access to and use of certain functionalities provided in or
          through the Sites may be subject to additional terms and conditions
          presented to you by CarbonTerra, CarbonTerra’s Affiliates or
          CarbonTerra’s service providers. Such additional terms and conditions
          are incorporated herein by reference.&nbsp; If there is a conflict or
          inconsistency between the terms and conditions of such additional
          terms and these Terms of Use, then the provisions of these Terms of
          Use will govern to the extent of such conflict or inconsistency,
          unless the conflicting term in the additional terms expressly states
          that the conflicting term in these Terms of Use do not apply. If you
          do not accept and agree to such additional terms and conditions, you
          may not be able to, and you should not, access or use those
          functionalities.
        </p>
        <ol>
          <li>
            Confidential Information
            <ul>
              <li>
                <u></u> “<strong>Confidential Information</strong>” means
                non-public or proprietary information of a Party (the “
                <strong>Disclosing Party</strong>”), regardless of form (oral,
                written, database or electronic), or whether it is marked
                “confidential”, that is disclosed or made available to, or that
                comes into the possession of, the other Party (the “
                <strong>Receiving Party</strong>”), either directly or
                indirectly, through any means of communication or by
                observation, in connection with or as a result of entering into
                these Terms of Use. Confidential Information includes: (i)
                information relating to the Disclosing Party and its investors,
                Affiliates or any customer of or supplier or lender to the
                Disclosing Party and its Affiliates; and (ii) any summaries,
                notes, analyses, compilations, studies or other records that
                contain or otherwise reflect or have been generated, wholly or
                partly, or derived from such information. Except with respect to
                Personal Information, Confidential Information does not include:
                (a) Collected Information; (b) Aggregated Data; (c) Feedback; or
                (d) information that: (I) is, or prior to the time of disclosure
                becomes, generally available to the public other than as a
                result of a disclosure by the Receiving Party or its
                representatives; (II) is received from an independent third
                party who had obtained the information lawfully and was under no
                obligation of secrecy or duty of confidentiality; (III) the
                Receiving Party can show was in its lawful possession before it
                received such information from the Disclosing Party; or (IV) the
                Receiving Party can show was independently developed by it or on
                its behalf.
              </li>
              <li>
                <u>Confidentiality Covenants.</u> Except as otherwise provided
                in these Terms of Use, the Receiving Party hereby agrees that
                during the Term and at all times following the Term it will not:
                (i) disclose Confidential Information of the Disclosing Party to
                any third party except such recipients as the Disclosing Party
                may approve in writing; (ii) use Confidential Information of the
                Disclosing Party except to exercise its rights or perform its
                obligations under these Terms of Use; or (iii) alter or remove
                from any Confidential Information of the Disclosing Party any
                proprietary legend. Each Party will take reasonable precautions
                to safeguard the other Party’s Confidential Information.&nbsp;
                Those precautions will be at least as great as the precautions
                that the Receiving Party takes to protect its own Confidential
                Information of a similar type.
              </li>
              <li>
                <u>Exceptions to Confidentiality.</u> Notwithstanding anything
                to the contrary in these Terms of Use, the Receiving Party may
                disclose the Disclosing Party’s Confidential Information: (i) to
                the extent that such disclosure is required by applicable law or
                by the order of a court or similar judicial or administrative
                body, provided that, except to the extent prohibited by law, the
                Receiving Party promptly notifies the Disclosing Party in
                writing of such required disclosure and cooperates with the
                Disclosing Party to seek an appropriate protective order; (ii)
                to its legal counsel and other professional advisors if and to
                the extent such Persons need to know such Confidential
                Information in order to provide applicable professional advisory
                services in connection with the Party’s business; or (iii) in
                the case of CarbonTerra, to: (A) CarbonTerra’s personnel and
                Affiliates; and (B) potential assignees, acquirers or successors
                of CarbonTerra if and to the extent such persons need to know
                such Confidential Information in connection with a potential
                sale, merger, amalgamation or other corporate transaction
                involving the business or assets of CarbonTerra.
              </li>
            </ul>
          </li>
          <li>
            Warranty; Disclaimer; Indemnity
            <ul>
              <li>
                <u>Personal Information Warranty</u>. You represent and warrant
                to, and covenant with CarbonTerra that the User Information,
                Registration Information, Assessment Information and Feedback
                that you provide to CarbonTerra or transmit, load, or enter into
                the Sites will only contain Personal Information in respect of
                which you have provided all notices and disclosures, obtained
                all applicable third party consents and permissions and
                otherwise have all authority, in each case as required by
                applicable laws, to enable CarbonTerra to the collect, access,
                use, store, disclose and otherwise transmit Personal Information
                in accordance with these Terms of Use.
              </li>
              <li>
                <u>GENERAL DISCLAIMER.</u> CARBONTERRA DOES NOT WARRANT THAT THE
                AVAILABILITY OF THE SITES WILL BE UNINTERRUPTED, THAT THE SITES
                WILL BE ERROR FREE, OR THAT ALL ERRORS IN THE SITES CAN OR WILL
                BE CORRECTED; NOR DOES CARBONTERRA MAKE ANY WARRANTY AS TO THE
                RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITES.&nbsp; EXCEPT
                AS SPECIFICALLY PROVIDED IN THESE TERMS OF USE, THE SITES AND
                ANY OTHER PRODUCTS AND SERVICES PROVIDED BY CARBONTERRA TO YOU
                ARE PROVIDED “AS IS” AND “AS AVAILABLE”. TO THE FULLEST EXTENT
                PERMITTED BY APPLICABLE LAW, CARBONTERRA DISCLAIMS ALL
                WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY KIND WITH
                RESPECT TO THIRD PARTY COMMUNICATIONS AND ANY THIRD PARTY
                WEBSITES OR CONTENT DIRECTLY OR INDIRECTLY ACCESSED THROUGH THE
                SITES.
              </li>
              <li>
                <u>DISCLAIMER OF WARRANTIES. </u>TO THE EXTENT PERMITTED BY
                APPLICABLE LAW, CARBONTERRA HEREBY DISCLAIMS ALL EXPRESS,
                IMPLIED, COLLATERAL OR STATUTORY WARRANTIES, REPRESENTATIONS AND
                CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED
                WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE
                QUALITY, COMPATIBILITY, TITLE, NON-INFRINGEMENT, SECURITY,
                RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY, QUALITY,
                INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE OR USE, OR ANY
                WARRANTIES OR CONDITIONS ARISING OUT OF COURSE OF DEALING OR
                USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF ANY OF THE
                FOREGOING, CARBONTERRA EXPRESSLY DISCLAIMS ANY REPRESENTATION,
                CONDITION OR WARRANTY THAT ANY DATA OR INFORMATION PROVIDED TO
                YOU IN CONNECTION WITH YOUR USE OF THE SITES IS ACCURATE, OR CAN
                OR SHOULD BE RELIED UPON BY YOU FOR ANY PURPOSE WHATSOEVER.
                &nbsp;
              </li>
              <li>
                <u></u> You will defend, indemnify and hold harmless
                CarbonTerra, CarbonTerra’s Affiliates, and the employees,
                officers, directors, agents, contractors, successors, and
                assigns of CarbonTerra and its affiliates (collectively, the “
                <strong>Indemnitees</strong>”), against any and all third party
                liability (including damages, recoveries, deficiencies,
                interest, penalties and legal fees), directly or indirectly
                arising from or in connection with: (i) User Information; (ii)
                Feedback; (iii) your use of or access to the Sites, including
                any use of the Sites in combination with any third party
                software, application or service; (iv) your breach of any
                provision of these Terms of Use; or (v) your violation of any
                law or the rights of a third party (including intellectual
                property rights). You will fully cooperate with the applicable
                Indemnitees in the defense of any claim that you defend pursuant
                to your indemnification obligations under these Terms of Use and
                will not settle any such claim without the prior written consent
                of CarbonTerra. &nbsp;
              </li>
            </ul>
          </li>
          <li>Limitation of Liability</li>
        </ol>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          CARBONTERRA BE LIABLE, WHETHER BASED ON WARRANTY, CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL THEORY, FOR ANY
          INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE
          DAMAGES; OR LOST PROFITS, LOSS OF USE, LOSS OF DATA, PERSONAL INJURY,
          FINES, FEES, PENALTIES OR OTHER LIABILITIES, IN EACH CASE, WHETHER OR
          NOT CARBONTERRA WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
          RESULTING FROM OR RELATED TO THE SITES OR THESE TERMS OF USE.
        </p>
        <p>
          FOR ANY OTHER DAMAGES, OR TO THE EXTENT THAT THE FOREGOING LIMITATION
          IS NOT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL CARBONTERRA’S
          TOTAL AGGREGATE LIABILITY IN CONNECTION WITH OR UNDER THESE TERMS OF
          USE, OR YOUR USE OF, OR INABILITY TO MAKE USE OF THE SITES, EXCEED THE
          LESSER OF: (A) THE AMOUNT OF FEES PAID BY YOU TO CARBONTERRA IN
          CONNECTION WITH YOUR USE OF THE SITES IN THE 12 MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM; OR (B) $100
          CAD. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER
          THESE TERMS OF USE WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.
        </p>
        <ol>
          <li>Third Party Promotions, Content, Websites or Services</li>
        </ol>
        <p>
          The Sites may provide you with access to third party promotions and
          offers through links, third party content, websites, or services.
          Likewise, CarbonTerra may allow you to access the Sites from third
          party systems. CarbonTerra does not endorse any third party content,
          websites, services, or systems, or guarantee their quality, accuracy,
          reliability, completeness, currency, timeliness, non-infringement,
          merchantability, or fitness for any purpose. Third party content,
          websites, services, or systems (including any offers or promotions)
          are not under CarbonTerra’s control. If you choose to access any such
          content, websites, or services, or to access the Sites from such
          systems, you do so entirely at your own risk.
        </p>
        <ol>
          <li>Your Computer System</li>
        </ol>
        <p>
          Downloading and viewing Content is done at Your own risk. CarbonTerra
          does not guarantee or warrant that the Sites are compatible with your
          computer system or that the Sites, or any links from the Sites, will
          be free of viruses, worms, trojan horses or disabling devices or other
          code that manifests contaminating or destructive properties. You are
          responsible for implementing safeguards to protect the security and
          integrity of your computer system, and you are responsible for the
          entire cost of any service, repairs or connections of and to your
          computer system that may be necessary as a result of your use of the
          Sites.
        </p>
        <ol>
          <li>
            Term and Termination
            <ul>
              <li>
                <u>Term</u>. These Terms of Use will commence on the Effective
                Date and continue to be in effect until terminated by either
                Party in accordance with the provisions of these Terms of Use
                (the “<strong>Term</strong>”).
              </li>
              <li>
                <u>Termination for Convenience.</u> Either Party may terminate
                these Terms of Use at any time by providing advance written
                notice of not less than 30 days to the other Party.
              </li>
              <li>
                <u>Termination for Cause</u>. CarbonTerra may, in addition to
                other relief, suspend or terminate these Terms of Use if You
                commits a material breach of any provision of these Terms of Use
                and fail to correct such material breach within 15 calendar days
                of receiving notice of such breach.
              </li>
            </ul>
          </li>
          <li>Survival</li>
        </ol>
        <p>
          The following Sections, together with any other provision of these
          Terms of Use which expressly or by its nature survives termination or
          expiration, or which contemplates performance or observance subsequent
          to termination or expiration of these Terms of Use, will survive
          expiration or termination of these Terms of Use for any reason:
          Section 7 (Intellectual Property Rights; Reservation of Rights),
          Section 8 (Privacy), Section 9 (Additional Terms), Section 10
          (Confidential Information), Section 11 (Warranty; Disclaimer;
          Indemnity), Section 12 (Limitation of Liability), Section 13 (Third
          Party Promotions, Content, Websites or Services), Section 14 (Your
          Computer System), this Section 16 (Survival), and Section 17 (General
          Provisions).
        </p>
        <ol>
          <li>
            General Provisions
            <ul>
              <li>
                <u></u> Notices sent to either Party will be effective when
                delivered in person or by email, one day after being sent by
                overnight courier, or five days after being sent by first class
                mail postage prepaid to the official contact designated by the
                Party to whom a notice is being given. To provide you with
                notice, CarbonTerra will: (a) send such notice to the email
                affiliated with your MyTerra Account; or (b) post such notice to
                the Sites. You are responsible for updating your MyTerra Account
                to ensure that the email affiliated with your MyTerra Account is
                current.&nbsp; To provide CarbonTerra with notice, you will send
                such notice to the following address:
              </li>
            </ul>
          </li>
        </ol>
        <table>
          <tbody>
            <tr>
              <td width="312">
                <p>By Mail:</p>
              </td>
              <td width="312">
                <p>
                  Privacy Officer
                  <br />
                  CarbonTerra Limited
                  <br />
                  211 – 310 Wall Street
                  <br />
                  Saskatoon, S7K 1N7, SK, Canada
                </p>
              </td>
            </tr>
            <tr>
              <td width="312">
                <p>By Email:</p>
              </td>
              <td width="312">
                <p>
                  <a href="mailto:privacyofficer@carbonterra.earth">
                    privacyofficer@carbonterra.earth
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p>
          CarbonTerra may update its contact information by providing you with
          notice of such updates.
        </p>
        <ul>
          <li>
            <u>Assignment</u>. You will not assign, transfer, sub-license or
            subcontract these Terms of Use, or any of Your rights or obligations
            under these Terms of Use, to any third party without CarbonTerra’s
            prior written consent.&nbsp; CarbonTerra may assign, transfer,
            sub-license or subcontract these Terms of Use, or any of its rights
            under these Terms of Use, to any third party without Your consent.
            Any assignment in violation of this Section will be void. these
            Terms of Use will inure to the benefit of and be binding upon the
            Parties, their permitted successors and permitted assignees.
          </li>
          <li>
            <u>Choice of Law</u>. These Terms of Use and any action related
            thereto will be governed by and construed in accordance with the
            substantive laws of the Province of Saskatchewan and the applicable
            federal laws of Canada, without regard to conflicts of law
            principles.&nbsp; The U.N. Convention on Contracts for the
            International Sale of Goods will not apply to these Terms of Use.
            This choice of jurisdiction does not prevent CarbonTerra from
            seeking injunctive relief with respect to a violation of
            intellectual property rights or confidentiality obligations in any
            appropriate jurisdiction.
          </li>
          <li>
            <u>Export Restrictions.</u> You will comply with all export laws and
            regulations that may apply to Your access to or use of the Sites.
          </li>
          <li>
            <u></u> Except as otherwise provided in these Terms of Use, the
            Parties’ rights and remedies under these Terms of Use are
            cumulative.&nbsp; The terms “include” and “including” mean,
            respectively, “include without limitation” and “including without
            limitation.” The term “Sites” means the “Sites or any component
            thereof”. The headings of sections of these Terms of Use are for
            reference purposes only and have no substantive effect. The terms
            “consent” or “discretion”, when used in respect of CarbonTerra in
            these Terms of Use means the right of CarbonTerra to withhold such
            consent or exercise such discretion, as applicable, arbitrarily and
            without any implied obligation to act reasonably or explain its
            decision to the Customer.
          </li>
          <li>
            <u>Force Majeure.</u> Neither Party will be liable for delays caused
            by any event or circumstances beyond that Party’s s reasonable
            control, including acts of God, acts of government, flood, fire,
            earthquakes, civil unrest, acts of terror, strikes, slowdowns,
            walkouts or other labour problems, Internet service failures or
            delays, cyberattacks, or the unavailability or Modification by third
            parties of telecommunications or hosting infrastructure or third
            party software or websites or changes in laws preventing or limiting
            the provision of the services.
          </li>
          <li>
            <u></u> Any provision of these Terms of Use found by a tribunal or
            court of competent jurisdiction to be illegal or unenforceable will
            be severed from these Terms of Use and all other provisions of these
            Terms of Use will remain in full force and effect.
          </li>
          <li>
            <u></u> A waiver of any provision of these Terms of Use must be in
            writing and a waiver in one instance will not preclude enforcement
            of such provision on other occasions.
          </li>
          <li>
            <u>Independent Contractors.</u> CarbonTerra’s relationship to You is
            that of an independent contractor, and neither Party is an agent or
            partner of the other.&nbsp; Neither Party will have, and will not
            represent to any third party that it has, any authority to bind the
            other Party.
          </li>
          <li>
            <u>Entire Agreement.</u> These Terms of Use, including the Privacy
            Policy incorporated by reference herein, constitute the entire
            agreement between the Parties with respect to the subject matter of
            these Terms of Use and supersedes all prior or contemporaneous
            agreements, representations or other communications, whether written
            or oral.
          </li>
          <li>
            <u>English Language.</u> It is the express wish of the Parties that
            these Terms of Use and all related documents be drawn up in
            English.&nbsp; C’est la volonté expresse des parties que la présente
            convention ainsi que les documents qui s’y rattachent soient rédigés
            en anglais.
          </li>
        </ul>
        <ol>
          <li>Contact</li>
        </ol>
        <p>
          If you have any questions regarding these Terms of Use, please contact
          CarbonTerra at <strong>privacyofficer@carbonterra.earth</strong>.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            EXHIBIT A<br />
            GLOSSARY
          </strong>
        </p>
        <p>
          Capitalized terms used in these Terms of Use have the following
          meanings:
        </p>
        <ul>
          <li>
            “<strong>Affiliate</strong>” means, in relation to any Person
            including you or CarbonTerra, any other Person directly or
            indirectly Controlling or Controlled by, or under direct or indirect
            common Control with, that Person. For the purpose of this
            definition, a Person will be deemed to control another Person if
            that Person possesses, directly or indirectly, the power to direct
            or cause the direction of the management or policies of that other
            Person, whether by operation of law, by contract, through the
            ownership of voting securities or otherwise.
          </li>
          <li>
            “<strong>Aggregated Data</strong>” has the meaning set out in
            Section 3(a).
          </li>
          <li>
            “<strong>Assessment Information</strong>” has the meaning set out in
            Section 5.
          </li>
          <li>
            “<strong>CarbonTerra</strong>” has the meaning set out in the
            preamble to these Terms of Use.
          </li>
          <li>
            “<strong>Collected Information</strong>” means: (i) Registration
            Information; (ii) Assessment Information; (iii) all information
            collected by or on behalf of CarbonTerra in connection with a
            Commitment Agreement; and (iii) any works derived from, and any
            intellectual property rights in, any of the foregoing.
          </li>
          <li>
            “<strong>Commitment Agreement</strong>” means any agreement entered
            into between you and CarbonTerra regarding the transfer and sale of
            any carbon credits, emission offsets, or similar assets.
          </li>
          <li>
            “<strong>Confidential Information</strong>” has the meaning set out
            in Section 10(a).
          </li>
          <li>
            “<strong>Content</strong>” means interfaces, functionality,
            graphics, images, videos, audio, text, data, documentation, and
            other information.
          </li>
          <li>
            “<strong>Disclosing Party</strong>” has the meaning set out in
            Section 10(a).
          </li>
          <li>
            “<strong>Effective Date</strong>” has the meaning set out in the
            preamble to these Terms of Use.
          </li>
          <li>
            “<strong>Feedback</strong>” has the meaning set out in Section 3(d).
          </li>
          <li>
            “<strong>Marks</strong>” has the meaning set out in Section 7(e).
          </li>
          <li>
            “<strong>Modifications</strong>” means modifications, improvements,
            customizations, updates, enhancements, aggregations, compilations,
            derivative works, translations and adaptations, and “
            <strong>Modify</strong>” has a corresponding meaning.
          </li>
          <li>
            “<strong>MyTerra Account</strong>” means an account issued by
            CarbonTerra to a user of the Sites that has completed the MyTerra
            Platform registration process.
          </li>
          <li>
            “<strong>MyTerra Platform</strong>” means CarbonTerra’s carbon
            credit and precision agriculture platform for Registered Users.
          </li>
          <li>
            “<strong>Parties</strong>” means you and CarbonTerra and “
            <strong>Party</strong>” means you or CarbonTerra as the context
            requires.
          </li>
          <li>
            “<strong>Person</strong>” means a natural person, a corporation, a
            partnership, a limited partnership, a joint venture, an association,
            a trust, a governmental authority or an unincorporated organization.
          </li>
          <li>
            “<strong>Personal Information</strong>” means any information about
            an identifiable individual, such as a name, an identification
            number, location data, or an online identifier.
          </li>
          <li>
            “<strong>Privacy Laws</strong>” means applicable federal, provincial
            and local laws, rules and regulations concerning the privacy and
            security of Personal Information.
          </li>
          <li>
            “<strong>Privacy Policy</strong>” has the meaning set out in Section
            8(a).
          </li>
          <li>
            “<strong>Receiving Party</strong>” has the meaning set out in
            Section 10(a).
          </li>
          <li>
            “<strong>Registered User</strong>” means a user of the Sites with a
            MyTerra Account.
          </li>
          <li>
            “<strong>Registration Information</strong>” has the meaning set out
            in Section 4.
          </li>
          <li>
            “<strong>Sites</strong>” means any: (i) CarbonTerra website,
            including <a href="https://carbonterra.com/">com</a>; (ii)
            applications made available by CarbonTerra from time to time via a
            CarbonTerra website or in any app store for use on mobile devices,
            tablets, personal computers or other devices; (iii) Content offered
            on or through any of the foregoing, including the MyTerra Platform;
            and (iv) Modifications to any of the foregoing.
          </li>
          <li>
            “<strong>Term</strong>” has the meaning set out in Section 13.
          </li>
          <li>
            “<strong>Terms of Use</strong>” has the meaning set out in the
            preamble to these Terms of Use.
          </li>
          <li>
            “<strong>User Information</strong>” means any Content that you
            transmit, load, or enter into the Sites, including, but not limited
            to Personal Information, but excluding all Aggregated Data,
            Collected Information and Feedback.
          </li>
          <li>
            “<strong>you</strong>” has the meaning set out in the preamble to
            these Terms of Use.
          </li>
        </ul>
      </Widget>
    </div>
  );
};

export default TermsAndConditions;
