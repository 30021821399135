import { useContext } from "react";
import { mutate } from "swr";
import { useAccessToken } from "../../hooks/useAccessToken";
import { useModal } from "../../hooks/useModal";
import { UserModel } from "../../types";
import BusinessList from "../features/business/BusinessList";
import BusinessNew from "../features/business/BusinessNew";
import { UserContext } from "../features/user/providers/UserProvider";
import Widget from "../layout/Widget";
import Button from "../ui/Button";
import Modal from "../ui/Modal";

const BusinessesPage = () => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { modal } = useModal();

  const handleBusinessCreated = () => {
    mutate([`customer/${user.customer_id}/business`, token]);
    modal.close();
  };

  return (
    <Widget
      title="my businesses"
      controls={
        <Button onClick={() => modal.open("new-business")}>
          add new business
        </Button>
      }
    >
      <BusinessList />
      <Modal
        visibility={modal.visibility("new-business")}
        handleClose={modal.close}
        title="new business"
        id="new-business"
      >
        <BusinessNew onSuccess={handleBusinessCreated} />
      </Modal>
    </Widget>
  );
};

export default BusinessesPage;
