import { useContext, useEffect, useState } from "react";
import { getPercentage, totalCreditsOf } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { UserModel } from "../../../types";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import { UserContext } from "../user/providers/UserProvider";
import { useUserCredits } from "./hooks/useUserCredits";
import PortfolioChart from "./PortfolioChart";

const CreditsPortfolio = () => {
  const user = useContext(UserContext) as UserModel;
  const { token } = useAccessToken();
  const { credits, isLoading, isError } = useUserCredits(user, token);
  const [totalCredits, setTotalCredits] = useState(0);
  const [standardCredits, setStandardCredits] = useState(0);
  const [premiumCredits, setPremiumCredits] = useState(0);
  const [standardPercentage, setStandardPercentage] = useState(0);

  useEffect(() => {
    if (credits && "items" in credits && credits.items.length > 0) {
      let standardCredits = totalCreditsOf("standard", credits.items);
      let premiumCredits = totalCreditsOf("premium", credits.items);
      let totalCredits = standardCredits + premiumCredits;
      setTotalCredits(parseFloat(totalCredits.toFixed(2)));
      setStandardCredits(parseFloat(standardCredits.toFixed(2)));
      setPremiumCredits(parseFloat(premiumCredits.toFixed(2)));
      setStandardPercentage(getPercentage(standardCredits, totalCredits));
    }
  }, [credits]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting user credits" />;

  return (
    <div className="credits-portfolio">
      <div className="credits-portfolio__total">
        <p className="credits-portfolio__title">Total portfolio credits</p>
        <p className="credits-portfolio__value">{totalCredits}</p>
      </div>
      <div className="credits-portfolio__ratio">
        <div>
          <p className="credits-portfolio__title">Standard</p>
          <p className="credits-portfolio__value">{standardCredits}</p>
        </div>
        <div className="credits-portfolio__ratio-bar">
          <div
            className="credits-portfolio__ratio-bar-standard"
            style={
              standardPercentage !== 0
                ? {
                    flexBasis: standardPercentage + "%",
                  }
                : {}
            }
          >
            {getPercentage(standardCredits, totalCredits)}%
          </div>
          <div
            className="credits-portfolio__ratio-bar-premium"
            style={
              getPercentage(premiumCredits, totalCredits) !== 0
                ? {
                    flexBasis:
                      getPercentage(premiumCredits, totalCredits) + "%",
                  }
                : {}
            }
          >
            {getPercentage(premiumCredits, totalCredits)}%
          </div>
        </div>
        <div>
          <p className="credits-portfolio__title">Premium</p>
          <p className="credits-portfolio__value">{premiumCredits}</p>
        </div>
      </div>
      <PortfolioChart
        standardCredits={standardCredits}
        premiumCredits={premiumCredits}
      />
    </div>
  );
};

export default CreditsPortfolio;
