import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { mutate } from "swr";
import { easyAPI } from "../../helpers";
import { useAccessToken } from "../../hooks/useAccessToken";
import { useServerError } from "../../hooks/useServerError";
import { useWizard } from "../../hooks/useWizard";
import { UserModel } from "../../types";
import BusinessEdit from "../features/business/BusinessEdit";
import LandList from "../features/land/LandList";
import FarmEdit from "../features/profile/FarmEdit";
import { useUserContacts } from "../features/profile/hooks/useUserContacts";
import SocialProfileEdit from "../features/profile/SocialProfileEdit";
import UserContactAdd from "../features/profile/UserContactAdd";
import UserContactEdit from "../features/profile/UserContactEdit";
import UserIdentityEdit from "../features/profile/UserIdentityEdit";
import Widget from "../layout/Widget";
import Avatar from "../ui/Avatar";
import ErrorMessage from "../ui/ErrorMessage";
import Loading from "../ui/Loading";
import Logo from "../ui/Logo";
import WizardControls from "../ui/wizard/WizardControls";
import WizardProgress from "../ui/wizard/WizardProgress";
import WizardStep from "../ui/wizard/WizardStep";

type OnboardingType = {
  user: UserModel;
};

const Onboarding = ({ user }: OnboardingType) => {
  const { token } = useAccessToken();
  const { serverError } = useServerError();
  let wizardStep = user.onboard_step === 0 ? 1 : user.onboard_step;
  const { wizard } = useWizard(
    [
      "Tell us about you",
      "Contact Information",
      "Complete your profile",
      "register your farm",
      "add your lands",
      "add your businesses",
    ],
    wizardStep
  );
  const [hasHomeQuarter, setHasHomeQuarter] = useState(false);
  const { contacts, isLoading, isError, mutateContacts } = useUserContacts(
    token,
    user
  );

  const updateUserOnboardingStep = (step: number, callback?: Function) => {
    const newProfile = new FormData();
    newProfile.append("onboard_step", step.toString());

    const onSuccess = () => {
      mutate(["customer/me", token]);
      wizard.setLoading(false);
      typeof callback === "function" && callback();
    };

    easyAPI(
      "customer/me/profile",
      newProfile,
      token,
      () => {},
      serverError,
      onSuccess,
      "POST",
      "form-data"
    );

    return true;
  };

  const handleStepChange = (direction: "next" | "previous" | "done") => {
    let formSubmitted = false;
    if (direction === "next") {
      formSubmitted = updateUserOnboardingStep(
        wizard.currentStep + 1,
        wizard.goToNextStep
      );
    }
    if (direction === "previous") {
      formSubmitted = updateUserOnboardingStep(
        wizard.currentStep - 1,
        wizard.goToPreviousStep
      );
    }
    if (direction === "done") {
      formSubmitted = updateUserOnboardingStep(-1);
    }
    return formSubmitted;
  };

  const handleLandCountChange = (landCount: number) => {
    setHasHomeQuarter(landCount > 0);
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message="failed getting user contacts" />;

  return (
    <div className="account-setup">
      <Widget
        customHeader={
          <div className="space-between">
            <Logo />
            <Avatar noProfileLink />
          </div>
        }
      >
        <WizardProgress wizard={wizard} />
        <div className="onboarding list">
          <WizardStep of={wizard} step={1}>
            <div className="list list--small-gap">
              <h3>Tell us about you</h3>
              <p className="subtitle">
                <FaInfoCircle /> We require your legal first and last name to
                setup your account.
              </p>
            </div>
            <UserIdentityEdit onSuccess={() => handleStepChange("next")}>
              {(submit) => <WizardControls wizard={wizard} onNext={submit} />}
            </UserIdentityEdit>
          </WizardStep>
          <WizardStep of={wizard} step={2}>
            <div className="list list--small-gap">
              <h3>Contact Information</h3>
              {/* <p className="subtitle">
                <FaInfoCircle /> We require your legal first and last name to
                setup your account.
              </p> */}
            </div>
            {contacts.filter((c) => c.is_primary === true).length ? (
              <UserContactEdit
                contact={contacts.filter((c) => c.is_primary === true)[0]}
                mutateContacts={mutateContacts}
                onSuccess={() => handleStepChange("next")}
              >
                {(submit) => <WizardControls wizard={wizard} onNext={submit} />}
              </UserContactEdit>
            ) : (
              <UserContactAdd
                mutateContacts={mutateContacts}
                onSuccess={() => handleStepChange("next")}
              >
                {(submit) => <WizardControls wizard={wizard} onNext={submit} />}
              </UserContactAdd>
            )}
          </WizardStep>
          <WizardStep of={wizard} step={3}>
            <div className="list list--small-gap">
              <h3>Complete your profile (Optional)</h3>
              <p className="subtitle">
                <FaInfoCircle /> This information will be used to market your
                carbon credits to help get you the highest possible sell price.
                You will be able to edit this information once your account is
                fully created.
              </p>
            </div>
            <SocialProfileEdit onSuccess={() => handleStepChange("next")}>
              {(submit) => (
                <WizardControls
                  wizard={wizard}
                  onNext={submit}
                  onPrevious={() => handleStepChange("previous")}
                />
              )}
            </SocialProfileEdit>
          </WizardStep>
          <WizardStep of={wizard} step={4}>
            <div className="list list--small-gap">
              <h3>register your farm</h3>
              <p className="subtitle">
                <FaInfoCircle /> We use this to customize your experience.
              </p>
            </div>
            <FarmEdit onSuccess={() => handleStepChange("next")}>
              {(submit) => (
                <WizardControls
                  wizard={wizard}
                  onNext={submit}
                  onPrevious={() => handleStepChange("previous")}
                />
              )}
            </FarmEdit>
          </WizardStep>
          <WizardStep of={wizard} step={5}>
            <div className="list list--small-gap">
              <h3>add your home quarter</h3>
              <p className="subtitle">
                <FaInfoCircle /> Your land quarters are used to provide
                personalized information and to claim carbon credits, You need
                to add at least one land now and you can always add more later.
              </p>
            </div>
            <LandList
              onLandCountChange={handleLandCountChange}
              hideAddButton={hasHomeQuarter}
            />
            <WizardControls
              wizard={wizard}
              onNext={() => handleStepChange("next")}
              onPrevious={() => handleStepChange("previous")}
              nextDisabled={!hasHomeQuarter}
            />
          </WizardStep>
          <WizardStep of={wizard} step={6}>
            <div className="list list--small-gap">
              <h3>add your businesses</h3>
              <p className="subtitle">
                <FaInfoCircle /> We use the business information to verify the
                ownership of your lands. Please enter all businesses that you
                have a stake in which you own lands with.
              </p>
            </div>
            <BusinessEdit />
            <WizardControls
              wizard={wizard}
              onNext={() => handleStepChange("done")}
              onPrevious={() => handleStepChange("previous")}
            />
          </WizardStep>
        </div>
      </Widget>
    </div>
  );
};

export default Onboarding;
