import { useParams } from "react-router-dom";
import DocumentList from "../../../document/DocumentList";
import EnrollmentLands from "../../EnrollmentLands";

const EnrollmentReview = () => {
  const { enrollmentId } = useParams();
  return (
    <>
      <h1>enrollment review</h1>
      <h3>enrolled lands</h3>
      <EnrollmentLands enrollmentId={enrollmentId} />
      <h3>seeded acreage reports</h3>
      <DocumentList
        enrollmentId={enrollmentId}
        category={"seeded acreage report"}
        type={"seeded acreage report"}
      />
      <h3>soil sample reports</h3>
      <DocumentList
        enrollmentId={enrollmentId}
        category={"soil sample report"}
        type={"soil sample report"}
      />
    </>
  );
};

export default EnrollmentReview;
