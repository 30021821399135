import { ImageFieldType } from "@catalyst-tech/catalyst-form/dist/types";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import { easyAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  AnyObject,
  FormSuccessHandler,
  ProfileModel,
  QuickFormObject,
  UserModel,
} from "../../../types";
import Widget from "../../layout/Widget";
import { UserContext } from "../user/providers/UserProvider";
import ProfileForm from "./ProfileForm";
import ProfileView from "./ProfileView";

type ProfileEditProps = {
  title?: string;
  submitLabel?: string;
  required?: boolean;
  onSuccess?: FormSuccessHandler;
};

const ProfileEdit = ({
  title,
  submitLabel,
  required,
  onSuccess,
}: ProfileEditProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { serverError } = useServerError();
  const navigate = useNavigate();

  const [profileForm, setProfileForm] = useState<AnyObject>({});

  const handleFormChange = (form: any) => {
    setProfileForm(form);
  };

  const updateProfile = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<Omit<ProfileModel, "pic">> & { pic: ImageFieldType };
    }
  ) => {
    const newProfile = new FormData();
    // required fields
    newProfile.append("given_name", form.data.given_name || "");
    newProfile.append("family_name", form.data.family_name || "");
    newProfile.append("farm_name", form.data.farm_name || "");
    newProfile.append("province", form.data.province || "");
    newProfile.append("city", form.data.city || "");
    // optional fields
    form.data.pic.file instanceof File &&
      newProfile.append("pic", form.data.pic.file, form.data.pic.file.name);
    form.data.bio
      ? newProfile.append("bio", form.data.bio)
      : newProfile.append("remove_bio", "true");
    form.data.website
      ? newProfile.append("website", form.data.website)
      : newProfile.append("remove_website", "true");

    const onAPISuccess = (new_profile: ProfileModel) => {
      mutate(["customer/me", token]);
      navigate("/dashboard");
      typeof onSuccess === "function" && onSuccess(new_profile);
    };

    easyAPI(
      "customer/me/profile",
      newProfile,
      token,
      form.reset,
      serverError,
      onAPISuccess,
      "POST",
      "form-data"
    );
  };

  return (
    <div className="widget-grid">
      <div className="widget-grid__main">
        <Widget title={title ? title : "edit profile"} required={required}>
          <ProfileForm
            profile={user}
            onChange={handleFormChange}
            submitLabel={submitLabel}
            onSubmit={updateProfile}
            serverErrorMessage={serverError.message}
          />
        </Widget>
      </div>
      <div className="widget-grid__aside">
        <Widget title="preview">
          <ProfileView form={profileForm} />
        </Widget>
      </div>
    </div>
  );
};

export default ProfileEdit;
