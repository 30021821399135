import { ReactNode, useContext } from "react";
import { conditionalClassNames } from "../../helpers";
import Menu from "../Menu";
import { MenuContext } from "../ui/providers/MenuProvider";
import Footer from "./Footer";
import Header from "./Header";

type LayoutProps = {
  children: ReactNode;
  type?: "normal" | "isolated" | "none";
};

const Layout = ({ children, type = "normal" }: LayoutProps) => {
  const menuContext = useContext(MenuContext);

  if (type === "none") return <main>{children}</main>;
  if (type === "isolated") return <main className="main">{children}</main>;

  return (
    <>
      <Header />
      <div className="display">
        <aside>
          <Menu />
        </aside>
        <main
          className={conditionalClassNames({
            main: true,
            "main--blur": menuContext?.isOpen || false,
          })}
        >
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
