import useSWR from "swr";
import { fetcher } from "../../../../helpers";
import { UserContactModel, UserModel } from "../../../../types";

export const useUserContacts = (token: string, user: UserModel) => {
  const { data, error, mutate, isValidating } = useSWR(
    token ? [`party/${user.party.party_id}/contacts`, token] : null,
    fetcher
  );

  if (error) {
    console.log(error);
  }

  return {
    contacts: data && "items" in data ? (data.items as UserContactModel[]) : [],
    mutateContacts: mutate,
    isLoading: !error && !data,
    isValidating: isValidating,
    isError: error,
  };
};
