import { useCallback, useContext, useEffect } from "react";
import { compareTimeStamp, yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { UserModel } from "../../../types";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import { UserContext } from "../user/providers/UserProvider";
import { useUserLands } from "./hooks/useUserLands";
import LandItem from "./LandItem";
import LandNew from "./LandNew";

type LandListProps = {
  onLandCountChange?: (landCount: number) => void;
  hideAddButton?: boolean;
};

const LandList = ({
  onLandCountChange,
  hideAddButton = false,
}: LandListProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const { lands, isLoading, isError, mutateLands } = useUserLands(token, user);

  useEffect(() => {
    typeof onLandCountChange === "function" && onLandCountChange(lands.length);
  }, [lands, onLandCountChange]);

  const handleLandCreated = () => {
    typeof onLandCountChange === "function" &&
      onLandCountChange(lands.length + 1);
    mutateLands();
  };

  const handleLandDelete = useCallback(
    (landId: string) => {
      const onSuccess = () => {
        typeof onLandCountChange === "function" &&
          onLandCountChange(lands.length - 1);
        typeof mutateLands === "function" &&
          mutateLands({
            items: lands.filter((land) => land.land_id !== landId),
          });
      };
      const onFailure = () => {};
      yoAPI(
        `customer/${user.customer_id}/land/${landId}`,
        {},
        token,
        onSuccess,
        onFailure,
        "DELETE"
      );
    },
    [lands, mutateLands, onLandCountChange, token, user.customer_id]
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={isError.message} />;

  return (
    <>
      {!hideAddButton && <LandNew asModal onSuccess={handleLandCreated} />}
      <div className="land-list">
        {lands.length ? (
          lands
            .sort((a, b) => compareTimeStamp(a, b, "modified_ts", "new first"))
            .map((land) => (
              <LandItem
                key={land.land_id}
                land={land}
                onDelete={handleLandDelete}
              />
            ))
        ) : (
          <div className="panel">
            <p className="empty">no lands.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default LandList;
