import { ReactNode } from "react";
import { conditionalClassNames } from "../../helpers";

type LabelValuePairProps = {
  label: string;
  value: string | number;
  icon?: ReactNode;
  valuePrefix?: string;
  valueSuffix?: string;
  largeValue?: boolean;
  moneyValue?: boolean;
};

const LabelValuePair = ({
  label,
  value,
  icon,
  valuePrefix,
  valueSuffix,
  largeValue = false,
  moneyValue = false,
}: LabelValuePairProps) => {
  let formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });

  return (
    <div className="grid gap-4">
      <p className="title">{label}</p>
      <p
        className={conditionalClassNames({
          value: true,
          "value--large": largeValue,
        })}
      >
        {valuePrefix} {moneyValue ? formatter.format(value as number) : value}{" "}
        {valueSuffix}
      </p>
    </div>
  );
};

export default LabelValuePair;
