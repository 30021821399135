import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getLandSectionDescription,
  landHasPractice,
} from "../../../../../helpers";
import {
  LandModel,
  LandSectionModel,
  WizardObject,
} from "../../../../../types";
import WizardControls from "../../../../ui/wizard/WizardControls";
import PracticesBulkEdit from "./PracticesBulkEdit";

type DeclareFarmingPracticesProps = {
  lands: LandModel[];
  wizard: WizardObject;
};

const DeclareFarmingPractices = ({
  lands,
  wizard,
}: DeclareFarmingPracticesProps) => {
  const sections = useMemo(() => {
    if (lands) {
      // get unique sections from lands
      let sections: LandSectionModel[] = [];
      let sectionsDescriptions: string[] = [];
      for (const land of lands) {
        let sectionDescription = getLandSectionDescription(land);
        if (!sectionsDescriptions.includes(sectionDescription))
          sectionsDescriptions.push(sectionDescription);
      }
      // collect lands for each unique section
      for (const sectionDescription of sectionsDescriptions) {
        let section: LandSectionModel = {
          section: sectionDescription.split("-")[0],
          township: sectionDescription.split("-")[1],
          range: sectionDescription.split("-")[2],
          meridian: sectionDescription.split("-")[3],
          lands: [],
        };
        for (const land of lands) {
          if (getLandSectionDescription(land) === sectionDescription) {
            section.lands.push(land);
          }
        }
        sections.push(section);
      }
      return sections;
    }
    return [];
  }, [lands]);

  const [landsWithPractices, setLandsWithPractices] = useState<string[]>([]);

  useEffect(() => {
    setLandsWithPractices(
      lands.reduce((landIds: string[], land) => {
        landHasPractice(land, "zero_till") && landIds.push(land.land_id);
        return landIds;
      }, [])
    );
  }, [lands]);

  const onPracticeUpdate = useCallback((landId: string) => {
    setLandsWithPractices((current) =>
      !current.includes(landId) ? [...current, landId] : current
    );
  }, []);

  return (
    <>
      <div>
        <h3>declare your lands farming practices</h3>
        <p className="subtitle">
          We require your farming practices for each land to calculate your
          carbon credits accurately.
        </p>
      </div>
      <div className="land-select">
        <h4>your lands (by section):</h4>
        <PracticesBulkEdit
          sections={sections}
          onPracticeUpdate={onPracticeUpdate}
        />
      </div>
      <WizardControls
        wizard={wizard}
        onNext={wizard.goToNextStep}
        nextDisabled={landsWithPractices.length !== lands.length}
      >
        <p className="font-size-22 bold">
          {landsWithPractices.length} of {lands.length} Land Practices Declared
        </p>
      </WizardControls>
    </>
  );
};

export default DeclareFarmingPractices;
