import { nanoid } from "nanoid";
import { ChangeEvent, ReactNode } from "react";
import { conditionalClassNames } from "../../../helpers";
import { LandModel } from "../../../types";

type LandCheckboxProps = {
  land: LandModel;
  checked: boolean;
  onChange: (land: LandModel, action: "add" | "remove") => void;
  children: ReactNode;
};

const LandCheckbox = ({
  land,
  checked,
  onChange,
  children,
}: LandCheckboxProps) => {
  const checkboxId = nanoid();

  const handleLandCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(land, event.target.checked ? "add" : "remove");
  };

  return (
    <div
      className={conditionalClassNames({
        "land-checkbox": true,
        "land-checkbox--selected": checked,
      })}
    >
      <input
        className="form__checkbox"
        type="checkbox"
        name={`land-checkbox-${checkboxId}`}
        id={`land-checkbox-${checkboxId}`}
        checked={checked}
        onChange={handleLandCheckboxChange}
      />
      <label
        className="form__label form__label--no-tag"
        htmlFor={`land-checkbox-${checkboxId}`}
      >
        <div className="land-checkbox__details">{children}</div>
        {checked ? (
          <p className="land-checkbox__note land-checkbox__note--checked">Selected!</p>
        ) : (
          <p className="land-checkbox__note">Click to Select</p>
        )}
      </label>
    </div>
  );
};

export default LandCheckbox;
