import { useContext, useState } from "react";
import { mutate } from "swr";
import { yoAPI } from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { UserModel } from "../../../types";
import Button from "../../ui/Button";
import { UserContext } from "../user/providers/UserProvider";

type LandItemExcludedProps = {
  lld: string;
};

const LandItemExcluded = ({ lld }: LandItemExcludedProps) => {
  const { token } = useAccessToken();
  const user = useContext(UserContext) as UserModel;
  const [loading, setLoading] = useState(false);

  const handleIncludeLand = () => {
    setLoading(true);
    const onSuccess = () => {
      mutate([`customer/${user.customer_id}/land-section`, token, "v2"]);
    };
    const onFailure = () => {};
    yoAPI(
      `customer/${user.customer_id}/land`,
      { llds: [lld] },
      token,
      onSuccess,
      onFailure
    );
  };

  if (loading) return <p>Including quarter...</p>;
  return (
    <li className={"land-item land-item--excluded"}>
      <div className="land-item__content">
        <div className="space-between align-start">
          <p className="land-item__initials">{lld.slice(0, 2)}</p>
          <div className="flex">
            <Button onClick={handleIncludeLand} variant="second">
              Include
            </Button>
          </div>
        </div>

        {/* <div className="land-item__details">
          <p className="land-item__lld">{lld}</p>
        </div> */}
      </div>
    </li>
  );
};

export default LandItemExcluded;
