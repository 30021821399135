import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import App from "./App";
import {
  AppState,
  Auth0Provider,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import DashboardPage from "./components/pages/DashboardPage";
import WelcomePage from "./components/pages/WelcomePage";
import CarbonCreditsPage from "./components/pages/CarbonCreditsPage";
import TestPage from "./components/pages/TestPage";
import NoTillProgram from "./components/features/enrollment/no-till/NoTillProgram";
import ProfileEdit from "./components/features/profile/ProfileEdit";
import MyFarmPage from "./components/pages/MyFarmPage";
import EnrollmentsPage from "./components/pages/EnrollmentsPage";
import ListingsPage from "./components/pages/ListingssPage";
import BusinessesPage from "./components/pages/BusinessesPage";
import SupportPage from "./components/pages/SupportPage";
import { isBeingTestedByCypress } from "./helpers";
import CarbonCreditProgramsPage from "./components/pages/CarbonCreditProgramsPage";
import TestEnrollmentPage from "./components/pages/TestEnrollmentPage";
import UserProvider from "./components/features/user/providers/UserProvider";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsAndConditions from "./components/pages/TermsAndConditions";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const ProtectedRoute = ({
  component,
  ...args
}: {
  component: React.ComponentType<{}>;
  [arg: string]: any;
}) => {
  if (isBeingTestedByCypress()) {
    const Component = component;
    return <Component />;
  }
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: {
  children: ReactNode;
  [prop: string]: any;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      onRedirectCallback={onRedirectCallback}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        redirectUri={window.location.origin}
        audience={`https://myterrabackend.azurewebsites.net/`}
      >
        <UserProvider>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route element={<ProtectedRoute component={App} />}>
              <Route
                path="/test"
                element={<ProtectedRoute component={TestPage} />}
              />
              <Route
                path="/test/enrollment/:enrollmentId"
                element={<ProtectedRoute component={TestEnrollmentPage} />}
              />
              <Route
                path="/profile/edit"
                element={<ProtectedRoute component={ProfileEdit} />}
              />
              <Route
                path="/dashboard"
                element={<ProtectedRoute component={DashboardPage} />}
              />
              <Route
                path="/my-farm"
                element={<ProtectedRoute component={MyFarmPage} />}
              />
              <Route
                path="/carbon-credits"
                element={<ProtectedRoute component={CarbonCreditsPage} />}
              />
              <Route
                path="/my-enrolled-lands"
                element={<ProtectedRoute component={EnrollmentsPage} />}
              />
              <Route
                path="/carbon-credit-programs"
                element={
                  <ProtectedRoute component={CarbonCreditProgramsPage} />
                }
              />
              <Route
                path="/carbon-credit-programs/no-till/:enrollmentId"
                element={<ProtectedRoute component={NoTillProgram} />}
              />
              <Route
                path="/listings"
                element={<ProtectedRoute component={ListingsPage} />}
              />
              <Route
                path="/businesses"
                element={<ProtectedRoute component={BusinessesPage} />}
              />
              <Route
                path="/support"
                element={<ProtectedRoute component={SupportPage} />}
              />
            </Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          </Routes>
        </UserProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
