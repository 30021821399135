import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import axios from "axios";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useServerError } from "../../../hooks/useServerError";
import {
  AnyObject,
  FormSuccessHandler,
  QuickFormObject,
  UploadInfo,
} from "../../../types";
import { validate } from "../../../validators/validate";

type DocumentUploadType = {
  onSubmissionStart: VoidFunction;
  category: string;
  label: string;
  onSuccess?: FormSuccessHandler;
  children: (submit: FormSubmitType, uploadInfo: UploadInfo) => ReactNode;
};

const DocumentUpload = ({
  onSubmissionStart,
  category,
  label,
  onSuccess: onSuccessCallback,
  children,
}: DocumentUploadType) => {
  const { token } = useAccessToken();
  const { enrollmentId } = useParams();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { serverError } = useServerError();

  // used as the file input id and to get the value from the QuickForm data object.
  const fieldId = category.replaceAll(" ", "-").trim();

  const handleUploadDocuments = (form: QuickFormObject) => {
    let files = form.data[fieldId];
    if (files.length === 0) {
      typeof onSuccessCallback === "function" && onSuccessCallback(null);
      return;
    }

    const type = category; // these are the same for now.

    // uploading started
    onSubmissionStart();
    setIsUploading(true);

    // build form data object
    const formData = new FormData();
    for (const report of form.data[fieldId]) {
      formData.append("new_docs", report);
    }

    const onSuccess = (data: AnyObject) => {
      setIsUploading(false); // uploading stopped
      setUploadProgress(0); // reset progress
      mutate([
        `dossier/${enrollmentId}/document?category=${category}&document_type=${type}`,
        token,
      ]);

      typeof onSuccessCallback === "function" && onSuccessCallback(data);
    };
    const onFailure = (error: Error) => {
      setIsUploading(false); // uploading stopped
      serverError.set(error);
    };
    uploadFilesWithAxios(
      `dossier/${enrollmentId}/document?category=${category}&document_type=${type}`,
      formData,
      onSuccess,
      onFailure
    );
  };

  const uploadFilesWithAxios = async (
    url: string,
    data: FormData,
    onSuccess: Function,
    onError: Function
  ) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_MY_TERRA_API + "/v1/" + url,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );
      let jsonResponse;
      if (response.status < 200 || response.status > 299) {
        let errorMessage = "unknown error";
        if (response.status === 500) {
          errorMessage = "sorry, something went wrong on our end.";
        } else {
          jsonResponse = await response.data.json();
          if ("detail" in jsonResponse) {
            errorMessage = Array.isArray(jsonResponse.detail)
              ? jsonResponse.detail[0].msg
              : jsonResponse.detail;
          }
        }
        throw new Error(errorMessage);
      }
      onSuccess(jsonResponse);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <QuickForm
      fields={[
        {
          label: label,
          id: fieldId,
          type: "file",
          initial: [],
          validators: [validate.required],
          acceptedFormats: "pdf",
          instructions: (
            <p className="help-text">
              PDF & Microsoft Word files are accepted.
            </p>
          ),
        },
      ]}
      onSubmit={handleUploadDocuments}
      serverError={serverError.message}
    >
      {(submit) =>
        children(submit, {
          isLoading: isUploading,
          progress: uploadProgress,
        })
      }
    </QuickForm>
  );
};

export default DocumentUpload;
