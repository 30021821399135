import moment from "moment";
import { FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  compareTimeStamp,
  getCurrentYear,
  RECOMMENDED_SELL_PRICE_PREMIUM,
  RECOMMENDED_SELL_PRICE_STANDARD,
} from "../../../helpers";
import { EnrollmentModel } from "../../../types";
import Button from "../../ui/Button";
import Status from "../../ui/Status";

let formatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

type EnrollmentsListType = {
  enrollments: EnrollmentModel[];
};

const EnrollmentsList = ({ enrollments }: EnrollmentsListType) => {
  const navigate = useNavigate();

  const formatEnrollmentId = (id: string) => {
    const initial = id.slice(0, 4);
    const final = id.slice(-4);
    return initial + "..." + final;
  };

  const getEnrollmentType = (type: string) => {
    if (type === "zero_till") {
      return "no-till";
    }
    return "unknown";
  };

  const getEnrollmentStatus = (status: string) => {
    if (status === "incomplete") {
      return <Status type="incomplete" />;
    }
    if (status === "pending") {
      return <Status type="pending" message="in review" />;
    }
    if (status === "approved") {
      return <Status type="complete" message="approved" />;
    }
    return <Status type="pending" message={status} />;
  };

  const getStandardValue = (enrollment: EnrollmentModel) => {
    let credits = enrollment.credit_schedule.reduce((previous, current) => {
      if (
        current.credit_type === "avoidance" &&
        current.vintage <= getCurrentYear()
      ) {
        return previous + current.credits;
      }
      return previous;
    }, 0);
    return credits;
  };

  const getPremiumValue = (enrollment: EnrollmentModel) => {
    let credits = enrollment.credit_schedule.reduce((previous, current) => {
      if (
        current.credit_type === "removal" &&
        current.vintage <= getCurrentYear()
      ) {
        return previous + current.credits;
      }
      return previous;
    }, 0);
    return credits;
  };

  const getTotalCredits = (enrollment: EnrollmentModel) => {
    return parseFloat(
      (getStandardValue(enrollment) + getPremiumValue(enrollment)).toFixed(1)
    );
  };

  const getTotalValue = (enrollment: EnrollmentModel) => {
    let standardValue =
      getStandardValue(enrollment) * RECOMMENDED_SELL_PRICE_STANDARD;
    let premiumValue =
      getPremiumValue(enrollment) * RECOMMENDED_SELL_PRICE_PREMIUM;
    return standardValue + premiumValue;
  };

  return (
    <div className="list list--gap-16">
      {enrollments
        .sort((a, b) => compareTimeStamp(a, b, "created_ts", "new first"))
        .map((enrollment) => (
          <div className="row" key={enrollment.dossier_id}>
            <p className="sequence">{enrollment.sequence}</p>
            <div className="flex gap-4">
              <p className="bold upper">
                ID: {formatEnrollmentId(enrollment.dossier_id)}
              </p>
              <button
                className="icon-button"
                onClick={() =>
                  navigator.clipboard.writeText(enrollment.dossier_id)
                }
              >
                <FaCopy />
              </button>
            </div>
            <p className="bold cap">
              {getEnrollmentType(enrollment.practice_type)}
            </p>
            <div>
              <p className="bold cap">
                {getTotalCredits(enrollment)} (
                {formatter.format(getTotalValue(enrollment))})
              </p>
              <p className="font-size-12 cap">
                standard: {getStandardValue(enrollment).toFixed(1)} (
                {formatter.format(
                  getStandardValue(enrollment) * RECOMMENDED_SELL_PRICE_STANDARD
                )}
                )
              </p>
              <p className="font-size-12 cap">
                premium: {getPremiumValue(enrollment).toFixed(1)} (
                {formatter.format(
                  getPremiumValue(enrollment) * RECOMMENDED_SELL_PRICE_PREMIUM
                )}
                )
              </p>
            </div>
            {getEnrollmentStatus(enrollment.status)}
            <p className="font-size-14 cap">
              created: {moment.unix(enrollment.created_ts).fromNow()}
            </p>
            <Button
              onClick={() =>
                navigate(
                  `/carbon-credit-programs/${"no-till"}/${
                    enrollment.dossier_id
                  }`
                )
              }
              disabled={enrollment.status !== "incomplete"}
            >
              {enrollment.status === "incomplete" ? "continue" : "open"}
            </Button>
          </div>
        ))}
    </div>
  );
};

export default EnrollmentsList;
