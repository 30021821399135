import ResourceList from "../../ResourceList";

type DocumentListProps = {
  enrollmentId?: string;
  category: "report" | "seeded acreage report" | "soil sample report";
  type: string;
};

const DocumentList = ({
  enrollmentId = "",
  category,
  type,
}: DocumentListProps) => {
  return (
    <ResourceList
      resourceName="document"
      adjective={type}
      endpoint={`dossier/${enrollmentId}/document`}
      parentId={enrollmentId}
      query={{
        category: category,
        document_type: type,
      }}
    />
  );
};

export default DocumentList;
