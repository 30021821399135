import { useAuth0 } from "@auth0/auth0-react";
import Button from "../ui/Button";

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button onClick={() => loginWithRedirect({ screen_hint: "signup" })}>
      sign up
    </Button>
  );
};

export default SignUp;
