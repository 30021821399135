import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import Loading from "../ui/Loading";

const WelcomePage = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <Loading />;

  if (isAuthenticated) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <div className="welcome">
      <div className="welcome__content">
        <h1 className="color-brand-500 font-size-50">MyTerra</h1>
        <div className="welcome__group">
          <p>New to MyTerra?</p>
          <SignUp />
        </div>
        <div className="welcome__group">
          <p>Already have an account?</p>
          <Login />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
