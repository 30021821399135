import { FormObject } from "@catalyst-tech/catalyst-form/dist/types";
import { useState } from "react";
import { mutate } from "swr";
import {
  easyAPI,
  formatMoney,
  RECOMMENDED_SELL_PRICE_PREMIUM,
  RECOMMENDED_SELL_PRICE_STANDARD,
} from "../../../helpers";
import { useAccessToken } from "../../../hooks/useAccessToken";
import { useModal } from "../../../hooks/useModal";
import { useServerError } from "../../../hooks/useServerError";
import { useUser } from "../../../hooks/useUser";
import {
  FormSuccessHandler,
  OrderModel,
  QuickFormObject,
} from "../../../types";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import PopupTip from "../../ui/PopupTip";
import Status from "../../ui/Status";
import ListingForm from "./ListingForm";

type ListingNewType = {
  type: "standard" | "premium";
  availableCredit: number;
  asModal?: boolean;
  onSuccess?: FormSuccessHandler;
};

const ListingNew = ({
  type,
  availableCredit,
  asModal = false,
  onSuccess,
}: ListingNewType) => {
  const { token } = useAccessToken();
  const { user } = useUser(token);
  const { modal } = useModal();
  const [amountToSell, setAmountToSell] = useState(0);
  const [minimumSellPrice, setMinimumSellPrice] = useState(0);
  const { serverError } = useServerError();

  const handleListingFormChange = (form: FormObject) => {
    let newListing = form.data as OrderModel;
    let newAmount: number = parseFloat(newListing.amount) || 0;
    let newMinimumSellPrice: number =
      parseFloat(newListing.minimum_sell_price) || 0;
    setAmountToSell(newAmount);
    setMinimumSellPrice(newMinimumSellPrice);
  };

  const getListingValue = (
    amount: number | string,
    sellPrice: number | string
  ) => {
    if (typeof amount === "string") amount = parseFloat(amount);
    if (typeof sellPrice === "string") sellPrice = parseFloat(sellPrice);
    return formatMoney(amount * sellPrice);
  };

  const getRecommendedSellPrice = () => {
    if (type === "standard") {
      return RECOMMENDED_SELL_PRICE_STANDARD;
    }
    return RECOMMENDED_SELL_PRICE_PREMIUM;
  };

  const getLikelinessToSell = () => {
    if (minimumSellPrice === 0) return;
    if (minimumSellPrice > getRecommendedSellPrice()) {
      return <Status type="action required" message="less likely to sell" />;
    }
    return <Status type="complete" message="likely to sell" />;
  };

  const createListing = (
    form: Omit<QuickFormObject, "data"> & {
      data: Partial<OrderModel>;
    }
  ) => {
    const newListing = {
      amount: form.data.amount,
      credit_type: type === "standard" ? "avoidance" : "removal",
      minimum_sell_price: form.data.minimum_sell_price,
    };

    const onSuccessAPI = (created_listing: any) => {
      modal.close();
      mutate([`party/${user.party.party_id}/order`, token, "v1"]);
      mutate([`party/${user.party.party_id}/credit`, token]);
      if (typeof onSuccess === "function") onSuccess(created_listing);
    };

    easyAPI(
      `party/${user.party.party_id}/order`,
      newListing,
      token,
      form.reset,
      serverError,
      onSuccessAPI
    );
  };

  if (asModal)
    return (
      <>
        <Modal
          visibility={modal.visibility(`sell-${type}-credits`)}
          handleClose={modal.close}
          title={`list ${type} credits for sale`}
          id={`sell-${type}-credits`}
        >
          <div className="listing-new">
            <div className="listing-new__form">
              <ListingForm
                type={type}
                availableCredit={availableCredit}
                onChange={handleListingFormChange}
                onSubmit={(form) => createListing(form)}
                serverErrorMessage={serverError.message}
              />
            </div>
            <div className="listing-new__summery">
              <div>
                <p className="cap font-size-14">your {type} credits</p>
                <p className="font-size-22 bold">{availableCredit}</p>
              </div>
              <div>
                <p className="cap font-size-14">credits to list</p>
                <p className="font-size-22 bold">
                  {amountToSell}{" "}
                  <span className="font-size-14 normal">
                    ({(availableCredit - amountToSell).toFixed(2)} Remaining)
                  </span>
                </p>
              </div>
              <div>
                <div className="flex gap-4">
                  <p className="cap font-size-14">minimum sell price</p>
                  <PopupTip
                    content={
                      <>
                        <p>
                          The{" "}
                          <span className="highlight">minimum sell price</span>{" "}
                          or <b>"floor price"</b> is the minimum dollar value
                          that your carbon credits will be sold for.
                        </p>
                        <p>
                          The sell price of your carbon credits will always be
                          maximized, so if there are multiple offers over your
                          minimum sell price,{" "}
                          <b>you will be matched with the highest one.</b>
                        </p>
                      </>
                    }
                  />
                </div>
                <div className="grid">
                  <p className="font-size-22 bold">
                    {formatMoney(minimumSellPrice)}
                  </p>
                  {getLikelinessToSell()}
                </div>
              </div>
              <div>
                <p className="cap font-size-14 bold">minimum listing value</p>
                <p className="font-size-32 bold">
                  {getListingValue(amountToSell, minimumSellPrice)}
                </p>
              </div>
            </div>
          </div>
        </Modal>
        <Button
          onClick={() => modal.open(`sell-${type}-credits`)}
          disabled={availableCredit === 0}
          stretch
        >
          list {type} credits for sale
        </Button>
      </>
    );

  return (
    <ListingForm
      type={type}
      availableCredit={availableCredit}
      onChange={handleListingFormChange}
      onSubmit={createListing}
      serverErrorMessage={serverError.message}
    />
  );
};

export default ListingNew;
