import { useState } from 'react';

export const useModal = () => {
  const [currentOpenModal, setCurrentOpenModal] = useState('');

  return {
    modal: {
      visibility: (id: string) => currentOpenModal === id,
      toggle: (id: string) =>
        setCurrentOpenModal(id === currentOpenModal ? '' : id),
      open: (id: string) => setCurrentOpenModal(id),
      close: () => setCurrentOpenModal(''),
    },
  };
};
