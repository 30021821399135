import { useNavigate } from "react-router-dom";
import { useAccessToken } from "../../../hooks/useAccessToken";
import Widget from "../../layout/Widget";
import Button from "../../ui/Button";
import ErrorMessage from "../../ui/ErrorMessage";
import Loading from "../../ui/Loading";
import EnrollmentIntro from "./EnrollmentIntro";
import EnrollmentsList from "./EnrollmentsList";
import { useEnrollments } from "./hooks/useEnrollments";

const EnrollmentsWidget = () => {
  const { token } = useAccessToken();
  const { enrollments, isLoading, isError } = useEnrollments(token);
  const navigate = useNavigate();

  if (isLoading) return <Loading />;
  if (isError)
    return <ErrorMessage message="failed getting user enrollments" />;

  return (
    <>
      {enrollments.items.length > 0 ? (
        <Widget
          title="enroll your land in a carbon credit program"
          controls={
            <Button
              onClick={() => navigate("/carbon-credit-programs")}
              dataTest={"enrollment-button"}
            >
              Start a new enrollment
            </Button>
          }
        >
          <EnrollmentsList enrollments={enrollments.items} />
        </Widget>
      ) : (
        <Widget title="enroll your land in a carbon credit program">
          <EnrollmentIntro />
        </Widget>
      )}
    </>
  );
};

export default EnrollmentsWidget;
