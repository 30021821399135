import { createContext, ReactNode, useState } from "react";

export const MenuContext = createContext<{
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
} | null>(null);

type MenuProviderProps = {
  children: ReactNode;
};

const MenuProvider = ({ children }: MenuProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((current) => !current);
  const closeMenu = () => setIsOpen(false);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        toggleMenu,
        closeMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
