import { QuickForm } from "@catalyst-tech/catalyst-form";
import { FormSubmitType } from "@catalyst-tech/catalyst-form/dist/types";
import { ReactNode } from "react";
import {
  FormChangeHandler,
  FormSubmitHandler,
  UserContactModel,
} from "../../../types";
import { validate } from "../../../validators/validate";

type UserContactFormProps = {
  contact?: UserContactModel;
  onChange?: FormChangeHandler;
  children: (submit: FormSubmitType) => ReactNode;
  onSubmit: FormSubmitHandler;
  serverErrorMessage: string;
};

const UserContactForm = ({
  contact,
  onChange,
  children,
  onSubmit,
  serverErrorMessage,
}: UserContactFormProps) => {
  return (
    <>
      <QuickForm
        fields={[
          {
            label: "phone number",
            id: "phone_number",
            type: "text",
            initial: contact?.phone_number ?? "",
            validators: [validate.required, validate.phoneNumber],
            instructions: <p className="font-size-14 color-neutral-600">Example format: 8005551234</p>
          },
        ]}
        onChange={onChange}
        onSubmit={onSubmit}
        serverError={serverErrorMessage}
      >
        {(submit) => children(submit)}
      </QuickForm>
    </>
  );
};

export default UserContactForm;
