import { useParams } from "react-router-dom";
import { WizardObject } from "../../../../../types";
import WizardControls from "../../../../ui/wizard/WizardControls";
import DocumentList from "../../../document/DocumentList";
import DocumentUpload from "../../../document/DocumentUpload";

type UploadSoilSampleReportsProps = {
  wizard: WizardObject;
  onSuccess: VoidFunction;
};

const UploadSoilSampleReports = ({
  wizard,
  onSuccess,
}: UploadSoilSampleReportsProps) => {
  const { enrollmentId } = useParams();

  return (
    <>
      <div className="list list--small-gap">
        <h3>Add your soil sample reports (Optional)</h3>
        <p className="subtitle">
          We use organic matter data from soil sample reports in order to refine
          your carbon credit calculation and allow you to receive more credits
          per acre of land.
        </p>
      </div>
      <h4>Uploaded reports</h4>
      <DocumentList
        enrollmentId={enrollmentId}
        category={"soil sample report"}
        type={"soil sample report"}
      />
      <DocumentUpload
        category={"soil sample report"}
        label={"upload new reports"}
        onSubmissionStart={() => wizard.setLoading(true)}
        onSuccess={onSuccess}
      >
        {(submit, uploadInfo) => (
          <WizardControls
            wizard={wizard}
            onNext={submit}
            uploadInfo={uploadInfo}
          />
        )}
      </DocumentUpload>
    </>
  );
};

export default UploadSoilSampleReports;
