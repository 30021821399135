import ListingList from "../features/listing/ListingList";
import Widget from "../layout/Widget";

const ListingsPage = () => {
  return (
    <Widget title="listing history">
      <ListingList />
    </Widget>
  );
};

export default ListingsPage;
