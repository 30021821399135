import CreditHoldings from "../features/carbon-credit/CreditHoldings";
import Widget from "../layout/Widget";
import ProfitsChart from "../features/carbon-credit/ProfitsChart";
import ListingList from "../features/listing/ListingList";
import CreditsPortfolio from "../features/carbon-credit/CreditsPortfolio";

const CarbonCreditsPage = () => {
  let formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });

  const gerPercentage = (value: number, total: number) =>
    ((value / total) * 100).toFixed(2);

  return (
    <div className="widget-grid">
      <div className="widget-grid__main">
        <Widget title="current holdings">
          <CreditHoldings />
        </Widget>
        <Widget title="listing history">
          <ListingList />
        </Widget>
      </div>
      <div className="widget-grid__aside">
        <Widget title="portfolio">
          <CreditsPortfolio />
        </Widget>

        <Widget title="profits">
          <div className="credits-portfolio">
            <div className="credits-portfolio__total">
              <p className="credits-portfolio__title">Total portfolio credits</p>
              <p className="credits-portfolio__value">{formatter.format(0)}</p>
            </div>
            <div className="credits-portfolio__ratio">
              <div>
                <p className="credits-portfolio__title">Standard</p>
                <p className="credits-portfolio__value">{formatter.format(0)}</p>
              </div>
              <div className="credits-portfolio__ratio-bar">
                <div
                  className="credits-portfolio__ratio-bar-standard"
                  style={{
                    flexBasis: gerPercentage(1, 2) + "%",
                  }}
                >
                  {gerPercentage(0, 100)}%
                </div>
                <div
                  className="credits-portfolio__ratio-bar-premium"
                  style={{
                    flexBasis: gerPercentage(1, 2) + "%",
                  }}
                >
                  {gerPercentage(0, 100)}%
                </div>
              </div>
              <div>
                <p className="credits-portfolio__title">Premium</p>
                <p className="credits-portfolio__value">{formatter.format(0)}</p>
              </div>
            </div>
          </div>
          <ProfitsChart />
        </Widget>
      </div>
    </div>
  );
};

export default CarbonCreditsPage;
